import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import GenreIcon from '../../../../components/genre/GenreIcon';
import { mainColor } from '../../../../helper/helper';
import { IC_NEW_BADGE_B_IMG_PATH } from '../../../../static/svg';
import BingoHexagonBase from '../../../../static/svg/BingoHexagonBase';
import BingoHexagonBlue from '../../../../static/svg/BingoHexagonBlue';
import BingoHexagonYellow from '../../../../static/svg/BingoHexagonYellow';
import PlacePanelYokohama from '../../../../static/svg/PlacePanelYokohama';
import PlacePanelYokohamaClear from '../../../../static/svg/PlacePanelYokohamaClear';
import './styles.scss';

const HexagonItem = (props) => {
  const { sheetDetail, lastChecked } = useSelector((state) => state.bingo);
  const isClearBingo = sheetDetail?.isComplete;
  const [iconCenter, setIconCenter] = useState(<PlacePanelYokohama fill={mainColor} />)
  const [showNewBadge, setShowNewBadge] = useState(false);
  const isClearFull = props.isClearFull
  useEffect(() => {
    if (isClearBingo) {
      setIconCenter(<PlacePanelYokohamaClear />)
    }
  }, [isClearBingo])
  const isCenter = props.isCenter ? props.isCenter : false;
  const panel = props.panel;
  useEffect(() => {
    if (lastChecked && panel && panel.spotId) {
      if (lastChecked === panel.spotId) {
        setShowNewBadge(true);
      }
    }
  }, [lastChecked])
  const colorIcon = `rgb(${panel?.design.colors.red}, ${panel?.design.colors.green}, ${panel?.design.colors.blue})`;
  const isCheckin = panel?.isCheckin;
  let hexagonType;
  const color = isClearFull ? 'yellow' : (isCheckin || isCenter ? 'blue' : 'base');
  switch (color) {
    case 'yellow':
      hexagonType = <BingoHexagonYellow width={props.width} height={props.height} />
      break;
    case 'blue':
      hexagonType = <BingoHexagonBlue width={props.width} height={props.height} />
      break;
    case 'base':
      hexagonType = <BingoHexagonBase width={props.width} height={props.height} />
      break;
  }

  return (
    <>
      {panel.spot ? (<Link to={`/bingo/${ props.bingoId }/spot/${ panel.spotId }/color=${colorIcon}`}>
        <div className="hexagon-item" style={{ width: props.width + "px", height: props.height + "px" }}>
          {hexagonType}
          {showNewBadge && <div className="new-badge-icon"><img src={IC_NEW_BADGE_B_IMG_PATH} /></div>}
          <div className="icon" style={!isCheckin ? { opacity: '0.5' } : null}>
            {isCenter ? iconCenter : <GenreIcon sizeCategory={'size03'} spot={panel.spot} fill={colorIcon} />}
          </div>
        </div>
      </Link>) : (<div className="hexagon-item" style={{ width: props.width + "px", height: props.height + "px" }}>
          {hexagonType}
          {showNewBadge && <div className="new-badge-icon"><img src={IC_NEW_BADGE_B_IMG_PATH} /></div>}
          <div className="icon icon-center">
            {iconCenter}
          </div>
        </div>)}
    </>
  )
}
export default HexagonItem;