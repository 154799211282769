import React from 'react';
import ButtonDetail from '../../static/svg/ButtonDetail';
const CardItem = (props) => {
  const rightClick = props.rightClick;
  return (
    <>
      <div className="card-item" onClick = {rightClick}>
        <div className="left-title">{props.name}</div>
        <div className="right-icon">
          <ButtonDetail />
        </div>
      </div>
    </>
  )
}
export default CardItem;