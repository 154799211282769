import React, { useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { getBingoHistory, setNeedReloadHistory, resetPageNumber } from '../../../../redux/bingoHistory/action'
import BingoLogSpot from './BingoLogSpot';
import BingoLogClearItem from './BingoLogClearItem';
import BingoLogLineItem from './BingoLogLineItem';
import NextButton from '../../../../components/buttons/NextButton';
import Loading from '../../../../components/loading/Loading';
import { HEADER_TABBAR_COPYRIGHT_HOME } from '../../../../helper/helper';
import BingoLogItem from './BingoLogItem'

const BingoLog = () => {
  const dispatch = useDispatch()
  const  size = 5
  const  page = 1
  const playerId = window.localStorage.getItem('user_id');
  const { bingoHistory, isBingoHistories, showBtn } = useSelector((state) => state.history)
  
  useEffect(() => {
    dispatch(resetPageNumber())
    checkCallList()
    dispatch(setNeedReloadHistory(false))
  }, [])

  const checkCallList = async() => {
    const params = {
      playerId: playerId,
      size: size, 
      page: page
    }
    console.log(params);
    await dispatch(getBingoHistory(params))
  }

  return (
      <div
      className={((bingoHistory && bingoHistory.length === 2)) ? 'bingo-log-list class-list' : 'bingo-log-list'}
      style={{ minHeight: window.innerHeight - HEADER_TABBAR_COPYRIGHT_HOME + "px" }}
  >
    {
      !isBingoHistories ?
      <Loading /> : 
      <div>
        {bingoHistory && bingoHistory.slice(0,5).map((item, idx) =>
              item.type === 1 ? <BingoLogSpot data={item} key={idx} /> :
              item.type === 2 ? <BingoLogItem data={item} key={idx} /> :
              item.type === 4 ? <BingoLogLineItem data={item} key={idx} /> :
              <BingoLogClearItem data={item} key={idx} />)}
          <div className="box-button-next">
            { 
              showBtn &&
              <NextButton name="ビンゴログ一覧" link="/bingo-log" />
            }
          </div>
      </div>
    }
    </div>
  )
    
}

export default BingoLog;
