import React from 'react'

const ActionLogin = (props) => {
  const handleClick = props.handleClick;
  const handleLoginClick = props.handleLoginClick;
  const isLoading = props.isLoading;

  return (
    <>
      <div className="tabingo body" >
        <img src="icon/rainbow_kirakira_L.svg" className="imgLeft" alt="rainbow_kirakira_L"/>
        <h2>ログイン・会員登録</h2>
        <img src="icon/icon_title-right.svg" className="imgRight" alt="rainbow_kirakira_R"/>
      </div>
      {/* <div className="divLogin">
        <button
          onClick={handleClick}
          className={isLoading.btnMail ? "btn disable" : "btn"}
        >
          {isLoading.twitter ? (
            <i className="fa fa-refresh fa-spin" />
          ) : (
              <span>
                <img src="icon/while_twitter.svg" className="whileTwitter" alt="while_twitter"/>
                <span className="btn-title">Twitterではじめる</span>
              </span>
            )}
        </button>
      </div> */}

      <div className="divLoginEmail">
        <div
          onClick={handleLoginClick}
          className={isLoading.btnTwitter ? "btn disable" : "btn"}
        >
          {isLoading.mail ? ( 
            <i className="fa fa-refresh fa-spin" />
          ) : (
            <span>
              <span>メールアドレスではじめる</span>
            </span>
          )}
        </div>
      </div>
    </>
  );
}

export default ActionLogin
