import { actionTypes } from './action'

const initState = {
  events: [],
  eventDetail: null,
  isGettingEvent: false,
  isGettingEventDetail: false,
  isGettingEventPlayer: false,
  playerEvent: []
}

const eventReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.GET_LIST_EVENTS:
      return {
        ...state,
        isGettingEvent: false,
      };
    case actionTypes.GET_LIST_EVENTS_SUCCESS:
      return {
        ...state,
        events: payload,
        isGettingEvent: true,
      };
    case actionTypes.GET_LIST_EVENTS_FAIL:
      return {
        ...state,
        isGettingEvent: true,
      };
    // get event detail
    case actionTypes.GET_EVENT_DETAIL:
      return {
        ...state,
        isGettingEventDetail: false,
      };
    case actionTypes.GET_EVENT_DETAIL_SUCCESS:
      return {
        ...state,
        eventDetail: payload,
        isGettingEventDetail: true,
      };
    case actionTypes.GET_EVENT_DETAIL_FAIL:
      return {
        ...state,
        isGettingEventDetail: true,
      };


    //player event
    case actionTypes.GET_PLAYER_EVENT:
      return {
        ...state,
        isGettingEventPlayer: false,
        isGettingEvent: true
      };
    case actionTypes.GET_PLAYER_EVENT_SUCCESS:
      return {
        ...state,
        playerEvent: payload,
        isGettingEventPlayer: true,
        isGettingEvent: false
      };
    case actionTypes.GET_PLAYER_EVENT_FAIL:
      return {
        ...state,
        isGettingEventPlayer: true,
        isGettingEvent: false
      };

    case 'LOG_OUT':
      return initState;
    default:
      return state
  }
}

export default eventReducer
