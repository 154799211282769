import styled from 'styled-components'

const StyleContentHowToPlay = styled.div`
  .play-body {
    margin: 0 auto;
    width: 90%;
    .play-title {
      display: flex;
      justify-content: center;
      margin-top: 4px;

      img {
        max-width: 20px;
        margin: 0 9px
      }

      h2 {
        color: #10cad6;
        font-size: 22px;
      }
    }

    .step {
      margin-top: 15px;

      .step-title {
        h3 {
          font-size: 21px;
          color: #273956;
        }

        img {
          max-width: 37px;
          margin-right: 6px;
          float: left;
          padding-top: 7px;
        }

        p {
          font-size: 12px;
          color: #273956;
          margin-left: 44px;
          font-weight:500;
        }
      }

      .step-play {
        display: flex;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 15px;

        img {
          max-width: 180px;
          max-height: 180px;
        }
      }
    }
  }
`

export default StyleContentHowToPlay
