import React from "react";
import IconButtonBingoInfo from '../../static/svg/IconButtonBingoInfo';
import { Link } from 'react-router-dom';
import './styles.scss';

const MenuHeader = (props) => {
  return (
    <>
      <div className="menu-header">
        <h1 className="title">{props.name}</h1>
      </div>
    </>
  );
};
export default MenuHeader;
