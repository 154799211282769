export const actionTypes = {
  GET_LIST_EVENTS: 'GET_LIST_EVENTS',
  GET_LIST_EVENTS_SUCCESS: 'GET_LIST_EVENTS_SUCCESS',
  GET_LIST_EVENTS_FAIL: 'GET_LIST_EVENTS_FAIL',

  GET_EVENT_DETAIL: 'GET_EVENT_DETAIL',
  GET_EVENT_DETAIL_SUCCESS: 'GET_EVENT_DETAIL_SUCCESS',
  GET_EVENT_DETAIL_FAIL: 'GET_EVENT_DETAIL_FAIL',

  GET_PLAYER_EVENT: 'GET_PLAYER_EVENT',
  GET_PLAYER_EVENT_SUCCESS: 'GET_PLAYER_EVENT_SUCCESS',
  GET_PLAYER_EVENT_FAIL: 'GET_PLAYER_EVENT_FAIL',

}



export const getListEvents = () => {
  return {
    type: actionTypes.GET_LIST_EVENTS
  }
}
export const getListEventsSuccess = (data) => {
  return {
    type: actionTypes.GET_LIST_EVENTS_SUCCESS,
    payload: data
  }
}
export const getListEventsFail = () => {
  return {
    type: actionTypes.GET_LIST_EVENTS_FAIL
  }
}

export const getEventDetail = (params) => {
  return {
    type: actionTypes.GET_EVENT_DETAIL,
    payload: params
  }
}
export const getEventDetailSuccess = (data) => {
  return {
    type: actionTypes.GET_EVENT_DETAIL_SUCCESS,
    payload: data
  }
}
export const getEventDetailFail = () => {
  return {
    type: actionTypes.GET_EVENT_DETAIL_FAIL
  }
}


export const getPlayerEvents = (params) => {
  return {
    type: actionTypes.GET_PLAYER_EVENT,
    payload: params
  }
}
export const getPlayerEventsSuccess = (data) => {
  return {
    type: actionTypes.GET_PLAYER_EVENT_SUCCESS,
    payload: data
  }
}
export const getPlayerEventsFail = () => {
  return {
    type: actionTypes.GET_PLAYER_EVENT_FAIL
  }
}
