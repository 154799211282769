import React from "react";

const IconGenreScenicspots = (props) => {
  var svg;
  switch (props.sizeCategory ?? 1) {
    case 'size01':
      // 周辺地図/チェックイン画面
      svg = <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.934 13.095H0L12.356 0L18.566 6.581L21.209 3.78L30 13.095H27.066L21.21 6.889L18.566 9.69L12.356 3.109L2.934 13.095ZM24.179 25.266C22.214 25.266 20.48 25.612 19.426 26.141C18.153 25.018 14.724 24.213 10.691 24.213C5.574 24.213 1.426 25.508 1.426 27.106C1.426 28.704 5.574 29.999 10.691 29.999C14.406 29.999 17.608 29.316 19.085 28.33C20.078 28.965 21.985 29.396 24.178 29.396C27.393 29.396 29.999 28.471 29.999 27.33C30 26.191 27.394 25.266 24.179 25.266ZM23.002 23.339V20.555H26.326L22.121 11.919L19.289 17.736L20.376 19.969L20.661 20.555H21.24V23.339H23.002ZM8.758 23.339V20.555H9.337L9.622 19.969L10.709 17.736L7.877 11.919L3.672 20.555H6.997V23.339H8.758ZM15.949 23.339V20.379H19.534L15 11.066L10.466 20.379H14.051V23.339H15.949Z" fill={props.fill}/>
      </svg>
      break;

    case 'size02':
      // 周辺地図チェックイン情報
      svg = <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.3692 6.111H0L5.76613 0L8.66413 3.07113L9.89753 1.764L14 6.111H12.6308L9.898 3.21487L8.66413 4.522L5.76613 1.45087L1.3692 6.111ZM11.2835 11.7908C10.3665 11.7908 9.55733 11.9523 9.06547 12.1991C8.4714 11.6751 6.8712 11.2994 4.98913 11.2994C2.6012 11.2994 0.665467 11.9037 0.665467 12.6495C0.665467 13.3952 2.6012 13.9995 4.98913 13.9995C6.7228 13.9995 8.21707 13.6808 8.90633 13.2207C9.36973 13.517 10.2597 13.7181 11.2831 13.7181C12.7834 13.7181 13.9995 13.2865 13.9995 12.754C14 12.2225 12.7839 11.7908 11.2835 11.7908ZM10.7343 10.8915V9.59233H12.2855L10.3231 5.5622L9.00153 8.2768L9.5088 9.31887L9.6418 9.59233H9.912V10.8915H10.7343ZM4.08707 10.8915V9.59233H4.35727L4.49027 9.31887L4.99753 8.2768L3.67593 5.5622L1.7136 9.59233H3.26527V10.8915H4.08707ZM7.44287 10.8915V9.5102H9.11587L7 5.16413L4.88413 9.5102H6.55713V10.8915H7.44287Z" fill={props.fill}/>
      </svg>
      break;

    case 'size03':
      // ビンゴシート
      svg = <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5208 15.714H0L14.8272 0L22.2792 7.8972L25.4508 4.536L36 15.714H32.4792L25.452 8.2668L22.2792 11.628L14.8272 3.7308L3.5208 15.714ZM29.0148 30.3192C26.6568 30.3192 24.576 30.7344 23.3112 31.3692C21.7836 30.0216 17.6688 29.0556 12.8292 29.0556C6.6888 29.0556 1.7112 30.6096 1.7112 32.5272C1.7112 34.4448 6.6888 35.9988 12.8292 35.9988C17.2872 35.9988 21.1296 35.1792 22.902 33.996C24.0936 34.758 26.382 35.2752 29.0136 35.2752C32.8716 35.2752 35.9988 34.1652 35.9988 32.796C36 31.4292 32.8728 30.3192 29.0148 30.3192ZM27.6024 28.0068V24.666H31.5912L26.5452 14.3028L23.1468 21.2832L24.4512 23.9628L24.7932 24.666H25.488V28.0068H27.6024ZM10.5096 28.0068V24.666H11.2044L11.5464 23.9628L12.8508 21.2832L9.4524 14.3028L4.4064 24.666H8.3964V28.0068H10.5096ZM19.1388 28.0068V24.4548H23.4408L18 13.2792L12.5592 24.4548H16.8612V28.0068H19.1388Z" fill={props.fill}/>
      </svg>
      break;

    case 'size04':
      // パネル獲得時
      svg = <svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.6284 34.047H0L32.1256 0L48.2716 17.1106L55.1434 9.828L78 34.047H70.3716L55.146 17.9114L48.2716 25.194L32.1256 8.0834L7.6284 34.047ZM62.8654 65.6916C57.7564 65.6916 53.248 66.5912 50.5076 67.9666C47.1978 65.0468 38.2824 62.9538 27.7966 62.9538C14.4924 62.9538 3.7076 66.3208 3.7076 70.4756C3.7076 74.6304 14.4924 77.9974 27.7966 77.9974C37.4556 77.9974 45.7808 76.2216 49.621 73.658C52.2028 75.309 57.161 76.4296 62.8628 76.4296C71.2218 76.4296 77.9974 74.0246 77.9974 71.058C78 68.0966 71.2244 65.6916 62.8654 65.6916ZM59.8052 60.6814V53.443H68.4476L57.5146 30.9894L50.1514 46.1136L52.9776 51.9194L53.7186 53.443H55.224V60.6814H59.8052ZM22.7708 60.6814V53.443H24.2762L25.0172 51.9194L27.8434 46.1136L20.4802 30.9894L9.5472 53.443H18.1922V60.6814H22.7708ZM41.4674 60.6814V52.9854H50.7884L39 28.7716L27.2116 52.9854H36.5326V60.6814H41.4674Z" fill={props.fill}/>
      </svg>
      break;
  }

  return (
    <>{ svg }</>
  );
};
export default IconGenreScenicspots;
