import { combineReducers } from 'redux';
import shopReducer from './shop/reducer';
import bingoReducer from './bingo/reducer';
import userReducer from './user/reducer';
import notificationReducer from './notification/reducer';
import bingoHistoryReducer from './bingoHistory/reducer';
import appReducer from './app/reducer';
import eventReducer from './event/reducer';
import spotReducer from './spot/reducer';
import HistoryReducer from './bingoHistory/reducer';
import itemsReducer from './item/reducer';

const rootReducer = combineReducers({
  user: userReducer,
  bingo: bingoReducer,
  notification: notificationReducer,
  event:eventReducer,
  app:appReducer,
  spot:spotReducer,
  history: HistoryReducer,
  items: itemsReducer,
})

export default rootReducer;

