import React from 'react';
import './styles.scss';


const CheckinSuccessButton = (props) => {
  return (
    <button
      className={ !props.status ? "checkin-success-button disable" : "checkin-success-button" }
      onClick={ props.handleClick }
      disabled={ !props.status || props.isLoading }
    >
      <span>{ props.name }</span>
    </button>
  )
}
export default CheckinSuccessButton;