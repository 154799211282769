import React from 'react';

const IconLocation = (props) => {
  return (
    <svg
      width={props.width ? props.width : "14"}
      height={props.height ? props.height : "16"}
      viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 0.773C10.439 0.773 13.227 3.561 13.227 7C13.227 9.864 11.293 12.275 8.66 13.002L8.661 13.003L7 14.718L5.338 13.003L5.339 13.002C2.707 12.275 0.773 9.864 0.773 7C0.773 3.561 3.561 0.773 7 0.773ZM7 0C3.14 0 0 3.14 0 7C0 10.036 1.977 12.732 4.843 13.659L7 16L9.157 13.659C12.023 12.732 14 10.036 14 7C14 3.14 10.86 0 7 0ZM7 4.225C8.532 4.225 9.775 5.467 9.775 7C9.775 8.532 8.533 9.775 7 9.775C5.468 9.775 4.225 8.532 4.225 7C4.225 5.468 5.468 4.225 7 4.225ZM7 3.453C5.044 3.453 3.453 5.044 3.453 7C3.453 8.956 5.044 10.547 7 10.547C8.956 10.547 10.547 8.956 10.547 7C10.547 5.044 8.956 3.453 7 3.453Z"
        fill={props.fill} />
    </svg>

  )
}
export default IconLocation;