import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { ICON_VECTOR_RIGHT_IMG_PATH } from '../../static/svg';
const NextButton = (props) => {
  return (
    <>
    <Link to={props.link}>
      <div className="next-button">
        <p>{props.name}</p>
          <img src={ICON_VECTOR_RIGHT_IMG_PATH} alt={props.name} />
      </div>
      </Link>
    </>
  )
}
export default NextButton;