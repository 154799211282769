import {twitterLogin} from '../../services/authenticate'
import React, {useState} from 'react'
import ContentHowToPlay from '../howToPlay/contentHowToPlay'
import StyleLogin from './Login.style'
import {Link ,Redirect} from 'react-router-dom'
import ActionLogin from './ActionLogin'
import Rule from './Rule'

const Login = (props) => {
  window.scrollTo(0, 0);

  const [isLoading, setIsLoading] = useState({
    twitter: false, 
    btnTwitter: false, 
    mail: false,
    btnMail: false
  });
 
  const handleClick = async () => {
    window.localStorage.removeItem('twitterId');
    window.localStorage.removeItem('user_id');
    window.localStorage.removeItem('register');
    window.localStorage.removeItem('needAccessKey');
    window.localStorage.removeItem('jwt_id');
    window.localStorage.removeItem('playerEventId');
    window.localStorage.removeItem('showTabBarBottom');
    window.localStorage.removeItem('playsheetID');
    window.localStorage.removeItem('typeLogin');
    setIsLoading({
      twitter: true, 
      btnTwitter: true, 
      mail: false,
      btnMail: false
    })

    await twitterLogin();
    setIsLoading({
      twitter: false, 
      btnTwitter: false, 
      mail: false,
      btnMail: false
     })

    setTimeout(() => setIsLoading({
      twitter: false, 
      btnTwitter: false, 
      mail: false,
      btnMail: false
     }), 1000);
  };

  const handleLoginClick = async () => {
    setIsLoading({
      twitter: false, 
      btnTwitter: false, 
      mail: true,
      btnMail: true
     })
     
    setTimeout(() => 
      setIsLoading({
        twitter: false, 
        btnTwitter: false, 
        mail: false,
        btnMail: false
      }), 1000);

    window.location.href = ('/login');
  };

  return (
    <StyleLogin style={{ height: "100%" }}>

      {/* <div className="loginTitle">
        <button
          onClick={handleClick}
          className={isLoading.btnMail ? "btn disable" : "btn"}
        >
          {isLoading.twitter ? (
            <i className="fa fa-refresh fa-spin" />
          ) : (
              <span>
                <img src="icon/while_twitter.svg" className="whileTwitter" alt="while_twitter"/>
                <span className="title-twitter-first">Twitterではじめる</span>
              </span>
            )}
        </button>
      </div> */}
      <div className="divImgBgr">
        <img className="imgBgr" src="/images/BG_login.png"  />
        <div className="kumahaku-logo">
          <img className="kumahaku-logo-main" src="/images/LogoTabingo02.svg"  />
          <div className="kumahaku-logo-sub">
            <img className="kumahaku-logo-sub1" src="/icon/Group 28.svg"  />
            <img className="kumahaku-logo-sub2" src="/icon/icon_headerLogo.svg"  />
          </div>
        </div>
        
      </div>
      <div className="imgTitle">
        <img className="image-title" src="icon/catchcopy.svg" alt="catchcopy"  />
      </div>
      
      <div className="botLogin">
        <div className="tabingo top" >
          <img src="icon/rainbow_kirakira_L.svg" className="imgLeft" alt="rainbow_kirakira_L"/>
          <h2>TABINGOって??</h2>
          <img src="icon/icon_title-right.svg" className="imgRight" alt="rainbow_kirakira_R"/>
        </div>
        <div className="content">
          <div>いつもの街がBINGOのマスに様変わり！</div>
          <div>ねらったマスにチェックインして、BINGOをそろえよう。</div>
          <div>見慣れた散歩道で、旅先の街角で。</div>
          <div>ちょっとだけ遠回りしたくなる、BINGOゲームのはじまりです。</div>
        </div>
        {/* <img style={{minHeight:'5px', marginTop:'20px'}} className="line" src="icon/rainbow_LINE.svg" alt="rainbow_LINE 1"/> */}
        <ActionLogin handleClick={handleClick} handleLoginClick={handleLoginClick} isLoading={isLoading}/>
        <ContentHowToPlay className="content-how-to-play"/>
        <ActionLogin handleClick={handleClick} handleLoginClick={handleLoginClick} isLoading={isLoading}/>
        <Rule />
        <div className="footer"  >
          <div className="imgFooter"  />
          <div className="copyRight" ><p>Copyright © POCKET CULTURE CO.,Ltd. All Rights Reserved.</p></div>
        </div>
      </div>
    </StyleLogin>
  );
};

export default Login;
