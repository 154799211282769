import React, { useState } from 'react';
import ErrorModal from "./ErrorModal";

const ErrorLoadModal = (props) => {
  const [isOnline, setIsOnline] = useState(true);
  
  window.addEventListener('offline', function(e) {
    setIsOnline(false);
  });
    
  window.addEventListener('online', function(e) {
    window.location.reload();
  });

  return (
    <ErrorModal
      label1="読み込みエラー"
      label2="再度読み込みを行ってください"
      mainBtnLabel="再読み込み!"
      onClose={() => setIsOnline(true)}
      maintBtnOnClick={() => window.location.reload()}
      display={isOnline ? 'none' : null}
    />
  )
}
export default ErrorLoadModal;
