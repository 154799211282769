import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
import { ICON_VECTOR_RIGHT_IMG_PATH } from '../../static/svg';
const LoadmoreButton = (props) => {
  const onClick = props.isLoading ? null : props.onClick;
  const isLoading = props.isLoading;
  const render = () => {
    return (
      <>
        {props.name} <img src={ICON_VECTOR_RIGHT_IMG_PATH} alt={props.name} />
      </>
    )
  }
  return (
    <>
      <div className="load-more-button" onClick={onClick}>
        <div>
          {isLoading && <i className="fa fa-refresh fa-spin" />}
          {!isLoading && render()}
        </div>
      </div>
    </>
  )
}
export default LoadmoreButton;