import {GET_LIST_HISTORY_SUCCESS, GET_LIST_HISTORY_FAIL, UPDATE_LIST_HISTORY, GET_LIST_HISTORY, NEED_RELOAD_HISTORY, RELOAD_HISTORY_SUCCESS, RESET_PAGE_NUMBER} from './types'

const initState = {
  bingoHistory: [],
  isBingoHistories : false,
  countItems: null,
  pageNumber: 1,
  size: 5,
  showBtn: false,
  needReloadHistory: false
}

const HistoryReducer = (state = initState, action) => {
  const {type, payload} = action

  switch (type) {
    case GET_LIST_HISTORY:
      return {
        ...state,
        isBingoHistories:false,
        showBtn: false
      }
    case RELOAD_HISTORY_SUCCESS:
      return {
        ...state,
        bingoHistory: payload.dataLogs,
        countItems: payload.count, 
        isBingoHistories : true,
        showBtn : payload.count / state.size > state.pageNumber ? true : false
      }

    case GET_LIST_HISTORY_SUCCESS:
      const old_histories = state.bingoHistory;
      const new_histories = old_histories.concat(payload.dataLogs)
  
      return {
        ...state,
        bingoHistory: new_histories,
        countItems: payload.count, 
        isBingoHistories : true,
        showBtn : payload.count / state.size > state.pageNumber ? true : false
      }
    case GET_LIST_HISTORY_FAIL:
      return {
        ...state,
        isBingoHistories: true,
      }
    case UPDATE_LIST_HISTORY:
      return {
        ...state,
        pageNumber: payload,
        isBingoHistories: false,
      }
    case RESET_PAGE_NUMBER:
        return {
          ...state,
          pageNumber: 1,
          showBtn: true,
        }
    case NEED_RELOAD_HISTORY:
      return {
        ...state,
        needReloadHistory: payload,
      }
    default:
      return state
  }
}

export default HistoryReducer
