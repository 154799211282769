import React from 'react';
import { DEFAULT_WIDTH } from '../../helper/helper';

const PlacePanelYokohamaClear = (props) => {
  const size = window.innerWidth <= 768 ? window.innerWidth : DEFAULT_WIDTH;
  const widthItemDefault = 58;
  const heightItemDefault = 46;
  return (
    <>
      <svg
        width={ props.width ? props.width : widthItemDefault / DEFAULT_WIDTH * size }
        height={ props.height ? props.height : heightItemDefault / DEFAULT_WIDTH * size }
        viewBox="0 0 58 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.667 47.02V36.979L16.646 36H20.688L21.667 36.979V39.458H19.662V38.195L19.425 37.958H17.909L17.672 38.195V45.806L17.909 46.043H19.425L19.662 45.806V44.543H21.667V47.022L20.688 48H16.646L15.667 47.02Z" fill="#4F9FE1" />
        <path d="M23.462 48V36H25.467V48H23.462Z" fill="#AC9DE1" />
        <path d="M33.617 37.942H31.454V48H29.449V37.942H27.286V36H33.618V37.942H33.617Z" fill="#E1AAD1" />
        <path d="M39.586 48H37.597V43.816L34.849 36H37.028L38.591 41.037L40.154 36H42.333L39.586 43.816V48Z" fill="#E78DC2" />
        <path d="M11 18C11 8.059 19.059 0 29 0C38.941 0 47 8.059 47 18H45.286C45.285 9.02 37.98 1.714 29 1.714C20.02 1.714 12.715 9.02 12.714 18H11Z" fill="#FF8E97" />
        <path d="M12.244 18C12.244 8.761 19.761 1.244 29 1.244C38.239 1.244 45.756 8.761 45.756 18H43.926C43.926 9.77 37.23 3.074 29 3.074C20.77 3.074 14.074 9.77 14.074 18H12.244Z" fill="#FFC45F" />
        <path d="M13.488 18C13.488 9.44701 20.447 2.48801 29 2.48801C37.553 2.48801 44.512 9.44601 44.512 18H42.755C42.755 10.416 36.585 4.24601 29.001 4.24601C21.417 4.24601 15.246 10.416 15.246 18H13.488Z" fill="#FFF4A1" />
        <path d="M14.732 18C14.732 10.133 21.132 3.73199 28.999 3.73199C36.866 3.73199 43.267 10.133 43.268 18H41.553C41.553 11.078 35.922 5.44699 29 5.44699C22.078 5.44699 16.447 11.078 16.447 18H14.732Z" fill="#99E17D" />
        <path d="M15.977 18C15.977 10.819 21.819 4.976 29 4.976C36.181 4.976 42.023 10.819 42.023 18H40.103C40.103 11.877 35.122 6.896 28.999 6.896C22.876 6.896 17.896 11.877 17.896 18H15.977Z" fill="#9EEEF5" />
        <path d="M17.221 18C17.221 11.505 22.505 6.22099 29 6.22099C35.495 6.22099 40.779 11.505 40.779 18H38.813C38.813 12.589 34.411 8.18699 29 8.18699C23.589 8.18699 19.187 12.589 19.187 18H17.221Z" fill="#6EBBFF" />
        <path d="M18.529 18C18.529 12.226 23.226 7.52901 29 7.52901C34.774 7.52901 39.471 12.227 39.471 18H38.291C38.291 12.877 34.123 8.70901 29 8.70901C23.877 8.70901 19.709 12.877 19.709 18H18.529Z" fill="#E1AAD1" />
        <path d="M6.871 21.67H4.87L3.435 26.295L2 21.67H0L2.522 28.846V32.687H4.348V28.846L6.871 21.67Z" fill="#FF7B7C" />
        <path d="M8.209 21.67L7.311 22.569V31.789L8.209 32.688H11.92L12.819 31.789V22.569L11.92 21.67H8.209ZM10.964 30.702L10.747 30.905H9.355L9.15201 30.702V23.671L9.355 23.468H10.746L10.963 23.671V30.702H10.964Z" fill="#FFBC5F" />
        <path d="M18.419 21.67L15.824 26.701V21.67H14.012V32.687H15.824V30.223L16.694 28.629L18.433 32.687H20.448L17.81 26.541L20.448 21.67H18.419Z" fill="#FFE25F" />
        <path d="M22.018 21.67L21.12 22.569V31.789L22.018 32.688H25.729L26.628 31.789V22.569L25.729 21.67H22.018ZM24.772 30.702L24.554 30.905H23.163L22.96 30.702V23.671L23.163 23.468H24.554L24.772 23.671V30.702Z" fill="#C1E97D" />
        <path d="M31.734 26.222H29.661V21.67H27.82V32.687H29.661V28.005H31.734V32.687H33.575V21.67H31.734V26.222Z" fill="#A8D57D" />
        <path d="M36.914 21.67L34.305 32.687H36.189L36.681 30.31H38.942L39.435 32.687H41.32L38.712 21.67H36.914ZM37.044 28.527L37.813 24.816L38.581 28.527H37.044Z" fill="#A1EDD9" />
        <path d="M46.152 26.875L44.34 21.67H42.05V32.687H43.891V25.251L45.674 30.325H46.631L48.414 25.251V32.687H50.255V21.67H47.964L46.152 26.875Z" fill="#7DDDD9" />
        <path d="M55.391 21.67H53.594L50.985 32.687H52.869L53.362 30.31H55.623L56.116 32.687H58L55.391 21.67ZM53.723 28.527L54.491 24.816L55.259 28.527H53.723Z" fill="#5BC3E1" />
        <path d="M10.929 38.786H9.64301V40.072H10.929V38.786Z" fill="#FF7B7C" />
        <path d="M12.214 37.5H10.928V38.786H12.214V37.5Z" fill="#77B4EA" />
        <path d="M12.214 40.071H10.928V41.357H12.214V40.071Z" fill="#FFBC5F" />
        <path d="M13.5 38.786H12.214V40.072H13.5V38.786Z" fill="#99D548" />
        <path d="M7.071 42.643H5.785V43.929H7.071V42.643Z" fill="#FF7B7C" />
        <path d="M5.786 42.643H4.5V43.929H5.786V42.643Z" fill="#FFA5A6" />
        <path d="M8.35701 41.357H7.07101V42.643H8.35701V41.357Z" fill="#77B4EA" />
        <path d="M8.35701 40.071H7.07101V41.357H8.35701V40.071Z" fill="#9BC8F0" />
        <path d="M8.35701 43.929H7.07101V45.215H8.35701V43.929Z" fill="#FFBC5F" />
        <path d="M8.35701 45.214H7.07101V46.5H8.35701V45.214Z" fill="#FFD294" />
        <path d="M9.64299 42.643H8.35699V43.929H9.64299V42.643Z" fill="#99D548" />
        <path d="M10.929 42.643H9.64301V43.929H10.929V42.643Z" fill="#C4E795" />
        <path d="M8.35701 37.5H7.07101V38.786H8.35701V37.5Z" fill="#F0DC39" />
        <path d="M51.5 43.928H50.215V45.213H51.5V43.928Z" fill="#FF7B7C" />
        <path d="M50.214 45.215H48.929V46.5H50.214V45.215Z" fill="#77B4EA" />
        <path d="M50.214 42.643H48.929V43.928H50.214V42.643Z" fill="#FFBC5F" />
        <path d="M48.928 43.928H47.643V45.213H48.928V43.928Z" fill="#99D548" />
        <path d="M47.643 40.072H46.358V41.357H47.643V40.072Z" fill="#FF7B7C" />
        <path d="M48.928 40.072H47.643V41.357H48.928V40.072Z" fill="#FFA5A6" />
        <path d="M46.357 41.357H45.072V42.642H46.357V41.357Z" fill="#77B4EA" />
        <path d="M46.357 42.643H45.072V43.928H46.357V42.643Z" fill="#9BC8F0" />
        <path d="M46.357 38.785H45.072V40.07H46.357V38.785Z" fill="#FFBC5F" />
        <path d="M46.357 37.5H45.072V38.785H46.357V37.5Z" fill="#FFD294" />
        <path d="M45.071 40.072H43.786V41.357H45.071V40.072Z" fill="#99D548" />
        <path d="M43.785 40.072H42.5V41.357H43.785V40.072Z" fill="#C4E795" />
        <path d="M43.786 45.214H42.5V46.5H43.786V45.214Z" fill="#F0DC39" />
      </svg>
    </>
  )
}
export default PlacePanelYokohamaClear;