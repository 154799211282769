import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import JumpToTopButton from '../../../components/buttons/JumpToTopButton';
import CopyRightGreen from '../../../components/copyRights/CopyRightGreen';
import BingoClearModal from '../../../components/modals/BingoClearModal';
import GetTicketSuccessModal from '../../../components/modals/GetTicketSuccessModal';
import { setLastChecked } from '../../../redux/bingo/action';
import { clearCheckin } from '../../../redux/spot/action';
import { BG_BINGO_SHEET_COMPLETE_IMG_PATH, BG_BINGO_SHEET_NORMAL_IMG_PATH } from '../../../static/png';
import ChallengeToSpots from '../../../static/svg/ChallengeToSpots';
import BingoChallengeBottom from './bingoBottom/BingoChallengeBottom';
import BingoChallengeTop from './bingoTop/BingoChallengeTop';
import GoOtherBingo from './bingoTop/GoOtherBingo';
import './styles.scss';

const BingoChallengeScreen = (props) => {
  const dispatch = useDispatch();
  const playerBingoId = props.playerBingoId;
  const isClearBingo = props.sheetDetail?.isComplete;
  const [imgBackground, setImgBackground] = useState(BG_BINGO_SHEET_NORMAL_IMG_PATH)
  const [display, setDisplay] = useState(1);
  const { checkinData } = useSelector((state) => state.spot);
  const history = useHistory();

  useEffect(() => {
    document.body.style.overflow = "unset";
  }, [])

  useEffect(() => history.listen(() => {
    dispatch(setLastChecked(null))
  }), [dispatch, history])

  useEffect(() => {
    if (isClearBingo) {
      setImgBackground(BG_BINGO_SHEET_COMPLETE_IMG_PATH);
    }
  }, [isClearBingo])

  const jumpToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  const renderBingoReward = () => {
    return (
      <a href="/exchange-ticket" className="div-img-reward">
        <div className="reward-text">
          <div className="reward-text-first">
            チケット
          </div>
          <div className="reward-text-second">
            交換
          </div>
        </div>
      </a>
    )
  }

  const clearCheckinData = () => {
    dispatch(clearCheckin());
    setDisplay(0)
  }

  const clearCheckinDataLine = (data) => {
    const id = document.getElementsByClassName('modal-id')[0].innerText;
    const arrId = id.split(',');
    if (arrId.length === data.length) {
      dispatch(clearCheckin());
      setDisplay(0);
      return;
    }
    for (let i = 0; i < data.length; i++) {
      if (!arrId.includes(data[i].ticketId)) {
        document.getElementsByClassName('img-box')[0].src = data[i].ticketUri;
        setTimeout(function() {          
          document.getElementsByClassName('number-line')[0].innerText = data[i].lineNummber;
          document.getElementsByClassName('title-ticket')[0].innerText = data[i].title;
          document.getElementsByClassName('number-ticket')[0].innerText = data[i].acquired;
          document.getElementsByClassName('modal-id')[0].innerText = id + ',' + data[i].ticketId;
        }, 1000);
        break;
      }
    }
  }

  const renderBingoInfo = () => {
    return (
      <Link to={`/bingo/${playerBingoId}/spots`}>
        <div className="div-bingo-info">
          <ChallengeToSpots width="50" height="50" />
        </div>
      </Link>
    )
  }

  return (
    <>
      <div
        className="bingo-challenge-screen"
        style={{
          backgroundImage: "url(" + imgBackground + ")"
        }}>
        {/* {renderBingoReward()} */}
        {renderBingoInfo()}
        <BingoChallengeTop sheetDetail={props.sheetDetail} />
        {isClearBingo && <GoOtherBingo />}
        <BingoChallengeBottom sheetDetail={props.sheetDetail} />
        <JumpToTopButton onClick={() => jumpToTop()} />
        <CopyRightGreen />
      </div>
      {
        display === 1 &&
        checkinData?.checkinClearBingo &&
        <BingoClearModal name={props.sheetDetail?.name} onClose={() => clearCheckinData()} />
      }
      {
        display === 1 &&
        !checkinData?.checkinClearBingo &&
        checkinData?.numberCompleteLines > 0 &&
        <GetTicketSuccessModal
          id={checkinData?.acquiredTickets[0].ticketId}
          ticketUri={checkinData?.acquiredTickets[0].ticketUri}
          title={checkinData?.acquiredTickets[0].title}
          number={checkinData?.acquiredTickets[0].lineNummber}
          rewards={checkinData?.acquiredTickets[0].acquired}
          onClose={() => clearCheckinDataLine(checkinData?.acquiredTickets)}
        />
      }
    </>
  )
}
export default BingoChallengeScreen;