import { actionTypes } from './action'

const initState = {
  isGettingNotifications: false,
  isGettingDetail: false,
  notifications: [],
  notificationDetail: {},
  lasted_field: 0,
  showBtnLoadMore: false,
  isCheckNotificaiton: false,
  size: 6,
  pageNumber: 1,
  countItem: null,
  isNotFound: false
}

const notificationReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        isGettingNotifications: true,
      };
    case actionTypes.GET_NOTIFICATIONS_SUCCESS: 
      const old_notifications = state.notifications;
      const new_notifications = old_notifications.concat(payload.notifications)

      return {
        ...state,
        isGettingNotifications: false,
        notifications: new_notifications,
        countItem: payload.count,
        showBtnLoadMore: payload.count / state.size > state.pageNumber ? true : false,
        isCheckNotificaiton: true,
      };

    case actionTypes.GET_NOTIFICATIONS_FAIL:
      return {
        ...state,
        isGettingNotifications: false,
      };
    case actionTypes.GET_NOTIFICATION_DETAIL:
      return {
        ...state,
        isGettingDetail: true,
      };
    case actionTypes.GET_NOTIFICATION_DETAIL_SUCCESS:
      return {
        ...state,
        isGettingDetail: false,
        notificationDetail: payload,
        isNotFound: false
      };
    case actionTypes.GET_NOTIFICATION_DETAIL_FAIL:
      return {
        ...state,
        isGettingDetail: false,
        isNotFound: true
      };
    case actionTypes.CLEAR_NOTIFICATION_DETAIL:
      return {
        ...state,
        notificationDetail: null,
      };
    case 'LOG_OUT':
      return initState;
    case actionTypes.UPDATE_NOTIFICATION_LIST:
      return {
        ...state,
        notifications: payload,
      }
    case actionTypes.UPDATE_LASTED_NOTIFICATION:
      return {
        ...state,
        lasted_field: payload,
      }
    case actionTypes.UPDATE_PAGE_NOTIFICATION:
      return {
        ...state,
        pageNumber: payload,
      }
    default:
      return state
  }
}

export default notificationReducer
