import React from "react";
import StyleLoading from './Loading.style'

const Loading = () => {
  return (
    <StyleLoading>
      <div className="loader" />
    </StyleLoading>
  );
}

export default Loading;
