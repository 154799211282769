import { all } from "redux-saga/effects";
import {
  watchGetNotifications,
  watchGetNotificationDetail
} from './notification/saga'
import { watchGetEventDetail, watchGetPlayerEvent } from './event/saga'
import { watchCheckinSpot, watchGetListSpots, watchGetSpotDetail } from './spot/saga'
import { watchGetBingosSelect, watchGetSheetDetail } from "./bingo/saga";
import { watchGetBingosHistory} from "./bingoHistory/saga"
export default function* rootSaga() {
  yield all([
    watchGetNotifications(),
    watchGetNotificationDetail(),
    //event
    watchGetEventDetail(),
    watchGetPlayerEvent(),
    //spot
    watchGetListSpots(),
    watchGetSpotDetail(),
    watchCheckinSpot(),
    //bingo
    watchGetBingosSelect(),
    watchGetSheetDetail(),

    watchGetBingosHistory()
  ]);
}