import React from 'react';
import './styles.scss';
import { ICON_BUTTON_CLOSE_IMG_PATH } from '../../static/svg';

const PopupModal = (props) => {
  let showCloseBtn = true;
  let onClickBtn = props.onClose;
  if (props.showCloseBtn !== undefined) {
    showCloseBtn = props.showCloseBtn
  }
  if (props.onClickBtn !== undefined) {
    onClickBtn = props.onClickBtn;
  }
  return (
    <div className="popup-modal">
      <div className="content">
        <div className="card">
          {props.children}
        </div>
      </div>
      {
          showCloseBtn && <div className="button-close"><img src={ICON_BUTTON_CLOSE_IMG_PATH} onClick={onClickBtn} /></div>
        }
    </div>
  )
}

export default PopupModal
