import React from 'react';
import './styles.scss';
import LineTownFooter from '../../static/svg/LineTownFooter';

const CopyRight = (props) => {
  return (
    <div className="copy-right">
      <div className="div-image" style={{backgroundColor:props.backgroundColor?props.backgroundColor:null}}>
      {/* <LineTownFooter /> */}
      </div>
      <div className="copy-right-text">
        <span>Copyright © POCKET CULTURE CO.,Ltd. All Rights Reserved.</span>
      </div>
    </div>
  )
}
export default CopyRight;