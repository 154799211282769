import { getPlayerBingoStarted } from 'common/src/api/playerBingo'
import { serverApi } from '../../../services/serverApi'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter, useLocation } from 'react-router-dom';
import GoogleMap from './googleMap/GoogleMap'
import BingoChallengeScreen from '../bingoChallenge/BingoChallengeScreen';
import './styles.scss';
import MainHeader from '../../../components/headers/MainHeader'
import BingoTabbar from './BingoTabbar'
import { setPlayerBingoId } from '../../../redux/user/action';
import { getSheetDetail, updateSheetDetail } from '../../../redux/bingo/action';
import Loading from '../../../components/loading/Loading';


const BingoMapScreen = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const needUpdate = useSelector(state => state.bingo.need_update);
  const playerId = window.localStorage.getItem("user_id");
  const playerBingoId = props.match.params.playerBingoId;
  const playerEventId = window.localStorage.getItem('playerEventId');
  const { sheetDetail, isGettingSheetDetail, isUpdateSheetDetail } = useSelector((state) => state.bingo);


  useEffect(() => {
    dispatch(setPlayerBingoId(playerBingoId))
    const getBingoInfo = async () => {
      let userId = window.localStorage.getItem('user_id')
      let result = await serverApi(getPlayerBingoStarted(userId))
    }
    needUpdate && getBingoInfo()
  }, [needUpdate])

  useEffect(() => {
    if (!playerId || !playerEventId || !playerBingoId) {
      return
    }
    dispatch(setPlayerBingoId(playerBingoId))

    if (!sheetDetail || isUpdateSheetDetail) {
      dispatch(getSheetDetail({
        playerId: playerId,
        playerEventId: playerEventId,
        playerBingoId: playerBingoId
      }))
    }
    dispatch(updateSheetDetail(false))
  }, [])
  let ua = navigator.userAgent.toLowerCase(); 
  let classMap = 'bingo-map-screen safari-map';
  if (ua.indexOf('android') > -1) {
    if (ua.indexOf('chrome') > -1 &&  ua.indexOf('browser') === -1) {
      classMap = 'bingo-map-screen chrome-map-android'; // Chrome
    } else if (ua.indexOf('samsungbrowser') > -1) {
      classMap = 'bingo-map-screen samsung-br-map';
    } else if (ua.indexOf('browser') > -1 && ua.indexOf('android') != -1) {
      classMap = 'bingo-map-screen chrome-map'; // internet
    }
  } else if (ua.indexOf('safari') != -1) {
    if (ua.indexOf('chrome') > -1 || /CriOS/i.test(navigator.userAgent)) {
      classMap = 'bingo-map-screen chrome-map'; // Chrome
    }
  }
  
  return (
    <>
      <div className="bingo-map-container">
        <MainHeader name={sheetDetail?.name} isGettingData={isGettingSheetDetail} showGoBack={false} props={props}  targetUrl = '/select-bingo'/>
        <BingoTabbar action={props} playerBingoId={ playerBingoId } />
        <div className={(location.pathname === `/bingo/${ playerBingoId }/map`) ? classMap : "bingo-map-screen"}>
          {
            (location.pathname === `/bingo/${ playerBingoId }/map`) &&
            (
              (isGettingSheetDetail || !sheetDetail) ?
              <Loading /> :
              <GoogleMap playerBingoId={ playerBingoId } playsheetID={ sheetDetail?.id } playerId={playerId}/>
            )
          }
          {
            (location.pathname === `/bingo/${ playerBingoId }/challenge`) &&
            
            (
              (isGettingSheetDetail || !sheetDetail) ?
              <Loading /> :
              <BingoChallengeScreen playerBingoId={ playerBingoId } sheetDetail={ sheetDetail } playerId={playerId}/>
            )
          }
        </div>
      </div>
    </>
  )
}

export default withRouter(BingoMapScreen);
