import React from 'react';
import FooterBuilding from '../../components/footerBuilding';
import HeaderGoBack from '../../components/HeaderGoBack'
import ContentHowToPlay from '../howToPlay/contentHowToPlay'
import ContentInfoTabingo from './contentInfoTabingo'
import StyleInfoTabingo from './infoTabingo.style'
import './styles.scss'

const InfoTabingo = () => {
  window.scrollTo(0, 0);
  return (
    <React.Fragment>
      <div className="menu copy-right">
        <HeaderGoBack text="TABINGOとは？" style={{ borderBottom: 'none'}}/>

        <StyleInfoTabingo>
          <ContentInfoTabingo />
          <img className="line" src="icon/rainbow_LINE.svg"  alt="rainbow_LINE"/>
          <div style={{marginBottom:'80px'}}>
            <ContentHowToPlay />
          </div>
        </StyleInfoTabingo>
        <FooterBuilding />
      </div>
    </React.Fragment>
  );
}

export default InfoTabingo;
