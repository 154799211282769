import {GET_ITEMS_SINGLE} from './types'

const initState = {
  items: []
}

const itemsReducer = (state = initState, action) => {
  const {type, payload} = action
  let item;

  switch (type) {
    case GET_ITEMS_SINGLE:
      item = state.items
      item.push({ ...payload });
      return {
        ...state,
        items: item
      }
    default:
      return state
  }
}

export default itemsReducer;
