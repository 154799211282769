import React from 'react'

const Rule = (props) => {
  return (
    <div className="rules">
        <p className="text">
          <span>
            会員登録に伴い、
            <a href="/term-of-use">利用規約</a>
            、
            <a href="/privacy-protection">個人情報保護方針</a>
            に
          </span>
        </p>
        <p className="text"> 同意したものとみなされます。</p>
    </div>
  );
}

export default Rule
