import React from 'react';
import './styles.scss';
import { formatDateBingoLog } from '../../../../helper/helper';
import moment from 'moment';

const BingoLogItem = (props) => {
  const date = moment((props.data.date)).format(formatDateBingoLog)

  return (
    <div className="bingo-log-item" >
      <div className="item-image">
        <img src={props.data.thumbnailUri} alt={props.idx} />
      </div>
      <div className="item-text">
        <div className="title">
          <p>「{props.data.eventName}」で<span className="bold">{props.data.name}</span>を交換しました。</p>
          <p>配布番号：{props.data.deliveryId}</p>
          </div>
        <div>
          {props.data.tickets && props.data.tickets.map((ticket) =>
            <div className="ticket"><img className="image-ticket" src={ticket.thumbnailUri} /><span className="ticket-text">×{ticket.consumedTickets}枚 消費</span></div>
          )}
          <div className="date">{date}</div>
        </div>
      </div>
    </div>
  )
}

export default BingoLogItem;
