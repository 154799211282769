import { detailPlayer } from 'common/src/api/player'
import useQuery from 'common/src/util/useQuery'
import moment from 'moment'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import HomeHeader from '../../components/headers/HomeHeader'
import Loading from '../../components/loading/Loading'
import { getEventDetail, getPlayerEvents } from '../../redux/event/action'
import { getItemsSingle } from '../../redux/item/action'
import { getNotifications } from '../../redux/notification/action'
import { getUserInfo } from '../../redux/user/action'
import { serverApi } from '../../services/serverApi'
import EventBox from './eventBox/EventBox'
import './styles.scss'
import HomeTab from './tabs/HomeTab'

const HomeScreen = () => { 
  const history = useHistory();
  const query = useQuery();
  const itemId = query.get('itemId');
  const eventId = query.get('eventId');

  if (!window.localStorage.getItem("showTutorial")) {
    history.push('/tutorial');
  }

  const dispatch = useDispatch();
  const { notifications, isCheckNotificaiton } = useSelector((state) => state.notification);
  const { isCheckUser, userInfo } = useSelector((state) => state.user)
  const { playerEvent, isGettingEventPlayer, isGettingEventDetail } = useSelector((state) => state.event);
  const playerId = window.localStorage.getItem('user_id');
  const eventDetailId = window.localStorage.getItem('eventId');

  useEffect(() => {
    if (typeof userInfo.id === 'undefined') {
      getInfoUser(playerId)
    }
    if (eventDetailId != null) {
      dispatch(getEventDetail({ eventId: eventDetailId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[playerId, eventDetailId])

  const getInfoUser = async (playerId) => {
    if (!playerId) {
      return;
    }
    const result = await serverApi(detailPlayer(playerId))
   
    if (result.data) {
      const player = result.data.data
      // set user info to redux
      dispatch(getUserInfo(player))
    }
  }

  useEffect(() => {
    if (isCheckUser) {
      if (notifications.length <= 0) {
        getNotificationsList()
      }
      if (isCheckNotificaiton && playerEvent.length <= 0) {
        dispatch(getPlayerEvents({ playerId: playerId }));
      }
    }
  },[isCheckUser, playerId, isCheckNotificaiton])

  useEffect(async () => {
    if (itemId && eventId && userInfo.activities !== undefined) {
      const eventData = await serverApi({url: 'events/' + eventId});
      const itemData = await serverApi({url: 'items/' + itemId});
      if (itemData.data.data && itemData.data.data) {
        dispatch(getItemsSingle(itemData.data.data));
        const item = itemData.data.data;
        const eventDetail = eventData.data.data;
        const activities = userInfo.activities;
        let isSucess = false;
        for (let i = 0; i < item.tickets.length; i++) {
          if (Array.isArray(activities.wallets) && activities.wallets) {
            const walletData = activities.wallets.filter(function (wallet) {
              return item.tickets[i].id === wallet.ticketId;
            });
            if (walletData.length > 0 && walletData[0].totalAcquisitionTicket >= item.tickets[i].amountOfTicketsConsumed) {
              isSucess = true;
            } else {
              isSucess = false;
              break;
            }
          }
        }

        if (isSucess && eventDetail) {
          let now = moment(new Date()).format('YYYY-MM-DD');
          let isCreate = true;
          let number = 1;
          const eventId = window.localStorage.getItem('eventId');
          const serials = eventDetail.serials;
          for (let i = 0; i < serials.length; i++) {
            if (serials[i].date === now) {
              isCreate = false;
              serials[i].number = serials[i].number + 1;
              number = serials[i].number;
              break;
            }
          }

          if (isCreate) {
            serials.push({
              date: now,
              number: number,
            });
          }
          serverApi({
            url: 'events/' + eventId,
            method: 'patch',
            data: { serials: serials }
          });
          for (let i = 0; i < item.tickets.length; i++) {
            if (Array.isArray(activities.wallets) && activities.wallets) {
              for (let j = 0; j < activities.wallets.length; j++) {
                if (item.tickets[i].id === activities.wallets[j].ticketId) {
                  activities.wallets[j].totalAcquisitionTicket = activities.wallets[j].totalAcquisitionTicket - item.tickets[i].amountOfTicketsConsumed;
                }
              }
            }
          }
          serverApi({
            url: 'players/' + playerId + '/activities/' + activities.id,
            method: 'patch',
            data: { wallets: activities.wallets }
          });
          let textCountRedeem = '';
          if (number.toString().length === 1) {
            textCountRedeem = '000' + number.toString();
          } else if (number.toString().length === 2) {
            textCountRedeem = '00' + number.toString();
          } else if (number.toString().length === 3) {
            textCountRedeem = '0' + number.toString();
          } else {
            textCountRedeem = number.toString();
          }

          const deliveryId = eventDetail.eventCode + '-' + now + '-' + textCountRedeem;
          serverApi({
            url: 'players/' + playerId + '/activities/' + activities.id + '/acquiredItems',
            method: 'post',
            data: { 
              itemId: itemId,
              acquiredAt: parseInt(moment(new Date()).format('x')),
              deliveryId: deliveryId,
            }
          });
          item.deliveryId = deliveryId;
          item.eventName = eventDetail.name;
          serverApi({
            url: 'players/' + playerId + '/activities/logItems',
            method: 'post',
            data: item
          });
          history.push('/redeem-card?deliveryId=' + deliveryId + '&itemId=' + item.id);
        } else {
          history.push('/redeem-card?itemId=' + item.id);
        }
      } else {
        history.push('/');
      }
    }
  },[userInfo])

  const getNotificationsList = async (size = 6, page = 1) => {
    await dispatch(getNotifications({
      size:size, page: page 
    }))
  }

  const jumpToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  useEffect(() => {
    jumpToTop()
  }, [])

  return (
    <React.Fragment>
      <div>
        { window.localStorage.getItem("showTutorial") ? <HomeHeader /> : null }
        {!isGettingEventPlayer || !isGettingEventDetail ||  (itemId && eventId) ? <Loading /> :
          <>
            <div className="home-screen">
              <div className="home-screen-top">
                <EventBox playerId={playerId} />
                <div className="town-image"></div>
              </div>
              <div className="home-screen-content" >
                <HomeTab />
              </div>
            </div>
          </>}

      </div>

    </React.Fragment>
  )
}

export default HomeScreen;
