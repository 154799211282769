import React, { useEffect, useState } from 'react'
import './styles.scss';
import StepOneTutorialV2 from '../../static/svg/StepOneTutorialV2';
import StepTwoTutorialV2 from '../../static/svg/StepTwoTutorialV2';
import StepThreeTutorialV2 from '../../static/svg/StepThreeTutorialV2';
import StepFourTutorialV2 from '../../static/svg/StepFourTutorialV2';
import CommonStep from './commonStep';
import CommonStep2 from './commonStep2';
import CommonStep3 from './commonStep3';

const Tutorial = () => {
    const [step, setStep] = useState(1);
    window.localStorage.removeItem("showTutorial");
    window.localStorage.setItem('backLogin', true);

    return (
        <>
        { step === 1 && <CommonStep step={<StepOneTutorialV2 />} stepNumber={1} title={'応募キャンペーン'} next={ () => setStep(2) } /> }
        { step === 2 && <CommonStep2 step={<StepTwoTutorialV2 />} stepNumber={2} title={'オリジナルグッズの引き換え場所'} previous={ () => setStep(1) } next={ () => setStep(3) } /> }
        { step === 3 && <CommonStep3 step={<StepThreeTutorialV2 />} stepNumber={3} title={'問い合わせ窓口'} previous={ () => setStep(2) } next={ () => setStep(4) } /> }
        {/* { step === 4 && <CommonStep step={<StepFourTutorialV2 />} stepNumber={4} title={'ビンゴを達成するとチケットGET'} previous={ () => setStep(3) } next={ () => setStep(5) } /> } */}
        </>
    )
}

export default Tutorial