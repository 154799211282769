import React from 'react';

const StepBody = (props) => {
    const top = (props.innerHeight / 2) - 180 + 'px';

    return (
        <div className="kumahaku-body">
          <p className="kumahaku-body-txt1">各ビンゴシートにある、くま博の2つのイベント会場（アミュひろば・花畑広場）のビンゴパネルを獲得した先着3000名様に、</p>
          <p className="kumahaku-body-txt2">くま博限定のオリジナルトートバックをプレゼント！</p>
          {/* <p className="kumahaku-body-txt3">ビンゴ例１：初級シート①（熊本市内）の場合
          「アミュひろば」「花畑広場」「くまモンスクエア」
          「熊本現代美術館」のビンゴ達成</p>
          <p className="kumahaku-body-txt4">ビンゴ例２：中級シート②（宇城・天草）の場合
              「アミュひろば」「花畑広場」「フォレストアドベンチャー・美里」</p> */}
          <p className="kumahaku-body-txt5">条件を満たした方には、次の引き換え場所でプレゼントをお渡しします。</p>
          <p className="kumahaku-body-txt6">スタッフに達成したビンゴシートをご提示ください</p>
        </div>
    )
}

export default StepBody