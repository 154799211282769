import {RANGE_GET_SHOP} from '../googleMap/map'

/**
 * @param {number| string} shopId
 * @returns {{url: string}}
 */
export function getDetailShop(shopId) {
  return { url: '/shops/' + shopId };
}

/**
 * @param {object} currentPosition. EX: {lat: number, lng: number}
 * @param {number} range
 * @returns {{url: string}}
 */
export function listShop(currentPosition, range=RANGE_GET_SHOP) {
  return {url: `/shops?latitude=${currentPosition.lat}&longitude=${currentPosition.lng}&range=${range}`}
}
