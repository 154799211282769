import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { mainColor } from "../../helper/helper";
import IconBingo from "../../static/svg/IconBingo";
import IconHome from "../../static/svg/IconHome";
import IconMenu from "../../static/svg/IconMenu";
import "./styles.scss";
const TabBarBottom = (props) => {
  const dispatch = useDispatch();
  const [display, setDisplay] = useState();
  const [show, setShow] = useState(true);
  const location = useLocation();
  if (window.localStorage.length === 0) {
    window.location.href = '/';
  }

  const menuRedirect = [
    "/privacy-protection",
    "/problems",
    "/term-of-use",
    "/member-agreement",
  ];

  const bingoRedirect = [
    "^/bingo/([0-9a-zA-Z]+)/map$",
    "^/bingo/([0-9a-zA-Z]+)/challenge$",
    "^/select-bingo",
    "^/bingo/([0-9a-zA-Z]+)/spots$",
    "^/bingo/([0-9a-zA-Z]+)/spot/([0-9a-zA-Z]+)/",
    "^/bingo/([0-9a-zA-Z]+)/spot-checkin/([0-9a-zA-Z]+)/",
    "^/exchange-ticket"
  ]

  const checkPath = (pathName, paths) => {
    let status = false;
    paths.forEach(element => {
      const regex = new RegExp(element);
      if (regex.test(pathName)) {
        status = true;
      }
    })
    return status;
  }

  const homeRedirect = [
    "^/notification$",
    "^/notifications/([0-9a-zA-Z]+)$",
    "^/bingo-log$",
  ]

  useEffect(() => {
    // hide when screen is error
    setShow(document.getElementsByClassName('error-screen').length === 0);
    if (location.pathname === "/" || checkPath(location.pathname, homeRedirect)) {
      setDisplay(1);
    } else if (checkPath(location.pathname, bingoRedirect)) {
      setDisplay(2);
    } else if (
      location.pathname === "/menu" ||
      menuRedirect.includes(location.pathname)
    ) {
      setDisplay(3);
    } else {
      setDisplay(0)
    }
  }, [location.pathname, menuRedirect, bingoRedirect, homeRedirect]);

  const showbuton = () => {
    return (
      <div
      className={
        menuRedirect.includes(location.pathname)
          ? "tabbar_bottom tabbar_info"
          : "tabbar_bottom"
      }
    >
      <div className="footer-menu">
        <Link className={display === 1 ? "active item" : "item"} to="/">
          <div className="home-item">
            <IconHome fill={display === 1 ? mainColor : null} />
            <div className="name">HOME</div>
          </div>
        </Link>
        <Link className={display === 2 ? "active item" : "item"} to="/select-bingo">
          <div className="bingo-item">
            <IconBingo fill={display === 2 ? mainColor : null} />
            <div className="name">BINGO</div>
          </div>
        </Link>
        <Link className={display === 3 ? "active item" : "item"} to="/menu">
          <div className="menu-item">
            <IconMenu fill={display === 3 ? mainColor : null} />
            <div className="name">MENU</div>
          </div>
        </Link>
      </div>
    </div>
    );
  }
  const pathnameNoneFooter = ['/login', '/forgotpassword', '/register', '/redeem-card'];
  if (pathnameNoneFooter.indexOf(location.pathname) < 0) {
    return (
      <> 
      {show ? showbuton() : ''}
      </>
    );
  } else {
    return (
      <></>
    );
  }
};

export default TabBarBottom;
