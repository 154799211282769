import React from 'react';

const BingoHexagonBase = (props) => {
  return (
    <>
      <svg
        width={props.width ? props.width : '70'}
        height={props.height ? props.height : '80'}
        viewBox="0 0 70 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35 0L0 20V60L35 80L70 60V20L35 0Z" fill="#DEEFEF" />
        <path d="M35 0V80L0 60V20L35 0Z" fill="#EDF6F6" />
        <path d="M3 58.236V21.764L35 3.5L67 21.764V58.237L35 76.5L3 58.236Z" fill="white" />
      </svg>

    </>
  )
}
export default BingoHexagonBase;