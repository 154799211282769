import React, { useEffect } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/loading/Loading';
import HomeHeader from '../../components/headers/HomeHeader';
import RedeemCardBottomSuccess from './RedeemCardBottomSuccess';
import RedeemCardBottomInsufficient from './RedeemCardBottomInsufficient';
import MainButton from '../../components/buttons/MainButton';
import useQuery from 'common/src/util/useQuery';
import { getItemsSingle } from '../../redux/item/action';
import { useHistory } from 'react-router';
import { getUserInfo } from '../../redux/user/action';
import { serverApi } from '../../services/serverApi';
import { detailPlayer } from 'common/src/api/player';
import CopyRight from '../../components/copyRights/CopyRight';
import { backgroundGlay } from '../../helper/helper';

const RedeemCardScreen = () => {
  const playerId = window.localStorage.getItem('user_id');
  const dataItem = useSelector(state => state.items.items);
  const { isCheckUser } = useSelector((state) => state.user)
  const dispatch = useDispatch();
  const query = useQuery();
  const itemId = query.get('itemId');
  const deliveryId = query.get('deliveryId');
  const history = useHistory();

  const getInfoUser = async (playerId) => {
    const result = await serverApi(detailPlayer(playerId))
    if (result.data) {
      const player = result.data.data
      dispatch(getUserInfo(player))
    }
  }

  useEffect(async () => {
      if (!playerId) {
          return;
      }
      if (!isCheckUser) {
        getInfoUser(playerId);
      }
      if (dataItem.length <= 0) {
        const itemData = await serverApi({
          url: 'items/' + itemId
        });
        if (itemData) {
          dispatch(getItemsSingle(itemData.data.data))
        }
      }
  }, [])

  const handleClick = () => {
    history.push('/');
  }

  return (
    <>
      <HomeHeader />
      {!isCheckUser ? <Loading /> :
        <div className="redeem-card-screen">
          {deliveryId ? (
            <RedeemCardBottomSuccess />
          ) : (
            <RedeemCardBottomInsufficient />
          )}
          <div>          
            <div className="gohome-button">
              <MainButton name="TABINGOを開く" handleClick={handleClick}/>
            </div>
            <CopyRight backgroundColor={ backgroundGlay } />
          </div>
        </div>
      }
    </>
  )
}
export default RedeemCardScreen;