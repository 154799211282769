import React from "react";

const IconGenreHistory = (props) => {
  var svg;
  switch (props.sizeCategory ?? 1) {
    case 'size01':
      // 周辺地図/チェックイン画面
      svg = <svg width="24" height="29" viewBox="0 0 24 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.898 0H0.19L0 28.571H18.898V0ZM3.048 14.762V2.857H7.81V14.762H3.048ZM19.851 24.908H24V28.572H19.851V24.908ZM19.851 18.144H24V23.955H19.851V18.144ZM19.851 4.616H24V10.427H19.851V4.616ZM19.851 11.38H24V17.191H19.851V11.38ZM24 0V3.664H19.851V0H24Z" fill={props.fill}/>
      </svg>
      break;

    case 'size02':
      // 周辺地図チェックイン情報
      svg = <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.449 0H0.095L0 14.2855H9.449V0ZM1.524 7.381V1.4285H3.905V7.381H1.524ZM9.9255 12.454H12V14.286H9.9255V12.454ZM9.9255 9.072H12V11.9775H9.9255V9.072ZM9.9255 2.308H12V5.2135H9.9255V2.308ZM9.9255 5.69H12V8.5955H9.9255V5.69ZM12 0V1.832H9.9255V0H12Z" fill={props.fill}/>
      </svg>
      break;

    case 'size03':
      // ビンゴシート
      svg = <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M23.6225 0H0.2375L0 35.7137H23.6225V0ZM3.81 18.4525V3.57125H9.7625V18.4525H3.81ZM24.8137 31.135H30V35.715H24.8137V31.135ZM24.8137 22.68H30V29.9438H24.8137V22.68ZM24.8137 5.77H30V13.0338H24.8137V5.77ZM24.8137 14.225H30V21.4888H24.8137V14.225ZM30 0V4.58H24.8137V0H30Z" fill={props.fill}/>
      </svg>
      break;

    case 'size04':
      // パネル獲得時
      svg = <svg width="62" height="74" viewBox="0 0 62 74" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M48.8198 0H0.490833L0 73.8084H48.8198V0ZM7.874 38.1352V7.38058H20.1758V38.1352H7.874ZM51.2817 64.3457H62V73.811H51.2817V64.3457ZM51.2817 46.872H62V61.8837H51.2817V46.872ZM51.2817 11.9247H62V26.9364H51.2817V11.9247ZM51.2817 29.3983H62V44.4101H51.2817V29.3983ZM62 0V9.46533H51.2817V0H62Z" fill={props.fill}/>
      </svg>
      break;
  }

  return (
    <>{ svg }</>
  );
};
export default IconGenreHistory;
