import styled from 'styled-components'

const StyleHelp = styled.div`
  .help {
    background-color: #f3f3f4;

    .header_top {
      text-align: center;
      .title {
        background-color: #10cad6;
        height: 50px;

        h2 {
          color: #fff;
          margin: 0;
          position: relative;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 80%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 18px;
          font-weight: 500;
        }

        .load {
          position: absolute;
          top: 10px;
        }
      }
    }

    .header {
      background-color: #10cad6;
      height: 90px;
      text-align: center;

      git s h2 {
        color: white;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .group {
      display: flex;
      flex-direction: column;
      align-content: flex-start;
      background-color: #f3f3f4;

      .help-card {
        display: flex;
        padding-top: 10px;
        //padding-bottom: 10px;

        .left {
          margin-left:16px;
          height: 50px;
          width: 44px;
          position: relative;
          .frame {
            font-size: 110px;
            position: relative;

            .icon {
              font-size: 60px;
              position: absolute;
              left: 50%;
              top: 50%;
              margin-top: -30px;
              margin-left: -30px;
            }
          }
        }

        .right {
          margin-top: 1px;
          margin-bottom: auto;
          margin-left: 8px;
          height: 90%;
          width: calc(100% - 125px);

          .title {
            font-size: 12px;
            font-weight: bold;
            color: #ff3399;
            margin-bottom: 2px;
          }

          p {
            font-size: 10px;
            margin-right: 20px;
          }
        }
      }
    }
  }

  .helpHex {
    // position: relative;
  }

  .helpIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 23px;
  }

  .helpIcon2 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
  }

  .helpIcon3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 22px;
  }

  .helpFreeIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
  }
`

export default StyleHelp
