import React from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../components/loading/Loading';
import './styles.scss';
import useQuery from 'common/src/util/useQuery';

const RedeemCardBottomSuccess = () => {
  const { isCheckUser, userInfo } = useSelector((state) => state.user)
  const dataItem = useSelector(state => state.items.items);
  const query = useQuery();
  const deliveryId = query.get('deliveryId');

  const getAmountOfTicketsConsumed = (ticketId, wallets) => {
    let amountOfTicketsConsumed = 0;
    if (Array.isArray(wallets) && wallets.length > 0) {
      for (let i = 0; i < wallets.length; i++) {
        const walletData = wallets.filter(function (wallet) {
          return ticketId === wallet.ticketId;
        });
        if (walletData.length > 0) {
          amountOfTicketsConsumed = walletData[0].totalAcquisitionTicket;
        }
      }
    }
    return amountOfTicketsConsumed;
  }

  return (
    <>
      {
        (!isCheckUser || dataItem.length <= 0) ?
        <Loading /> : 
        <div className="redeem-card-bottom">
          <div className="top">
            <img src="icon/icon_reward_left.svg" className="img-left" alt="icon_reward_left"/>
            <span className="title-content">カードの引き換え</span>
            <img src="icon/icon_reward_right.svg" className="img-right" alt="icon_reward_right"/>
          </div>
          <div className="description">この画面を係員に見せ、カードと交換してください。</div>
          {/* display box items */}
          <div className="box-item">
            <div className="item-title">
              <div className="item-text-title">配布番号</div>
              <div className="item-event-code">{deliveryId}</div>
            </div>
            <div className="item-card">
              <div className="item-box-card">
                <div className="img-card">
                  <img src={dataItem[0].images[0]} alt="" />
                </div>
              </div>
            </div>
            <div className="item-text">{dataItem[0].name}</div>
            <div className="title-ticket">残りチケット所持数</div>
            {dataItem[0].tickets && dataItem[0].tickets.map((ticket) => (
              <>
              <div className="item-ticket-success">
                <div className="ticket-img">
                <img src={ ticket.thumbnails[0] ? ticket.thumbnails[0] : ''} alt="" />
                </div>
                <div className="ticket-text"><span>{ticket.title}:</span></div>
                <div className="ticket-point">
                  <span>{getAmountOfTicketsConsumed(ticket.id, userInfo.activities.wallets)}</span>
                </div>
              </div>
              <div className="ticket-deducted">
                <div className="ticket-text"><span className="text">チケット消費数：</span><span className="point">
                    <span className='text-red'>{ticket.amountOfTicketsConsumed > 0 ? -ticket.amountOfTicketsConsumed : ticket.amountOfTicketsConsumed}</span>
                  </span>
                </div>
              </div>
              </>
            ))}
          </div>
        </div>
      }
    </>
  )
}
export default RedeemCardBottomSuccess;