
export function getSpots(playsheetID, playerId, playerBingoId) {
  return { url: `/sheets/${playsheetID}/players/${playerId}/bingos/${playerBingoId}/spots` }
}
export function getSpotDetail(spotId) {
  return {
    url: `/shops/${spotId}`
  }
}

export function checkinSpot(playerId, params) {
  return {
    url: `/players/${playerId}/activities/checkins`,
    method: "post",
    data: params,
  }
}