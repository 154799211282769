import React from "react";

const IconButtonBack = (props) => {
  return (
    <svg
      width={props.width ? props.width : '18'}
      height={props.height ? props.height : '18'}
      viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 8H3.823L10.371 1.421L8.957 0L0 9L8.957 18L10.371 16.579L3.823 10H18V8Z"
        fill={props.fill ? props.fill : '#96A4BC'}
      />
    </svg>

  );
};
export default IconButtonBack;
