import React from 'react';
import HeaderGoBack from '../../components/HeaderGoBack'

const CommonProblem = () => {
  return (
    <React.Fragment>
      <div className="menu">
        <HeaderGoBack text="よくある質問"/>
        <div style={{ marginTop: "10px" }}>よくある質問</div>
      </div>
    </React.Fragment>
  );
}

export default CommonProblem;
