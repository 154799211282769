import React from 'react';
import HomeHeader from '../../components/headers/HomeHeader';
import { ILLUST_404_ERROR_IMG_PATH } from '../../static/svg';
import MainButton from '../../components/buttons/MainButton';

import './styles.scss';
import { Link } from 'react-router-dom';
const ErrorScreen = () => {
  return (
    <>
       <HomeHeader zIndex = {9999} />
      <div className="error-screen" style={{minHeight:window.innerHeight+"px"}}>
        <div className="img-error">
          <img src={ILLUST_404_ERROR_IMG_PATH} />
        </div>
        <div className="error-text">
          <div className="text-header">ページが見つかりません</div>
          <div className="text-content">
            <div className="box1">
              <p>このページは一時的にアクセスできない状況にあるか、</p>
              <p>URLが変更・削除された可能性がございます。</p>
            </div>
            <div className="box2">
              <p>ご不便をおかけしますが、ホームへ戻り引き続き</p>
              <p>TABINGOをお楽しみください。</p>
            </div>
          </div>
        </div>
        <Link to="/">
          <div className="error-button">
            <MainButton name ="ホームへ戻る" />
          </div>
        </Link>
      </div>
    </>
  )
}
export default ErrorScreen;