import React from 'react'

const StepBody = (props) => {

    return (
        <div className="kumahaku-body">
        <p className="kumahaku-body-txt1">タビンゴについてご不明な点等ございましたら、以下までご連絡ください。</p>
        <div className="kumahaku-body-box" style={{ marginTop: '32px', padding:'16px' }}>
            <p className='kumahaku-body-box-txt3'>熊本県内周遊促進業務運営事務局</p>
            <div className='kumahaku-body-box-tel'>
            <p className='kumahaku-body-box-tel-title'>TEL</p>
            <p className='kumahaku-body-box-tel-txt'>096-223-7325</p>
            </div>

            <div className='kumahaku-body-box-tel'>
            <p className='kumahaku-body-box-tel-title'>FAX</p>
            <p className='kumahaku-body-box-tel-txt'>096-223-7326</p>
            </div>

            <div className='kumahaku-body-box-tel'>
            <p className='kumahaku-body-box-tel-title'>Email</p>
            <p className='kumahaku-body-box-tel-txt'>kumahaku@nta.co.jp</p>
            </div>

            <div className="kumahaku-body-box-txt2" style={{ marginTop: '24px' }}><p className="kumahaku-body-box-txt2-child">交換時間</p>平日 10:00～17:00 (土・日・祝日　休業)</div>
        </div>
        <p className="kumahaku-body-copyright">@2010kumamoto pref.kumamon</p>
        </div>
    )
}

export default StepBody