/**
 *
 * @param {number | string} userId
 * @param {number | string} shopId
 * @param {object} dataCheckIn
 * @returns {{method: string, data: Object, url: string}}
 */
export function createPlayerCheckin(userId, shopId, dataCheckIn) {
  return {
    url: `/players/${userId}/shops/${shopId}`,
    method: "post",
    data: dataCheckIn,
  };
}
