import React from 'react'
import {Link} from 'react-router-dom'
import PopupModal from '../../components/modals/PopupModal'
import StyleErrorPage from './ErrorPage.style'

const Error500 = () => {
  return (
    <StyleErrorPage>
      <div className="error-modal">
        <PopupModal showCloseBtn={false}>
          <span className="icon-illust_error" style={{fontSize: '100px', margin: '20px'}}>
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
            <span className="path5"></span>
          </span>

          <p>
            <span> 問題が起きたためページが表示されません。 </span>
          </p>
          <p>
            <span> 再度読み込みを行ってください。  </span>
          </p>
          <div style={{width: '60%'}}>
            <Link to="/">
              <div className="btn" style={{color: '#ffffff'}}>ホームへ戻る</div>
            </Link>
          </div>
        </PopupModal>
      </div>
    </StyleErrorPage>
  )
}

export default Error500
