import React from 'react';
import ButtonDetail from '../../../static/svg/ButtonDetail';
import { mainColor } from '../../../helper/helper';
const BingoSpotInfo = (props) => {
  const spot = props.spot;
  const spotUrl = props.url;
  
  return (
    <>
      <div className="bingo-spot-info">
        <div className="info-header">
          {!props.showButtonCheckin &&
          <>
          <div className="title">スポット詳細</div>
          {spotUrl &&
            <a href={spotUrl} target={'_blank'}>
              <div className="link-web">詳細をwebで見る <ButtonDetail fill={mainColor} /></div>
            </a>
          }
          </>
          }
        </div>
        <div className="info-content">
          <div className="row">
            <div className="text-left">住所</div>
            <div className="text-right">{spot?.addresses?.postalCode}{spot?.addresses?.prefecture}{spot?.addresses?.localGovernments}{spot?.addresses?.streetNumber}</div>
          </div>
          <div className="row">
            <div className="text-left">アクセス</div>
            <div className="text-right">{spot.access}</div>
          </div>
          <div className="row">
            <div className="text-left">電話番号</div>
            <div className="text-right"><a className="tel" href={`tel:${spot?.tel}`}>{spot?.tel}</a></div>
          </div>
          <div className="row">
            <div className="text-left">営業時間</div>
            <div className="text-right">{spot.receptionTime}</div>
          </div>
          <div className="row">
            <div className="text-left">定休日</div>
            <div className="text-right">{spot.holiday}</div>
          </div>
        </div>
      </div>
    </>
  )
}
export default BingoSpotInfo;