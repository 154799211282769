import React from 'react'
import { Link } from 'react-router-dom'
import './styles.scss'
import { formatDate } from '../../../../helper/helper'
import moment from 'moment'
const NotificationItemNoImage = (props) => {
  const date = moment(props.data.registeredAt).format(formatDate)
  return (
    <Link to={"/notifications/" + props.data.id} key={props.data.id}>
      <div className="notification-item-no-image">
        <div className="item-text">
          <div className="title" dangerouslySetInnerHTML={{ __html: props.data.title }} />
          <div className="date">{date}</div>
        </div>

      </div>
    </Link>
  );
}

export default NotificationItemNoImage
