import React from 'react';
import GoBackHeader from '../../components/headers/GoBackHeader';
import BingoLogSpot from '../home/tabs/bingoLog/BingoLogSpot';
import BingoLogItem from '../home/tabs/bingoLog/BingoLogItem';
import BingoLogClearItem from '../home/tabs/bingoLog/BingoLogClearItem';
import BingoLogLineItem from '../home/tabs/bingoLog/BingoLogLineItem';
import CopyRight from '../../components/copyRights/CopyRight';
import { HEADER_TABBAR_COPYRIGHT_HEIGHT } from '../../helper/helper'
import './styles.scss';
import { useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import { getBingoHistory, updateHistoryList } from '../../redux/bingoHistory/action'

import LoadmoreButton from '../../components/buttons/LoadmoreButton';
import Loading from '../../components/loading/Loading';
import { getPlayerEvents } from '../../redux/event/action';

const BingoLogScreen = () => {

  const dispatch = useDispatch()
  const playerId = window.localStorage.getItem('user_id');
  const { isGettingEventPlayer } = useSelector((state) => state.event);
  const { bingoHistory, isBingoHistories, pageNumber, showBtn, needReloadHistory} = useSelector((state) => state.history)
  const size = 5
  const page = 1

  useEffect(() =>{
    if (!isGettingEventPlayer) {
      dispatch(getPlayerEvents({ playerId: playerId }))
    }
  },[])

  useEffect(()=>{
    if (isGettingEventPlayer || needReloadHistory) {
      if (bingoHistory.length <= 0 ) {
        getListHistory()
      }
    } 
  },[isGettingEventPlayer])

  const getListHistory = async() => {
    await dispatch(getBingoHistory({ 
      playerId: playerId, 
      size: size, 
      page: page
    }))
  }

  const handleLoadMore = async () => {
    const page = pageNumber + 1
   
    await dispatch(updateHistoryList(page))
    await dispatch(getBingoHistory({ 
      playerId: playerId, 
      size: size, 
      page: page
    }))
  }

  const jumpToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  useEffect(() => {
    jumpToTop()
  }, [])
  return (
    <>
      <GoBackHeader name="ビンゴログ一覧" />
      {!isBingoHistories && <Loading />}
      <div className="bingo-log-screen" >
        <div className="bingo-log-list" style={{ minHeight: window.innerHeight - HEADER_TABBAR_COPYRIGHT_HEIGHT + "px" }}>
          {bingoHistory && bingoHistory.map((item, idx) =>
              item.type === 1 ? <BingoLogSpot data={item} key={idx} /> :
              item.type === 2 ? <BingoLogItem data={item} key={idx} /> :
              item.type === 4 ? <BingoLogLineItem data={item} key={idx} /> :
              <BingoLogClearItem data={item} key={idx} />)}
          <div className="box-button-next">
            {showBtn && <LoadmoreButton name="もっと見る" isLoading={!isBingoHistories} onClick={() => { handleLoadMore(pageNumber)}} />}
          </div>
        </div>
        <CopyRight />
      </div>
    </>
  )
}
export default BingoLogScreen;