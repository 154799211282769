import React from 'react'

const Idicator = (props) => {
  const stepNumber = props.stepNumber;
  return (
    <div className="idicator">
      <div className="move-pre">
      {
        stepNumber === 1 ?
        <div className="clear-button"></div> :
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ props.previous }>
          <circle cx="20" cy="20" r="20" transform="rotate(-180 20 20)" fill="white"/>
          <path d="M29 19H14.823L21.371 12.421L19.957 11L11 20L19.957 29L21.371 27.579L14.823 21H29V19Z" fill="#10CAD6"/>
        </svg>
      }
      </div>
      <div className="current-position">
        <svg width="104" height="8" viewBox="0 0 104 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4" cy="4" r="4" fill={stepNumber === 1 ? "#10CAD6" : 'white'} />
          <circle cx="36" cy="4" r="4" fill={stepNumber === 2 ? "#10CAD6" : 'white'} />
          <circle cx="68" cy="4" r="4" fill={stepNumber === 3 ? "#10CAD6" : 'white'} />
          <circle cx="100" cy="4" r="4" fill={stepNumber === 4 ? "#10CAD6" : 'white'} />
        </svg>
      </div>
      <div className="move-next">
        {
          stepNumber === 4 ?
          <div className="clear-button"></div> :
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={ props.next }>
            <circle cx="20" cy="20" r="20" fill="white"/>
            <path d="M11 21L25.177 21L18.629 27.579L20.043 29L29 20L20.043 11L18.629 12.421L25.177 19L11 19L11 21Z" fill="#10CAD6"/>
          </svg>
        }
      </div>
    </div>
  )
}

export default Idicator
