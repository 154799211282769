import { actionTypes, getBingosSelect } from './action'

const initState = {
  bingos: [],
  sheetDetail: null,
  isGettingBingos: false,
  isGettingSheetDetail: false,
  checkedSpots: [],
  isUpdateSheetDetail: false,
  lastChecked: null,
  lineNewChecked: 0,
  needReloadBingos: false,
}

const bingoReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.GET_BINGOS_SELECT:
      return {
        ...state,
        isGettingBingos: true,
      };
    case actionTypes.GET_BINGOS_SELECT_SUCCESS:
      const bingos = payload;
      const unLockSpots = bingos.filter((x) => x.isLock === false);
      const checkedSpot = [];
      if (unLockSpots) {
        unLockSpots.map((item) => {
          const checkeds = item.panels.filter((x) => x.isCheckin === true);
          checkeds && checkeds.map((checked) => {
            checked && checked.spotId && checkedSpot.push(checked.spotId);
          })
        })
      }
      return {
        ...state,
        bingos: bingos,
        isGettingBingos: false,
        checkedSpots: checkedSpot
      };
    case actionTypes.GET_BINGOS_SELECT_FAIL:
      return {
        ...state,
        isGettingBingos: false,
      };
    case actionTypes.GET_SHEET_DETAIL:
      return {
        ...state,
        isGettingSheetDetail: true,
      };
    case actionTypes.GET_SHEET_DETAIL_SUCCESS:
      const sheetDetail = payload;
      const lines = sheetDetail.lines;
      const panels = sheetDetail.panels;
      let count_checked = panels.filter((x) => x.isChecked === true);
      let line_no_checked = 0;
      if (lines && lines.length > 0) {
        lines.map((item) => {
          let lines_is_clear = true;
          for (var i = 0; i < item.cells.length; i++) { //loop cells in lines
            if (panels[item.cells[i] - 1].isChecked === false) {
              lines_is_clear = false;
              line_no_checked = line_no_checked + 1;
              break;
            }
          }
          if (lines_is_clear) {
            item.isClear = true;
            for (var i = 0; i < item.cells.length; i++) { //loop cells in lines
              panels[item.cells[i] - 1].color = 'yellow';
            }
          }
        })
      }
      let line_checked = 15 - line_no_checked;
      sheetDetail.lineChecked = line_checked;
      sheetDetail.countChecked = count_checked.length + 1;
      return {
        ...state,
        sheetDetail: sheetDetail,
        isGettingSheetDetail: false
      };
    case actionTypes.GET_SHEET_DETAIL_FAIL:
      return {
        ...state,
        isGettingSheetDetail: false,
      };
    case actionTypes.UPDATE_SHEET_DETAIL:
      return {
        ...state,
        isUpdateSheetDetail: payload
      }
    case actionTypes.SET_CHECKED_SPOT:
      return {
        ...state,
        checkedSpots: payload
      }
    case actionTypes.SET_LAST_CHECKED:
      return {
        ...state,
        lastChecked: payload
      }
    case actionTypes.SET_RELOAD_BINGOS:
      return {
        ...state,
        needReloadBingos: payload
      }
    case 'LOG_OUT':
      return initState;
    default:
      return state
  }
}

export default bingoReducer
