import React from 'react'
import styled from 'styled-components'

const StyleFooterBuilding = styled.div`
  margin-bottom: 56px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 166px;
  width:100%;

  .background-building {
    width: 100%;
    height: auto;
    display: block;
  }

  .copy-right {
    text-align: center;
    background-color: #8592AA;
    color: #fff;
    width: 100%;
    height: 30px;
    font-size: 9px;
    display: block;
    margin-top: -1px;
    position:relative;
    span{
      margin: 0;
      position: absolute;
      top: 41%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }

  @media only screen and (min-width: 300px) {
    height: 80px;
  }
  @media only screen and (max-width: 280px){
    .copy-right {
      font-size: 10px;
    }
  }
`

const FooterBuilding = () => {
  return (
    <StyleFooterBuilding className="menu-building">
      <div>
        <img src="/icon/toa-nha.svg" className="background-building" alt=""/>
      </div>
      <div className="copy-right">
      <span>Copyright <i className="fa fa-copyright"/> POCKET CULTURE CO.,Ltd. All Rights Reserved.</span>
      </div>
      
    </StyleFooterBuilding>
  )
}

export default FooterBuilding
