import useQuery from 'common/src/util/useQuery';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import HomeHeader from '../../components/headers/HomeHeader';
import { MIN_PASSWORD, REGEX_MAIL_REGISTER, REGISTER } from '../../helper/helper';
import { setLogin } from '../../redux/user/action';
import firebase from '../../services/firebase';
import { serverApiNoAuth } from '../../services/serverApi';
import './LoginMailStyle.scss';

const LoginMail = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showvalidate, setShowvalidate] = useState(false);
  const [validatemail, setvalidateMail] = useState("");
  const [validatpass, setvalidatePass] = useState("");
  const [discable, setdiscable] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [isLoadingscreen, setIsLoadingscreen] = useState(false);
  const query = useQuery();
  const itemId = query.get('itemId');
  const eventId = query.get('eventId');

  useEffect(() => {
    document.body.className = 'signin';
    return () => { document.body.className = ''; }
  }, [])

  const validateMail = () => {
    var vnf_regex = REGEX_MAIL_REGISTER;
    if (email.length > 0) {
      if (vnf_regex.test(email)) {
        setvalidateMail('');
        return true;
      } 
      if (!vnf_regex.test(email)) {
        setvalidateMail('メールアドレスを正しく入力してください')
        return false;
      }
    } else {
      setvalidateMail('メールアドレスを入力してください');
      return false;
    }
  }

  const validatePass = () => {
    if (password.length == 0 || password.length < MIN_PASSWORD) {
      setvalidatePass('任意の英(大小文字含む)6 文字以上で、文字と数字を組み合わせた安全なパスワードを設定してください');
      return false;
    } else {
      setvalidatePass('');
      return true;
    }
  }

  const LoginMailClick = async () => {
    if(!discable) {
      setdiscable(true);
      setIsLoading(true);
      const mail = validateMail();
      const pass = validatePass()
      if (mail && pass) {
        firebase.auth().signInWithEmailAndPassword(email, password)
        .then(async (userCredential) => {
          setShowvalidate(false);
          var user = await userCredential.user;
          const id_user = user.za;
          const data = { name: "", access_token: id_user, origin_name: "" };
          const respond = await serverApiNoAuth.post("players/login", data);  
          if(respond.status == 200) {
            window.localStorage.setItem("user_id", respond.data.data.id);
            window.localStorage.setItem(REGISTER, "")
            window.localStorage.setItem('needAccessKey', true);
            dispatch(setLogin(true));
            if (!window.localStorage.getItem("showTutorial")) {
              if (itemId && eventId) {
                history.push('/tutorial/?itemId=' + itemId + '&eventId=' + eventId);
              } else {
                history.push('/tutorial');
              }
            } else {
              window.localStorage.setItem('showTabBarBottom', true);
              if (itemId && eventId) {
                history.push('/?itemId=' + itemId + '&eventId=' + eventId);
              } else {
                history.push('/');
              }
            }
          } else {
            setIsLoading(false);
            setdiscable(false);
          }
        })
        .catch((error) => {
          setShowvalidate(true);
          setIsLoading(false)
          setTimeout(() => setdiscable(false), 2000);
          setvalidatePass('メールアドレス又はパスワードが間違っています');
        });
      } else {
        setIsLoading(false);
        setTimeout(() => setdiscable(false), 2000);
        setShowvalidate(true);
      }
    }
  }

  const FotgotClick = () => {
    window.location.pathname = '/forgotpassword';
  }

  const RegisterClick = () => {
    window.location.pathname = '/register';
  }
 
  return (
    
    <div>
      <HomeHeader />
      <div className="login-mail">
        <div className="title-login"> 
          <img src="icon/rainbow_kirakira_L.svg" className="imgLeft" alt="rainbow_kirakira_L"/>
          <h2>会員の方はコチラ</h2>
          <img src="icon/rainbow_kirakira_R.svg" className="imgRight" alt="rainbow_kirakira_R"/>
        </div>
        <div className="form-login ">
          <div className="divusername">        
            <p style={{ color: validatemail.length > 0 ? '#FF0001' : '#45556D' }}>メールアドレス</p>
            <input style={{ borderBottom: validatemail.length > 0 ? '2px solid #FF0001' : ''}} type="text" id="" name="username" onChange={e => setEmail(e.target.value)} placeholder="xxx@tabingo.com" />
            {showvalidate && <p className="error-message">{validatemail}</p>}
          </div>
          <div className="divpassword">
            <p style={{ color: validatpass.length > 0 ? '#FF0001' : '#45556D' }}>パスワード</p>
            <input style={{ borderBottom: validatpass.length > 0 ? '2px solid #FF0001' : ''}} type="password" id="" name="password" onChange={e => setPassword(e.target.value)} placeholder="英数字8〜14文字" />
            {showvalidate && <p className="error-message">{validatpass}</p>}
          </div>
          <div className="forgot-password" onClick={FotgotClick}>パスワードを忘れた方
            <img src="icon/icon_vector_right.svg" alt="もっと見る" />
          </div>
        </div>
        <div className="loginBtn"  onClick={LoginMailClick} >
          <button className="btn"> 
            {isLoading ? ( 
                <i className="fa fa-refresh fa-spin" />
              ) : ( 
              <span>ログイン</span>
            )}
          </button>
        </div>
        <div className="title-login login-first-time"> 
          <img src="icon/rainbow_kirakira_L.svg" className="imgLeft" alt="rainbow_kirakira_L"/>
          <h2>初めての方はコチラ</h2>
          <img src="icon/rainbow_kirakira_R.svg" className="imgRight" alt="rainbow_kirakira_R"/>
        </div>
        <div className="loginBtn resBtn" onClick={RegisterClick}>
          <button className="btn">  
            <span>新規会員登録</span>
          </button>
        </div>
        <div className="footerLogin">
          会員登録に伴い、<span><a href="/term-of-use">利用規約</a></span>、<span><a href="/privacy-protection">個人情報保護方針</a></span>に 
          同意したものとみなされます。
        </div>
      </div>
    </div>
  );
}

export default LoginMail;
