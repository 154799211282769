import {listShop} from 'common/src/api/shop'
import {NUMBER_HEX_PER_SIDE, getBasePoint, constructCenters, calculateRLng, calculateH} from 'common/src/googleMap/map'
import {transformShop, getShopDisplayInHex} from 'common/src/googleMap/shop'
import useQuery from 'common/src/util/useQuery'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {serverApi} from '../../services/serverApi'
import StyleMapInfo from './MapInfo.style'

const checkString = (str) => {
  if (str.includes('"')) return "'";
  if (str.includes("'")) return '"';
  return '"';
}

const MapInfo = (props) => {
  // Hex
  // hex center
  const [hexCenters, setHexCenters] = useState([])
  const [preShops, setPreShops]     = useState([])
  const [shops, setShops]           = useState([])
  const [pre, setPre]               = useState(true)

  const query           = useQuery()
  const currentPosition = {lat: parseFloat(query.get('lat')), lng: parseFloat(query.get('lng'))}

  //redux
  let checkedShops = useSelector(state => state.bingo.shop_ids)
  let needUpdate   = useSelector(state => state.bingo.need_update)

  const getShop = async () => {
    if (hexCenters.length !== 0) {
      const result = await serverApi(listShop(currentPosition))

      if (result.error) {
        console.log(result.error)
      } else {
        let hexCentersLocal = hexCenters.map((hexCenter, idx) => {
          return {...hexCenter, shopsCount: 0, idx: idx, shop: idx + 1}
        })
        // Check if lat & lng legit or not
        let shopData        = result.data.data
          .filter(a => {
            return (typeof a.latitude === 'number') && (typeof a.longitude === 'number')
          })
          .map(shop => transformShop(checkedShops, shop))

        setPreShops(shopData)
        shopData = getShopDisplayInHex(hexCentersLocal, shopData)
        setShops(shopData)
      }
    }
  }

  useEffect(() => {
    if (hexCenters.length > 0 && !needUpdate)
      getShop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hexCenters, needUpdate])

  useEffect(() => {
    console.log('Current position: ', currentPosition)
    if (!isNaN(currentPosition.lat) && !isNaN(currentPosition.lng)) {
      const r_lng  = calculateRLng(currentPosition)
      const h      = calculateH()
      // caculate nearest point with user for hex render
      let cp  = getBasePoint(currentPosition, r_lng, h)
      // caculate hex centers
      const polygonsPoint = constructCenters(cp, r_lng, h, NUMBER_HEX_PER_SIDE)
      setHexCenters(polygonsPoint)
      console.log('Number of poly: ', polygonsPoint.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (<React.Fragment>
    <button onClick={() => setPre(true)} >Before processing</button>
    <button onClick={() => setPre(false)} >After processing</button>
    {pre ? <React.Fragment>
      <StyleMapInfo style={{marginBottom: "60px"}}>
        <h2>Number of shops: {preShops.length}</h2>
        <div className='bracket'>{'['}</div>
        {preShops.map((shop, index) => <div className='data' key={index}>
          <div className='symbol'>{'{'}</div>
          <span>
            <div className='property'>"Name":&nbsp;</div>
            {checkString(shop.name)}{shop.name}{checkString(shop.name)},
          </span>
          <span>
            <div className='property'>"Lat":&nbsp;</div>
            "{shop.map.lat}",
          </span>
          <span>
            <div className='property'>"Lng":&nbsp;</div>
            "{shop.map.lng}",
          </span>
          <span>
            <div className='property'>"Food":&nbsp;</div>
            {checkString(shop.food)}{shop.food}{checkString(shop.food)},
          </span>
          <span>
            <div className='property'>"Address":&nbsp;</div>
            {checkString(shop.address)}{shop.address}{checkString(shop.address)},
          </span>
          <span>
            <div className='property'>"Completed":&nbsp;</div>
            "{shop.completed}",
          </span>
          <span>
            <div className='property'>"Point":&nbsp;</div>
            "{shop.point}",
          </span>
          <span>
            <div className='property'>"Tel":&nbsp;</div>
            "{shop.tel}",
          </span>
          <span>
            <div className='property'>"Url":&nbsp;</div>
            <a href={shop.url}>"{shop.url}"</a>,
          </span>
          <span>
            <div className='property'>"Ablum":&nbsp;</div>
            <div className='list'>
              <div className='symbol'>[</div>
              {shop.album.map((img, index) => <span key={index}><a href={img}>"{img}"</a>,</span>)}
              <div className='symbol'>]</div>
            </div>
          </span>
          <div className='symbol'>{'},'}</div>
        </div>)}
        <div className='bracket'>{']'}</div>
      </StyleMapInfo>
    </React.Fragment> :
      <React.Fragment>
        <div style={{marginBottom: "60px"}}>
          <h2>Number of shops: {shops.length}</h2>
          <div className='bracket'>{'['}</div>
          {shops.map((shop, index) => <div className='data' key={index}>
            <div className='symbol'>{'{'}</div>
            <span>
              <div className='property'>"Name":&nbsp;</div>
              {checkString(shop.name)}{shop.name}{checkString(shop.name)},
            </span>
            <span>
              <div className='property'>"Lat":&nbsp;</div>
              "{shop.map.lat}",
            </span>
            <span>
              <div className='property'>"Lng":&nbsp;</div>
              "{shop.map.lng}",
            </span>
            <span>
              <div className='property'>"Food":&nbsp;</div>
              {checkString(shop.food)}{shop.food}{checkString(shop.food)},
            </span>
            <span>
              <div className='property'>"Color":&nbsp;</div>
              <div style={{ color: shop.color }}>"{shop.color}",</div>
            </span>
            <span>
              <div className='property'>"Address":&nbsp;</div>
              {checkString(shop.address)}{shop.address}{checkString(shop.address)},
            </span>
            <span>
              <div className='property'>"Completed":&nbsp;</div>
              "{shop.completed}",
            </span>
            <span>
              <div className='property'>"Point":&nbsp;</div>
              "{shop.point}",
            </span>
            <span>
              <div className='property'>"Tel":&nbsp;</div>
              "{shop.tel}",
            </span>
            <span>
              <div className='property'>"Url":&nbsp;</div>
              <a href={shop.url}>"{shop.url}"</a>,
            </span>
            <span>
              <div className='property'>"HEX_Number":&nbsp;</div>
              "{shop.HEX_Number}",
            </span>
            <span>
              <div className='property'>"Ablum":&nbsp;</div>
              <div className='list'>
                <div className='symbol'>[</div>
                {shop.album.map((img, index) => <span key={index}><a href={img}>"{img}"</a>,</span>)}
                <div className='symbol'>]</div>
              </div>
            </span>
            <div className='symbol'>{'},'}</div>
          </div>)}
          <div className='bracket'>{']'}</div>
        </div>
      </React.Fragment>
    }
  </React.Fragment>
  );
};

export default MapInfo
