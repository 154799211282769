import React, {useState} from 'react';
import BingoLog from './bingoLog/BingoLog';
import HomeNotification from './notification/HomeNotification';
import './styles.scss';
import CopyRight from '../../../components/copyRights/CopyRight';
const TAB_NOTIFICATION = 1
const TAB_BINGO_LOG = 3

const COMPONENT_NOTIFICATION = <HomeNotification />
const COMPONENT_BINGO_LOG = <BingoLog />

const HomeTab = () => {
  const [component, setComponent] = useState(COMPONENT_NOTIFICATION)
  const [activeTab, setActiveTab] = useState(TAB_NOTIFICATION)

  return (
    <>
      <div className="home-tabbar">
        <div className={activeTab === TAB_NOTIFICATION ? 'tab active' : 'tab'}>
          <button
            className={activeTab === TAB_NOTIFICATION ? 'item active' : 'item'}
            onClick={() => {
              setComponent(COMPONENT_NOTIFICATION)
              setActiveTab(TAB_NOTIFICATION)
            }}
          >
            お知らせ
          </button>
        </div>
        <div className={activeTab === TAB_BINGO_LOG ? 'tab active' : 'tab'}>
          <button
            className={activeTab === TAB_BINGO_LOG ? 'item active' : 'item'}
            onClick={() => {
              setComponent(COMPONENT_BINGO_LOG)
              setActiveTab(TAB_BINGO_LOG)
            }}
          >
            ビンゴログ
          </button>
        </div>
      </div>

      {component}
      <CopyRight />
    </>
  )
}

export default HomeTab
