import React from 'react';

const IconEdit = (props) => {
  return (
    <>
      <svg
        width={props.width ? props.width : '16'}
        height={props.height ? props.height : '16'}
        viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 5.047L6.117 14.93L1.07 9.883L10.953 0L16 5.047ZM0.48 10.944L0 16L5.056 15.52L0.48 10.944Z"
          fill={props.fill ? props.fill : '#96A4BC'}
        />
      </svg>

    </>
  )
}
export default IconEdit;