import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import PopupModal from '../../../components/modals/PopupModal'
import StyleConfirmModal from './ConfirmModal.style'

const ConfirmModal = (props) => {
  const playerBingoId = window.localStorage.getItem('playerBingoId');
  const illust_panel_blue   = (
    <span className="icon-illust_panel_blue icon">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
      <span className="path7"></span>
      <span className="path8"></span>
      <span className="path9"></span>
      <span className="path10"></span>
    </span>
  )
  const illust_panel_green  = (
    <span className="icon-illust_panel_green icon">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
      <span className="path7"></span>
      <span className="path8"></span>
      <span className="path9"></span>
      <span className="path10"></span>
    </span>
  )
  const illust_panel_red    = (
    <span className="icon-illust_panel_red icon">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
      <span className="path7"></span>
      <span className="path8"></span>
      <span className="path9"></span>
      <span className="path10"></span>
    </span>
  )
  const illust_panel_orange = (
    <span className="icon-illust_panel_orange icon">
      <span className="path1"></span>
      <span className="path2"></span>
      <span className="path3"></span>
      <span className="path4"></span>
      <span className="path5"></span>
      <span className="path6"></span>
      <span className="path7"></span>
      <span className="path8"></span>
      <span className="path9"></span>
      <span className="path10"></span>
    </span>
  )

  const textColor =
          props.color === 'blue'
          ? '#338ee0'
          : props.color === 'red'
            ? '#ff4e4f'
            : props.color === 'green'
              ? '#71c400'
              : 'orange'
  //const imgSucess = (props.color === "blue") ? illust_panel_blue : (props.color === "red") ? "/icon/illust_panel_red.svg" : (props.color === "green") ? "/icon/illust_panel_green.svg" : "/icon/illust_panel_orange.svg";
  const imgSucess =
          props.color === 'blue'
          ? illust_panel_blue
          : props.color === 'red'
            ? illust_panel_red
            : props.color === 'green'
              ? illust_panel_green
              : illust_panel_orange

  return (
    <StyleConfirmModal>
      <PopupModal onClose={props.onClose}>
        <div className="boxImage">
          {imgSucess}
          <div className="confirmIcon">
            <span className="icon-icon_genre_washoku" style={{color: textColor}}/>
          </div>
        </div>
        <p>
          <span>
            <span className="s18" style={{color: textColor}}>ご飯のパネル</span>
            <span className="s15" style={{color: textColor}}>
            {props.color === 'red'
             ? '「赤」'
             : props.color === 'blue'
               ? '「青」'
               : props.color === 'orange'
                 ? '「黄」'
                 : '「緑」'}
            
          </span>
          <span className="s15">を</span>
          <br/>
          <span className="s15">獲得しました！</span>
            </span>
        </p>
        <div className="confirmBtn"><Link to={{pathname: `bingo/${ playerBingoId }/challenge`}}> 確認する </Link></div>
      </PopupModal>
    </StyleConfirmModal>
  )
}

export default withRouter(ConfirmModal)
