import React from 'react'
import { useHistory } from 'react-router-dom';
import useQuery from 'common/src/util/useQuery';

const StepBot = (props) => {
  const history = useHistory();
  const query = useQuery();
  const itemId = query.get('itemId');
  const eventId = query.get('eventId');
  const nextPage = () => {
    window.localStorage.removeItem('backLogin');
    window.localStorage.setItem("showTabBarBottom", true);
    window.localStorage.setItem("showTutorial", true);
    if (itemId && eventId) {
      history.push('/?itemId=' + itemId + '&eventId=' + eventId);
    } else {
      history.push('/');
    }
  }
  return (
    <div className="tutorial-bot">
      <div className="bot-text">
        <a href="#" className="next" onClick={() => nextPage()}>TABINGOをはじめる</a>
      </div>
    </div>
  )
}

export default StepBot
