import axios from 'axios';
import { store } from '../redux/store';
import { get_jwt } from '../redux/user/action';
import firebase from './firebase';

export const serverApiNoAuth = axios.create({
  baseURL: process.env.REACT_APP_CLOUD_FUNCTION_ENDPOINT,
  headers: { "Access-Control-Allow-Origin": "*" }
});

export const serverApi = async ({ url, method = 'get', data = null, oneTimeOnly = false }) => {

  const state = store.getState();
  const authToken = state.user.jwt_token;
  console.log('++++++++++++++++++ API ++++++++++++++++++');
  console.log(`${process.env.REACT_APP_CLOUD_FUNCTION_ENDPOINT}/${url}`);
  const request = async ({ bearer, url, method, data }) => {
    const axiosObj = axios.create({ 
      baseURL: process.env.REACT_APP_CLOUD_FUNCTION_ENDPOINT,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": "Bearer " + bearer,
      }
    });

    let result;
    switch (method) {
      case 'post':
        result = await axiosObj.post(url, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        break;
      case 'put':
        result = await axiosObj.put(url, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        break;
      case 'delete':
        result = await axiosObj.delete(url);
        break;
      case 'patch':
        result = await axiosObj.patch(url, data);
        break;
      default:
        result = await axiosObj.get(url);
        break;
    }
    return result;
  };
  try {
    return await request({bearer: authToken, url: url, method: method, data: data});
  }
  catch (err) {
    if (err.response === null || err.response === undefined) {
      if (oneTimeOnly === true) {
        return { error: 442 }
      } else {
        return await request({bearer: authToken, url: url, method: method, data: data, oneTimeOnly: true});
      }
    }
    switch (err.response.status) {
      case 401:
        if (oneTimeOnly === true) {
          return { error: err.response.status }
        } else {
          let new_id = await firebase.auth().currentUser.getIdToken();

          store.dispatch(get_jwt(new_id))
          return await request({bearer: new_id, url: url, method: method, data: data, oneTimeOnly: true});
        }
      default:
        return { error: err.response.status };
    }
  }

}
