import ICON_HEADER_LOGO from './icon_headerLogo.png';
import LINE_TOWN_HOME from './line_town_home.png';
import BG_BINGO_SHEET_NORMAL  from './Bg_bingoseet_normal.png';
import BG_BINGO_SHEET_COMPLETE  from './Bg_bingoseet_complete.png';
import BINGO_REWARD from './BINGOREWARD.png';
import IMG_DETAIL_NOTIFI from './image_detail_notification.png';
import DEFAULT_IMAGE from './default.png';

export const ICON_HEADER_LOGO_IMG_PATH = ICON_HEADER_LOGO.toString();
export const LINE_TOWN_HOME_IMG_PATH = LINE_TOWN_HOME.toString();
export const BG_BINGO_SHEET_NORMAL_IMG_PATH = BG_BINGO_SHEET_NORMAL.toString();
export const BG_BINGO_SHEET_COMPLETE_IMG_PATH = BG_BINGO_SHEET_COMPLETE.toString();
export const BINGO_REWARD_IMG_PATH = BINGO_REWARD.toString();
export const IMG_DETAIL_NOTIFI_IMG_PATH = IMG_DETAIL_NOTIFI.toString();
export const DEFAULT_IMAGE_IMG_PATH = DEFAULT_IMAGE.toString();
