export const actionTypes = {
  GET_BINGOS_SELECT: 'GET_BINGOS_SELECT',
  GET_BINGOS_SELECT_SUCCESS: 'GET_BINGOS_SELECT_SUCCESS',
  GET_BINGOS_SELECT_FAIL: 'GET_BINGOS_SELECT_FAIL',

  GET_SHEET_DETAIL: 'GET_SHEET_DETAIL',
  GET_SHEET_DETAIL_SUCCESS: 'GET_SHEET_DETAIL_SUCCESS',
  GET_SHEET_DETAIL_FAIL: 'GET_SHEET_DETAIL_FAIL',

  SET_CHECKED_SPOT: 'SET_CHECKED_SPOT',
  SET_LAST_CHECKED:'SET_LAST_CHECKED',

  UPDATE_SHEET_DETAIL: 'UPDATE_SHEET_DETAIL',

  GET_BINGOS_HISTORY: 'GET_BINGOS_HISTORY',
  GET_BINGOS_HISTORY_SUCCESS: 'GET_BINGOS_HISTORY_SUCCESS',
  GET_BINGOS_HISTORY_FAIL: 'GET_BINGOS_HISTORY_FAIL',
  SET_RELOAD_BINGOS: 'SET_RELOAD_BINGOS',
}

export const setLastChecked = (params) => {
  return {
    type: actionTypes.SET_LAST_CHECKED,
    payload: params
  }
}

export const updateSheetDetail = (params) => {
  return {
    type: actionTypes.UPDATE_SHEET_DETAIL,
    payload: params
  }
}

export const setCheckedSpot = (params) => {
  return {
    type: actionTypes.SET_CHECKED_SPOT,
    payload: params
  }
}
export const getBingosSelect = (params) => {
  return {
    type: actionTypes.GET_BINGOS_SELECT,
    payload: params
  }
}
export const getBingosSelectSuccess = (data) => {
  return {
    type: actionTypes.GET_BINGOS_SELECT_SUCCESS,
    payload: data
  }
}
export const getBingosSelectFail = () => {
  return {
    type: actionTypes.GET_BINGOS_SELECT_FAIL
  }
}
export const setReloadBingos = (data) => {
  return {
    type: actionTypes.SET_RELOAD_BINGOS,
    payload: data
  }
}

//
export const getSheetDetail = (params) => {
  return {
    type: actionTypes.GET_SHEET_DETAIL,
    payload: params
  }
}
export const getSheetDetailSuccess = (data) => {
  return {
    type: actionTypes.GET_SHEET_DETAIL_SUCCESS,
    payload: data
  }
}
export const getSheetDetailFail = () => {
  return {
    type: actionTypes.GET_SHEET_DETAIL_FAIL
  }
}