import React from 'react';
import './styles.scss';
import LineTownFooter from '../../static/svg/LineTownFooter';

const CopyRightGreen = () => {
  return (
    <div className="copy-right-green">
      <div className="div-image">
      {/* <LineTownFooter /> */}
      </div>
      <div className="copy-right-text">
        <span>Copyright © POCKET CULTURE CO.,Ltd. All Rights Reserved.</span>
      </div>
    </div>
  )
}
export default CopyRightGreen;