export const actionTypes = {
  GET_LIST_SPOTS: 'GET_LIST_SPOTS',
  GET_LIST_SPOTS_SUCCESS: 'GET_LIST_SPOTS_SUCCESS',
  GET_LIST_SPOTS_FAIL: 'GET_LIST_SPOTS_FAIL',

  GET_SPOT_DETAIL: 'GET_SPOT_DETAIL',
  GET_SPOT_DETAIL_SUCCESS: 'GET_SPOT_DETAIL_SUCCESS',
  GET_SPOT_DETAIL_FAIL: 'GET_SPOT_DETAIL_FAIL',

  CHECKIN_SPOT: 'CHECKIN_SPOT',
  CHECKIN_SPOT_SUCCESS: 'CHECKIN_SPOT_SUCCESS',
  CHECKIN_SPOT_FAIL: 'CHECKIN_SPOT_FAIL',
  RESET_ACTION_CHECKIN_SPOT_FAIL: 'RESET_ACTION_CHECKIN_SPOT_FAIL',


  SET_CHECKIN_SUCCESS:'SET_CHECKIN_SUCCESS',
  CLEAR_CHECKIN: 'CLEAR_CHECKIN'
}


export const setCheckinSuccess = (params) => {
  return {
    type: actionTypes.SET_CHECKIN_SUCCESS,
    payload: params
  }
}
export const getListSpots = (params) => {
  return {
    type: actionTypes.GET_LIST_SPOTS,
    payload: params
  }
}
export const getListSpotsSuccess = (data) => {
  return {
    type: actionTypes.GET_LIST_SPOTS_SUCCESS,
    payload: data
  }
}
export const getListSpotsFail = () => {
  return {
    type: actionTypes.GET_LIST_SPOTS_FAIL
  }
}

export const getSpotDetail = (params) => {
  return {
    type: actionTypes.GET_SPOT_DETAIL,
    payload: params
  }
}
export const getSpotDetailSuccess = (data) => {
  return {
    type: actionTypes.GET_SPOT_DETAIL_SUCCESS,
    payload: data
  }
}
export const getSpotDetailFail = () => {
  return {
    type: actionTypes.GET_SPOT_DETAIL_FAIL
  }
}
//checkin
export const checkinSpot = (params) => {
  return {
    type: actionTypes.CHECKIN_SPOT,
    payload: params
  }
}
export const checkinSpotSuccess = (data) => {
  return {
    type: actionTypes.CHECKIN_SPOT_SUCCESS,
    payload: data
  }
}
export const checkinSpotFail = () => {
  return {
    type: actionTypes.CHECKIN_SPOT_FAIL
  }
}
export const resetCheckinSpotFail = () => {
  return {
    type: actionTypes.RESET_ACTION_CHECKIN_SPOT_FAIL
  }
}
export const clearCheckin = () => {
  return {
    type: actionTypes.CLEAR_CHECKIN
  }
}