import { takeLatest, put } from '@redux-saga/core/effects';
import { serverApi } from '../../services/serverApi';
import { actionTypes, getNotificationsSuccess, getNotificationsFail,getNotificationDetailSuccess,getNotificationDetailFail } from './action';
import {
  listNotification,
  getDetailNotification,
  
} from 'common/src/api/notification';



function* getNotificationsSaga(action) {
  try {
    const {size, page} = action.payload;
    const res = yield serverApi(listNotification(size, page));
    if (res.status === 200) {
      const data = res.data.data;
      yield put(getNotificationsSuccess(data));
    } else {
      yield put(getNotificationsFail());
    }
  } catch (error) {
    yield put(getNotificationsFail());
  }
}
function* getNotificationDetailSaga(action) {
  try {
    const {notificationId} = action.payload;
    console.log('notificationId',notificationId)
    const res = yield serverApi(getDetailNotification(notificationId));
    if (res?.status === 200) {
      const {data} = res?.data;
      yield put(getNotificationDetailSuccess(data));
    } else {
      yield put(getNotificationDetailFail());
    }
  } catch (error) {
    yield put(getNotificationDetailFail());
  }
}

export function* watchGetNotifications() {
  yield takeLatest(actionTypes.GET_NOTIFICATIONS, getNotificationsSaga);
}
export function* watchGetNotificationDetail() {
  yield takeLatest(actionTypes.GET_NOTIFICATION_DETAIL,getNotificationDetailSaga);
}
