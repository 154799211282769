import React from 'react';
import { useSelector } from 'react-redux';
import Loading from '../../components/loading/Loading';
import './styles.scss';

const RedeemCardBottomInsufficient = () => {
  const { isCheckUser, userInfo } = useSelector((state) => state.user)
  const dataItem = useSelector(state => state.items.items);

  const displayAmountOfTicketsConsumed = (ticketId, amountOfTicketsConsumed, wallets) => {
    let dataDisplay = '<span class="text-red">' + amountOfTicketsConsumed + '</span>';
    if (amountOfTicketsConsumed === 0) {
      dataDisplay = '<span class="text-green-blue">' + amountOfTicketsConsumed + '</span>';
    }
    if (Array.isArray(wallets) && wallets.length > 0) {
      for (let i = 0; i < wallets.length; i++) {
        const walletData = wallets.filter(function (wallet) {
          return ticketId === wallet.ticketId;
        });
        if (walletData.length > 0) {
          if (walletData[0].totalAcquisitionTicket >= amountOfTicketsConsumed) {
            dataDisplay = '<span class="text-green-blue">0</span>';
          } else {
            dataDisplay = '<span class="text-red">' + (amountOfTicketsConsumed - walletData[0].totalAcquisitionTicket) + '</span>';
          }
        }
      }
    }
    return dataDisplay;
  }


  return (
    <>
      {
        (!isCheckUser || dataItem.length <= 0) ?
        <Loading /> : 
        <div className="redeem-card-bottom">
          <div className="top">
            <img src="icon/icon_reward_left.svg" className="img-left" alt="icon_reward_left"/>
            <span className="title-content">チケットの不足</span>
            <img src="icon/icon_reward_right.svg" className="img-right" alt="icon_reward_right"/>
          </div>
          <div className="description">チケットの所持数がたりません。</div>
          {/* display box items */}
          <div className="box-item">
            <div className="title-ticket">チケット必要数</div>
            {dataItem[0].tickets && dataItem[0].tickets.map((ticket) => (
              <>
              <div className="item-ticket-success">
                <div className="ticket-img">
                <img src={ (ticket.thumbnails && ticket.thumbnails[0]) ? ticket.thumbnails[0] : ''} alt="" />
                </div>
                <div className="ticket-text"><span>{ticket.title}:</span></div>
                <div className="ticket-point">
                  <span>{ticket.amountOfTicketsConsumed}</span>
                </div>
              </div>
              <div className="ticket-deducted">
                <div className="ticket-text"><span className="text">チケット不足数：</span><span className="point" dangerouslySetInnerHTML={{__html: displayAmountOfTicketsConsumed(ticket.id, ticket.amountOfTicketsConsumed, userInfo.activities.wallets)}}></span>
                </div>
              </div>
              </>
            ))}
          </div>
        </div>
      }
    </>
  )
}
export default RedeemCardBottomInsufficient;