import React from 'react'
import styled from 'styled-components'

const createBrowserHistory = require('history').createBrowserHistory

const StyleMenuHeader = styled.div`
  background-color: #10cad6;
  height: 44px;
  text-align: center;
  position: sticky;
  z-index: 3;
  top: 0;

  h2 {
    color: white;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    font-weight: 600;
  }

  .load {
    position: absolute;
    top: 10px;
  }
  .backArrow{
    width:13px;
    position:absolute;
    left:16px;
  }
`

const ComponentGoBack = () => {
  const history = createBrowserHistory()
  return (
    <div
      className="load" style={{'zIndex': 500}} onClick={() => {
      history.goBack()
    }}
    >
      <img src = "/icon/goBackArrow.svg" className="backArrow" />
    </div>
  )
}

const HeaderGoBack = (props) => {
  let showGoBack = true
  if (props.showGoBack !== undefined) {
    showGoBack = props.showGoBack
  }

  return (
    <StyleMenuHeader>
      {
        showGoBack && <ComponentGoBack />
      }

      <h2>{props.text || 'マイメニュー'}</h2>
    </StyleMenuHeader>
  )
}

export default HeaderGoBack;
