import React, {useState} from 'react';
import MainButton from '../buttons/MainButton';

const SendmailSuccess = (props) => {
  const handleClick = props.handleClick
  return (
    <>
      <div className="send-mail-success">
        <div className="title">
          <div>{props.title}</div>
        </div>
        <div className="gohome-button">
          <MainButton name ={props.name} handleClick={handleClick} />
        </div>
      </div>
    </>
  )
}
export default SendmailSuccess;