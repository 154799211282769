import React from 'react';

const Step = () => {
  return (
    <>
      <div className="top margin-bottom-36">
        <img src="icon/icon_reward_left.svg" className="img-left" alt="icon_reward_left"/>
        <span className="title-content">引き換え方法</span>
        <img src="icon/icon_reward_right.svg" className="img-right" alt="icon_reward_right"/>
      </div>
      <div className="box-step">
        <div className="step-title">STEP:1</div>
        <div className="step-description">カード取得に必要なチケットを獲得の上、引き換え場所でQRコードを読み込みます。</div>
        <div className="step-image">
          <img src="images/step1.png" alt="" />
        </div>
      </div>
      <div className="box-step">
        <div className="step-title">STEP:1</div>
        <div className="step-description">カード取得に必要なチケットを獲得の上、引き換え場所でQRコードを読み込みます。</div>
        <div className="step-image">
          <img src="images/step2.png" alt="" />
        </div>
      </div>
      <div className="box-step">
        <div className="step-title">STEP:1</div>
        <div className="step-description">カード取得に必要なチケットを獲得の上、引き換え場所でQRコードを読み込みます。</div>
        <div className="step-image">
          <img src="images/step3.png" alt="" />
        </div>
      </div>
    </>
  )
}
export default Step;
