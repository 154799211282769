import { GET_LIST_HISTORY, GET_LIST_HISTORY_SUCCESS, GET_LIST_HISTORY_FAIL, UPDATE_LIST_HISTORY, NEED_RELOAD_HISTORY, RELOAD_HISTORY_SUCCESS, RESET_PAGE_NUMBER } from './types'



export const getBingoHistory = (params) => {
  return {
    type: GET_LIST_HISTORY,
    payload: params
  }
}
export const getBingoHistorySuccess   = (data) => {
  return {
    type: GET_LIST_HISTORY_SUCCESS,
    payload: data,
  }
}

export const getBingoHistoryFail   = () => {
  return {
    type: GET_LIST_HISTORY_FAIL
  }
}


export const updateHistoryList = (data) => {
  return {
    type: UPDATE_LIST_HISTORY,
    payload: data,
  }
}

export const resetPageNumber = () => {
  return {
    type: RESET_PAGE_NUMBER,
  }
}

export const setNeedReloadHistory = (data) => {
  return {
    type: NEED_RELOAD_HISTORY,
    payload: data,
  }
}

export const setReloadHistory = (data) => {
  return {
    type: RELOAD_HISTORY_SUCCESS,
    payload: data,
  }
}
