import React from 'react';
import { ILLUST_ERROR_IMG_PATH, ICON_BUTTON_CLOSE_IMG_PATH } from '../../static/svg';
import MainButton from '../buttons/MainButton';


const ErrorModal = (props) => {
  let onClickBtn = props.onClose;
  const label1 = props.label1;
  const label2 = props.label2;
  const mainBtnLabel = props.mainBtnLabel;
  let maintBtnOnClick = props.maintBtnOnClick;
  return (
    <>
      <div className="error-modal" style={{ display: props.display ?? 'inherit' }}>
        <div className="content">
          <div className="card">
            <div className="img-error">
              <img src={ILLUST_ERROR_IMG_PATH} />
            </div>
            <div className="text-error">
              <p>{label1}</p>
              <p>{label2}</p>
            </div>
            <div className="error-button">
            <MainButton name={mainBtnLabel} handleClick={maintBtnOnClick} />
          </div>
          </div>
        </div>
        <div className="button-close"><img src={ICON_BUTTON_CLOSE_IMG_PATH} onClick={onClickBtn} /></div>
      </div>
    </>
  )
}
export default ErrorModal;