import React from 'react';
import './styles.scss';
import HexagonLineItem from './HexagonLineItem';
import { goldColor } from '../../../../helper/helper';
import { formatDateBingoLog } from '../../../../helper/helper';
import moment from 'moment';

const BingoLogLineItem = (props) => {
  const date = moment((props.data.date)).format(formatDateBingoLog)
  const panels = [];
  for (let i = 0; i < 19; i++) {
    if (props.data.spotNumbers && props.data.spotNumbers.includes(i + 1)) {
      panels.push({
        key: i,
        design: 'yellow'
      });
    } else {
      panels.push({
        key: i,
        design: ''
      });
    }
  }

  return (
    <div className="bingo-log-line-item" >
      <div className="box-hexagon-icon">
        <div className="row">

        {panels && panels.slice(0, 3).map((panel) => (
            <HexagonLineItem data={panel.design} key={panel.key} />
          ))}
        </div>
        <div className="row">
        {panels && panels.slice(3, 7).map((panel) => (
            <HexagonLineItem data={panel.design} key={panel.key}/>
          ))}
        </div>
        <div className="row">
        {panels && panels.slice(7, 12).map((panel) => (
            <HexagonLineItem data={panel.design} key={panel.key}/>
          ))}
        </div>
        <div className="row">
        {panels && panels.slice(12, 16).map((panel) => (
            <HexagonLineItem data={panel.design} key={panel.key}/>
          ))}
        </div>
        <div className="row">
        {panels && panels.slice(16, 19).map((panel) => (
            <HexagonLineItem data={panel.design} key={panel.key}/>
          ))}
        </div>
      </div>

      <div className="item-text">
        <div className="title">
          <p>{props.data.bingoName}で</p>
          <p><span style={{color:goldColor}}>ビンゴを{props.data.clearLines}ライン</span>達成しました</p>
          </div>
        <div>
          {props.data.acquiredTickets && props.data.acquiredTickets.map((ticket) =>
            <div className="ticket"><img className="image-ticket" src={ticket.ticketUri} /><span className="ticket-text">×{ticket.acquired}枚 獲得</span></div>
          )}
          <div className="date">{date}</div>
        </div>
      </div>
    </div>
  )
}

export default BingoLogLineItem;
