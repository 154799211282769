export const actionTypes = {
  SET_SPOT_BUTTON_CHECKIN: 'SET_SPOT_BUTTON_CHECKIN',
  SET_BOTTOM_ACTIVE: 'SET_BOTTOM_ACTIVE'
}


export const setBottomActive = (params)=>{
  return{
    type: actionTypes.SET_BOTTOM_ACTIVE,
    payload:params
  }
}

export const setSpotButtonCheckin = (params) => {
  return {
    type: actionTypes.SET_SPOT_BUTTON_CHECKIN,
    payload: params,
  }
}



