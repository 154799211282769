import React from 'react';
import StyleContentInfoTabingo from './contentInfoTabingo.style'

const ContentInfoTabingo = () => {
  return (
    <StyleContentInfoTabingo>
      <div className="bgrInfo">
      <div className="loginTitle">
      </div>
      <div className="divImgBgr">
        <img className="imgBgr" src="/images/loginBgr.png"  />
      </div>
      <div className="imgTitle">
        <img className="title" src="icon/catchcopy.svg" alt="catchcopy"  />
      </div>
      </div>

      <div>
        <div className="tabingo">
          <img src="icon/rainbow_kirakira_L.svg" className="imgLeft" alt="rainbow_kirakira_L"/>
          <h2>TABINGOって??</h2>
          <img src="icon/rainbow_kirakira_R.svg" className="imgRight" alt="rainbow_kirakira_R"/>
        </div>
        <div className="content">
          <div>いつもの街がBINGOのマスに様変わり！</div>
          <div>ねらったマスにチェックインして、BINGOをそろえよう。</div>
          <div>見慣れた散歩道で、旅先の街角で。</div>
          <div className="end-content-info">ちょっとだけ遠回りしたくなる、BINGOゲームのはじまりです。</div>
        </div>
      </div>
    </StyleContentInfoTabingo>
  )
}

export default ContentInfoTabingo
