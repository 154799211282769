import React from 'react'
import styled from 'styled-components'
import HeaderGoBack from '../../components/HeaderGoBack'
import FooterBuilding from '../../components/footerBuilding';

const Style = styled.div`
  .txt {
    color: #323232;
    font-weight: 200;
  }

  .head {
    font-weight: bold;
    font-size:16px;
  }

  .mgt20 {
    margin-top: 20px;
  }

  .mgb20 {
    margin-bottom: 20px;
  }

  .mgb10 {
    margin-bottom: 10px;
  }

  .mgt40 {
    margin-top: 40px;
  }

  .right {
    float: right
  }
  p{
    font-size:12px;
  }
`

const TermOfUse = () => {
  window.scrollTo(0, 0);
  return (
    <Style>
      <div className="menu">
        <HeaderGoBack text="利用規約"/>
        <div style={{padding: '10px', marginBottom: '5px'}}>
          <div className="head"><b style={{color: '#10cad6'}}>TABINGO</b> <b>利用規約</b></div>
          <div className="mgt20 mgb20"><p
            className="txt"
          >サービス利用規約（以下「本利用規約」といいます。）は、株式会社ポケットカルチャー（以下「当社」といいます。）が提供するサービスを利用する者(以下、「ユーザ」といいます。)に適用されます。ユーザは以下の本規約に必ず同意頂き、ご利用ください。ユーザが利用する際の一切の行為に適用されます。</p>
          </div>
          <div className="mgt20 mgb20 head">第1条 本利用規約について</div>
          <div>
            <p className="txt mgb10">1.1.
                                     本利用規約は、サービスの利用条件を定めるものです。ユーザは、本利用規約に従いサービスを利用するものとします。また本利用規約の全ての記載内容について同意したものとみなされます。 </p>
            <p className="txt mgb10">1.2. 当社は、当社の判断により、本利用規約をいつでも任意の理由で変更することができるものとします。</p>
            <p className="txt mgb10">1.3. 変更後の本利用規約は、当社が別途定める場合を除いて、サービスサイト上に表示した時点より効力を生じるものとします。</p>
            <p className="txt mgb10">1.4. ユーザが、本利用規約の変更の効力が生じた後に本サービスをご利用になる場合には、変更後の利用規約の全ての記載内容に同意したものとみなされます。</p>
          </div>
          <div className="mgt20 mgb20 head">第2条 利用登録手続き</div>
          <div>
            <p className="txt mgb10">2.1.
                                     本サービスにおいては、会員登録希望者が本規約に同意の上、当社の定める方法によって会員登録を申請し、当社がこれを承諾した後、専用ページにて会員登録のためのフォーマットに必要な情報をデータ入力して当社に送信することにより、会員登録が完了するものとします。ただし、当社は、会員登録希望者が本規約に同意の上、Twitterなどの外部サービスの認証システムを経由する方法によって会員登録を申請した場合、当社がこれを承諾する方法によっても会員登録を完了することができるものとします。</p>
            <p className="txt mgb10">2.2. 会員登録に必要な情報の内容は以下のとおりです。</p>
            <p className="txt mgb10">①ユーザの氏名</p>
            <p className="txt mgb10">②ユーザの生年月日</p>
            <p className="txt mgb10">③ユーザの住所</p>
            <p className="txt mgb10">④ユーザのメールアドレス</p>
            <p className="txt mgb10">⑤その他本サービス利用のために必要な情報</p>
            <p className="txt mgb10">2.3.
                                     当社は、会員登録の申請者に以下の事由があると判断した場合、会員登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。</p>
            <p className="txt mgb10">①会員登録の申請に際して虚偽の事項を届け出た場合</p>
            <p className="txt mgb10">②本規約に違反したことがある者からの申請である場合</p>
            <p className="txt mgb10">③その他、当社が会員登録を相当でないと判断した場合</p>
          </div>
          <div className="mgt20 mgb20 head">第3条 利用条件等</div>
          <div>
            <p className="txt mgb10">3.1. ユーザは、自己の責任において本サービスを利用するものとします。なお、本サービスが全てのブラウザに対応することを保証するものではありません。</p>
            <p className="txt mgb10">3.2. ユーザは、ゲーム内アイテム等につき、本サービスで定められた範囲の利用権を有するのみであって、所有権、知的財産権等の権利を取得するものではありません。</p>
            <p className="txt mgb10">3.3.
                                     本サービスの著作権・その他の権利は、当社に帰属します。本規約は、明示的に定めがある場合を除き、ユーザに本サービスの著作権その他いかなる権利も移転することを許諾するものではありません。
                                     ユーザは、次の行為を行ってはならないものとします。</p>
            <p className="txt mgb10">3.4. ユーザは、次の行為を行ってはならないものとします。</p>
            <p className="txt mgb10">① 本サービスに影響を与える外部ツールの利用・作成・頒布・販売等を行う行為。</p>
            <p className="txt mgb10">② 本サービスのパラメーターデータを操作または変更しようとする行為。</p>
            <p className="txt mgb10">③ 当社が本来意図しない動作を利用して本サービス内での利益を得ようとする行為（いわゆるチート行為）</p>
            <p className="txt mgb10">④ その他、本サービスに関して本サービス提供者が有する権利を侵害する行為。</p>
            <p className="txt mgb10">⑤ 著作権表示及びその他の権利表示を除去または変更する行為。</p>
            <p className="txt mgb10">⑥ その他、当社が不適切と判断する行為。</p>
            <p className="txt mgb10">3.5. ユーザが本規約のいずれかの条項に違反したときは、ユーザに対して何時にても本規約に基づく本サービスの利用を終了させることができます。その場合、当社はユーザに対して何らの責任を負うものではありません。</p>
          </div>
          <div className="mgt20 mgb20 head">第4条（サービスの変更）</div>
          <div>
            <p className="txt mgb10">4.1. 当社は、当社の都合により、本サービスおよび本サービスのルール等いつでも追加、変更、中断、終了することができるものとし、ユーザに対して一切責任を負わないものとします。</p>
            <p className="txt mgb10">4.2. 当社は、本サービスの機能やツールに、有料化や年齢制限など必要に応じて制限を設けることができるものとします。
            </p>
          </div>
          <div className="mgt20 mgb20 head">第5条 免責</div>
          <div>
            <p className="txt mgb10">5.1. 当社は、本サービスの利用により発生したユーザの損害については、一切の賠償責任を負いません。ユーザが、本サービスを利用することにより、第三者に対し損害を与えた場合、 ユーザは自己の費用と責任においてこれを賠償するものとします。</p>
            <p className="txt mgb10">5.2. 当社は本サービスに発生した不具合、エラー、障害等により本サービスが利用できないことによって引き起こされた損害について一切の賠償責任を負いません。</p>
            <p className="txt mgb10">5.3. 本サービスは、当社がその時点で提供可能なものとします。当社は提供する情報、コンテンツおよびソフトウェア等の情報についてその完全性、正確性、適用性、有用性、利用可能性、 安全性、確実性等につきいかなる保証も一切しません。</p>
            <p className="txt mgb10">5.4. 当社は、本サービスのバグその他を補修する義務および本サービスを改良または改善する義務は負いません。ただし、ユーザにバージョンアップ情報等を提供する場合があります。この場合、バージョンアップ情報等も本規約が当然に適用されます。</p>
            <p className="txt mgb10">5.5. 本サービスが何らかの外的要因によりデータ破損等をした場合、当社はその責任を負いません。</p>
          </div>
          <div className="mgt20 mgb20 head">第6条 法律の適用及び裁判管轄</div>
          <div>
            <p className="txt mgb10">6.1. 本規約は、日本法に準拠し、日本法によって解釈されるものとします。</p>
            <p className="txt mgb10">6.2. 本規約に関する紛争については、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。</p>
          </div>
          <div style={{marginRight: '10px'}}>
            <p className="txt mgb10 right">以上</p>
          </div>
        </div>
        <FooterBuilding />
      </div>
    </Style>
  )
}

export default TermOfUse
