import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import GoBackHeader from '../../components/headers/GoBackHeader';
import NotificationItem from '../home/tabs/notification/NotificationItem';
import NotificationItemNoImage from '../home/tabs/notification/NotificationItemNoImage';
import LoadmoreButton from '../../components/buttons/LoadmoreButton';
import CopyRight from '../../components/copyRights/CopyRight';
import './styles.scss'
import { HEADER_TABBAR_COPYRIGHT_HEIGHT } from '../../helper/helper'
import { getNotifications, updatePageNotification } from '../../redux/notification/action';

const NotificationScreen = () => {
  const dispatch = useDispatch();
  const { notifications, isGettingNotifications, showBtnLoadMore, size, pageNumber } = useSelector((state) => state.notification);
  useEffect(() => {
    jumpToTop()
  }, [])

  useEffect(() => {
    if (notifications.length <= 0) {
      getListNotification()
    }
  }, [])

  const getListNotification = async() => {
    await dispatch(getNotifications({size, pageNumber}))
  }

  const jumpToTop = () => {
    window.scrollTo({
      behavior: 'smooth',
      top: 0,
      left: 0,
    })
  }

  const handleLoadMore = async () => {
    const page = pageNumber + 1
    await dispatch(updatePageNotification(page))
    await dispatch(getNotifications({
      size:size, page: page 
    }))
  }
  return (
    <>
      <GoBackHeader name="お知らせ一覧" />
      <div className="notification-screen" >
        <div className="notification-list" style={{ minHeight: window.innerHeight - HEADER_TABBAR_COPYRIGHT_HEIGHT + "px" }}>
          {notifications && notifications.map((item, idx) =>
            <>
              {item.thumbnail && <NotificationItem data={item} key={idx} />}
              {!item.thumbnail && <NotificationItemNoImage data={item} key={idx} />}
            </>)}
          <div className="box-button-next">
            {showBtnLoadMore && <LoadmoreButton name="もっと見る" isLoading={isGettingNotifications} onClick={() => { handleLoadMore(pageNumber) }} />}
          </div>
        </div>
        <CopyRight />
      </div>
    </>
  )
}
export default NotificationScreen;