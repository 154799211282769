import { actionTypes } from './action'

const initState = {
  spots: [],
  isGettingSpots: false,
  spot: {},
  isGettingSpot: false,
  checkinSuccess: false,
  actionCheckinFail: false,
  checkinData: {},
}

const spotReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.GET_LIST_SPOTS:
      return {
        ...state,
        isGettingSpots: true,
      };
    case actionTypes.GET_LIST_SPOTS_SUCCESS:
      return {
        ...state,
        spots: payload,
        isGettingSpots: false,
      };
    case actionTypes.GET_LIST_SPOTS_FAIL:
      return {
        ...state,
        isGettingSpots: false,
      };

    case actionTypes.GET_SPOT_DETAIL:
      return {
        ...state,
        isGettingSpot: true,
      };
    case actionTypes.GET_SPOT_DETAIL_SUCCESS:
      return {
        ...state,
        spot: payload,
        isGettingSpot: false,
      };
    case actionTypes.GET_SPOT_DETAIL_FAIL:
      return {
        ...state,
        isGettingSpot: false,
      };

    case actionTypes.CHECKIN_SPOT:
      return {
        ...state,
        isCheckinSpot: true,
        checkinSuccess: false
      };
    case actionTypes.CHECKIN_SPOT_SUCCESS:
      return {
        ...state,
        checkinData: payload,
        checkinSuccess: true,
        isCheckinSpot: false,
        actionCheckinFail: false
      };
    case actionTypes.CHECKIN_SPOT_FAIL:
      return {
        ...state,
        isCheckinSpot: false,
        checkinSuccess: false,
        actionCheckinFail: true
      };
    case actionTypes.RESET_ACTION_CHECKIN_SPOT_FAIL:
      return {
        ...state,
        actionCheckinFail: false
      };
    case actionTypes.SET_CHECKIN_SUCCESS:
      return {
        ...state,
        checkinSuccess: payload
      }
    case actionTypes.CLEAR_CHECKIN:
      return {
        ...state,
        checkinData: {}
      };
    case 'LOG_OUT':
      return initState;
    default:
      return state
  }
}

export default spotReducer
