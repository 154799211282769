import styled from 'styled-components'

const StyleConfirmModal = styled.div`
  .icon {
    font-size: 200px;
    color: #dee9f4;
    margin-bottom: 15px;
  }

  p {
    font-size: 20px;
    color: #273956;
    font-weight: bold;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    line-height:17px;
  }
  .s18{
    font-size:18px;
  }
  .s15{
    font-size:15px;
  }
  .confirmBtn{
    margin: 35px 0 6px 0;
    border-radius: 40px;
      background-color: white;
     
      border: solid 2px #10cad6;
      font-size: 19px;
      font-weight: bold;
      width: 80%;
      text-align: center;
      margin-left:auto;
      margin-right:auto;
      //height: fit-content;
      padding-top: 5px;
      padding-bottom: 5px;
      a{
        color: #10cad6;
      }
  }
  

  .boxImage {
    margin: 26px 0 33px 0;
    position: relative;

    .icon {
      font-size: 200px;
      color: #dee9f4;
      margin-bottom: 15px;
    }
  }

  .confirmIcon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .icon-icon_genre_washoku {
      font-size: 80px;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .confirm-modal {
    .confirmIcon {
      .icon-icon_genre_washoku {
        font-size: 260px;
      }
    }
  }
}

@media only screen and (min-width: 540px) {
  .confirm-modal {
    .confirmIcon {
      .icon-icon_genre_washoku {
        font-size: 80px;
      }
    }
  }
`

export default StyleConfirmModal
