/**
 *
 * @param {number| string} userId
 * @param {number| string} shopId
 * @param {number| string} bingoId
 * @returns {{method: string, data: {shop_id: (number|string), type: string}, url: string}}
 */
export function apiShareTwitter(userId, shopId, bingoId) {
  return {
    url: `/players/${userId}/sns/share`,
    method: "post",
    data: {
      shop_id: shopId,
      bingo_id: bingoId,
      type: "twitter"
    }
  };
}
