import React from "react";
import IconHeaderLogo from "../../static/svg/IconHeaderLogo";
import './styles.scss';

const HomeHeader = (props) => {
  const zIndex = props.zIndex?props.zIndex:null;
  return (
    <>
      <div className="home-header" style={{zIndex:zIndex}}>
        <h1>
          <IconHeaderLogo />
        </h1>
      </div>
    </>
  );
};

export default HomeHeader;
