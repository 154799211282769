import PLACE_PANNEL_YOKOHAMA_CLEAR from './placepanel_yokohama_clear.svg';
import PLACE_PANNEL_YOKOHAMA from './placepanel_yokohama.svg';
import ITEM_TICKET_YOKOHAMA from './item_ticket_yokohama.svg';
import ITEM_TICKET_YOKOHAMA_2 from './item_ticket_yokohama_2.svg';
import ICON_VECTOR_RIGHT from './icon_vector_right.svg';
import ICON_REWARD from './icon_reward.svg';
import ICON_HEXAGON_BASE from './bingo_hexagon_base.svg';
import ICON_HEXAGON_BLUE from './bingo_hexagon_blue.svg';
import ICON_HEXAGON_YELLOW from './bingo_hexagon_yellow.svg';
import TICKET_CLEAR_IMAGE from './ticket_clear_image.svg';
import BINGO_LINE from './bingo_line.svg';
import STAMP_REWARD_GET from './stamp_reward_get.svg';
import JUMP_TO_TOP from './jump_to_top.svg';
import ICON_BUTTON_CLOSE from './icon_button_close.svg';
import ILLUST_PANEL_GREEN from './illust_panel_green.svg';
import ILLUST_PANEL_RED from './illust_panel_red.svg';
import ILLUST_PANEL_BLUE from './illust_panel_blue.svg';
import ILLUST_PANEL_ORANGE from './illust_panel_orange.svg';
import ILLUST_PANEL_TICKET_YOKOHAMA from './illust_panel_ticket_yokohama.svg';
import BG_ILLUST_PANEL_TICKET from './bg_illust_panel_ticket.svg';
import ILLUST_PANEL_BINGO_CLEAR_YOKOHAMA from './illust_panel_bingoclear.svg';
import BUTTON_CURRENT_LOCATION from './button_current_location.svg';
import ILLUST_ERROR from './illust_error.svg';
import ILLUST_404_ERROR from './illust_404error.svg';
import STAMP_BINGO_CLEAR from './stamp_bingoclear.svg';
import IC_HEXAGON_YELLOW from './ic_hexagon_yellow.svg';
import IC_HEXAGON_GREY from './ic_hexagon_grey.svg';
import IC_NEW_BADGE_B from './icon_new_badgeB.svg';

export const PLACE_PANNEL_YOKOHAMA_IMG_PATH = PLACE_PANNEL_YOKOHAMA.toString();
export const PLACE_PANNEL_YOKOHAMA_CLEAR_IMG_PATH = PLACE_PANNEL_YOKOHAMA_CLEAR.toString();
export const ITEM_TICKET_YOKOHAMA_IMG_PATH = ITEM_TICKET_YOKOHAMA.toString();
export const ITEM_TICKET_YOKOHAMA_2_IMG_PATH = ITEM_TICKET_YOKOHAMA_2.toString();
export const ICON_VECTOR_RIGHT_IMG_PATH = ICON_VECTOR_RIGHT.toString();
export const ICON_REWARD_IMG_PATH = ICON_REWARD.toString();
export const ICON_HEXAGON_BASE_IMG_PATH = ICON_HEXAGON_BASE.toString();
export const ICON_HEXAGON_BLUE_IMG_PATH = ICON_HEXAGON_BLUE.toString();
export const ICON_HEXAGON_YELLOW_IMG_PATH = ICON_HEXAGON_YELLOW.toString();
export const TICKET_CLEAR_IMAGE_IMG_PATH = TICKET_CLEAR_IMAGE.toString();
export const BINGO_LINE_IMG_PATH = BINGO_LINE.toString();
export const STAMP_REWARD_GET_IMG_PATH = STAMP_REWARD_GET.toString();
export const JUMP_TO_TOP_IMG_PATH = JUMP_TO_TOP.toString();
export const ICON_BUTTON_CLOSE_IMG_PATH = ICON_BUTTON_CLOSE.toString();
export const ILLUST_PANEL_GREEN_IMG_PATH = ILLUST_PANEL_GREEN.toString();
export const ILLUST_PANEL_RED_IMG_PATH = ILLUST_PANEL_RED.toString();
export const ILLUST_PANEL_BLUE_IMG_PATH = ILLUST_PANEL_BLUE.toString();
export const ILLUST_PANEL_ORANGE_IMG_PATH = ILLUST_PANEL_ORANGE.toString();
export const ILLUST_PANEL_TICKET_YOKOHAMA_IMG_PATH = ILLUST_PANEL_TICKET_YOKOHAMA.toString();
export const BG_ILLUST_PANEL_TICKET_IMG_PATH = BG_ILLUST_PANEL_TICKET.toString();
export const ILLUST_PANEL_BINGO_CLEAR_YOKOHAMA_IMG_PATH = ILLUST_PANEL_BINGO_CLEAR_YOKOHAMA.toString();
export const BUTTON_CURRENT_LOCATION_IMG_PATH = BUTTON_CURRENT_LOCATION.toString();
export const ILLUST_ERROR_IMG_PATH = ILLUST_ERROR.toString();
export const ILLUST_404_ERROR_IMG_PATH = ILLUST_404_ERROR.toString();
export const STAMP_BINGO_CLEAR_IMG_PATH = STAMP_BINGO_CLEAR.toString();
export const IC_HEXAGON_YELLOW_IMG_PATH = IC_HEXAGON_YELLOW.toString();
export const IC_HEXAGON_GREY_IMG_PATH = IC_HEXAGON_GREY.toString();
export const IC_NEW_BADGE_B_IMG_PATH = IC_NEW_BADGE_B.toString();