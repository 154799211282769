import dotenv from "dotenv";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import styled from "styled-components";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { store } from "./redux/store";
import ErrorLoadModal from "./components/modals/ErrorLoadModal";
dotenv.config({ path: `.env.${process.env.NODE_ENV}` });

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

const StyleApp = styled.div`
  @media only screen and (min-width: 769px) {
    
    width: 375px !important;
    margin: 0 auto;
    .home-header, .question-header, .go-back-header, .main-header, .bingo-tab-bar, .bingo-map-container, .menu-header{
      width:375px !important;
    }
    .footer-menu {
      width: 375px !important;
      margin: 0 auto;
    }
  }
`;

ReactDOM.render(
  <Provider store={store}>
      <StyleApp id="app_body">
        <App />
        <ErrorLoadModal />
      </StyleApp>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
