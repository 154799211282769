import React from 'react'
import Idicator from './idicator';
import StepBody from './stepBody';
import StepTitle from './stepTitle';

const CommonStep = (props) => {
    const innerHeight = window.innerHeight;
    return (
        <>
        <div className="kumahaku-screen">
            <div className="kumahaku-top">
            <StepTitle title={ props.title } title02={props.title02} />
            <StepBody step={ props.step } stepNumber={ props.stepNumber } innerHeight={ innerHeight } />
            <Idicator stepNumber={props.stepNumber} previous={ props.previous } next={ props.next } />
            </div>
        </div>
        </>
    )
}

export default CommonStep