import React from 'react';
import { STAMP_REWARD_GET_IMG_PATH } from '../../../../static/svg';
import { lightGoldColor, goldColor } from '../../../../helper/helper'
import './styles.scss';
const BingoTicketItem = (props) => {

  const isFirst = props.isFirst ? props.isFirst : false;
  const line = props.line;
  const isClear = line?.completeAt;
  const imgbingo = props.imgbingo;

  return (
    <>
      {!isFirst && (
      <div className="bingo-ticket-item" style={{ backgroundColor: isClear ? lightGoldColor : null }}>
        <div className="div-image-ticket">
          <img className="img-ticket" src={isFirst ? imgbingo : (isClear ? STAMP_REWARD_GET_IMG_PATH : line?.ticket?.thumbnails[1])} alt="" />
        </div>
        <div className="ticket-text"><span style={isClear ? { color: goldColor } : null}>{ `${line?.ticket?.title}×${ line?.rewards }枚` }</span></div>
        <div className="ticket-end-image">
          <img src={line?.iconUrl} />
        </div>
      </div>)}
    </>
  )
}
export default BingoTicketItem;