import {GET_ITEMS_SINGLE} from './types'


export const getItemsSingle = (item) => {
  return {
    type: GET_ITEMS_SINGLE,
    payload: item
  }
}

