import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
const DisableMainButton = (props) => {
  const isLoading = props.isLoading;
  return (
    <>
      <div className="main-button disable" onClick={props.handleClick}>
        {isLoading && <i className="fa fa-refresh fa-spin" />}
        {!isLoading && <div className="title-name">{props.name}</div>}
      </div>
    </>
  )
}
export default DisableMainButton;