import React, {useEffect} from 'react'
import PopupModal from '../../../components/modals/PopupModal'
import StyleInfoModel from './InfoModal.style'

const InfoModal = (props) => {

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     props.onNext()
  //   }, 1500)
  //   return () => clearTimeout(timer)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  return (
    <StyleInfoModel>
      <PopupModal onClickBtn = {()=>{props.onNext()}}>
        <img src="/icon/illust_point.svg" className="icon" alt="illust_point"/>
        <p>
          <span>
            <span className="number">{props.point}</span>
            マイルポイント
            </span>
          を<br/>獲得しました！
        </p>
      </PopupModal>
    </StyleInfoModel>
  )
}

export default InfoModal
