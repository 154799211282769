import React from 'react';

const ConfirmModal = (props) => {
  return (
    <>
      <div className="popup-confirm-modal">
        <div className="content">
          <div className="card">
            <div className="detail-modal">
              <div className="title">{props.title}</div>
              <div className="desc">{props.desc}</div>
              <button className="button" onClick={props.handleClick}>はい </button>&nbsp;
              <button className="button" onClick={props.onClose}>いいえ</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ConfirmModal;
