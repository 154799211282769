import React, {useState, useEffect} from 'react'
import HomeHeader from '../../components/headers/HomeHeader';
import './ForgotPasswordStyle.scss';
import { useHistory } from 'react-router-dom';
import { serverApi } from '../../services/serverApi'
import firebase from '../../services/firebase';
import { REGEX_MAIL_REGISTER} from '../../helper/helper';


const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [validatemail, setvalidateMail] = useState("");
  const [discable, setdiscable] = useState(false);
  const history = useHistory();

  useEffect(() => {
    document.body.className = 'forgot-password';
    return () => { document.body.className = ''; }
  }, [])

  const validateMail = () => {
    var vnf_regex = REGEX_MAIL_REGISTER;
    if (email.length > 0) {
      if (vnf_regex.test(email)) {
        setvalidateMail('');
        return true;
      } else {
        setvalidateMail('メールアドレスを正しく入力してください')
        return false;
      }
    } else {
      setvalidateMail('メールアドレスを入力してください');
      document.getElementById('email').focus();
      return false;
    }
  }


  const GetpassWordClick = async () => {
    if (!discable) {
      setdiscable(true);
      setIsLoading(true);
      var checkmail = validateMail();
      if (checkmail) {
        firebase.auth().sendPasswordResetEmail( email, {
          url: window.location.protocol+"//"+window.location.host+'/login',
          handleCodeInApp: true
        }).then(function() {
          window.location.href = ('/sendmailsuccess');
          setdiscable(false)
        })
        .catch(function(error) {
          setdiscable(false);
          setvalidateMail('未登録のメールアドレスのため、新規登録より設定してください');
        });;
      } else {
        setdiscable(false);
        console.log(window.location.protocol+"//"+window.location.host+'/login');
      }
      setTimeout(() => setIsLoading(false), 1500);
    }
    
  }
  return (
    <div className="fgpw-body">
      <HomeHeader />
      <div className="forgot-password">
        <div className="title-forgotpssword"> 
          <img src="icon/rainbow_kirakira_L.svg" className="imgLeft" alt="rainbow_kirakira_L"/>
          <h2>パスワード再設定</h2>
          <img src="icon/rainbow_kirakira_R.svg" className="imgRight" alt="rainbow_kirakira_R"/>
        </div>
        <div className="content">
          パスワード再設定用のメールを送信します
          ご登録のメールアドレスを入力してください
        </div>
        <div className="form-forgot ">
          <div className="divusername">        
            <p style={{ color: validatemail.length > 0 ? '#FF0001' : '#45556D' }}>メールアドレス</p>
            <input style={{ borderBottom: validatemail.length > 0 ? '2px solid #FF0001' : ''}} type="text" id="email" name="username" onChange={e => setEmail(e.target.value)} placeholder="xxx@tabingo.com" />
            <p className="error-message">{validatemail}</p>
          </div>
        </div>
        <div className="forgotBtn" onClick={GetpassWordClick} >
          <button className="btn"> 
            {(isLoading && discable) ? ( 
                <i className="fa fa-refresh fa-spin" />
              ) : ( 
              <span>送信</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
