import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined'
import {createPlayerCheckin} from 'common/src/api/playerCheckin'
import {apiShareTwitter} from 'common/src/api/playerShare'
import {getDetailShop} from 'common/src/api/shop'
import {transformShop} from 'common/src/googleMap/shop'
import {calculateDistance, showName} from 'common/src/util/geo'
import React, {useEffect, useState } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {withRouter} from 'react-router-dom'
import Loading from '../../components/loading/Loading'
// import {requestUpdateBingo, setNewCheckIn, changeNewStatus} from '../../redux/bingo/action'
import {getShopSingle, checkInShop} from '../../redux/shop/action'
import {requestUpdateUser} from '../../redux/user/action'
import {getUserId, canCheckin} from '../../services/helper'
import {serverApi} from '../../services/serverApi'
import CheckInModal from './CheckInModal/CheckInModal'
import ConfirmModal from './ComfirmModal/ConfirmModal'
import StyleDetailShop from './DetailShop.style'
import ErrorModal from '../../components/modals/ErrorModal'
import InfoModal from './InfoModal/InfoModal'
import  { Swiper, SwiperSlide} from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper.scss';
import $ from 'jquery';

let createBrowserHistory = require('history').createBrowserHistory

const DetailShop = (props) => {
  let shopId    = props.match.params.shop_id
  const history = createBrowserHistory()
  const [data, setData]                 = useState({album: []})
  const [completed, setCompleted]       = useState(true)
  const [distance, setDistance]         = useState(0)
  const [displayColor, setDisplayColor] = useState('#8592aa');
  const [imgColor, setImgColor] = useState('');
  // 0: check-in page, 1 check-in modal, 2: confirm modal, 3: confirm modal, 4: error modal
  const [display, setDisplay]           = useState(0)
  const [contentTweet, setContentTweet] = useState('')

  const shopData     = useSelector((state) => state.shop.items.find((shop) => shop.key === shopId))
  const bingoId      = useSelector((state) => state.bingo.bingo_id)
  const userPosition = useSelector((state) => state.user.userPosition)
  const checkedShops = useSelector(state => state.bingo.shop_ids)
  const needUpdate   = useSelector(state => state.bingo.need_update)
  const dispatch     = useDispatch()

  const walkWidth = 55;
  const accessWidth = $("#access").width();
  const textWidth = (window.innerWidth-37)*0.8;
  useEffect(() => {
    if(display>0){
      document.body.style.overflow = "hidden"
    }
    else{
      document.body.style.overflow = "unset"
    }
  }, [display])
  //share API
  const btnShareClick = async () => {
    const shareData = {
      title: 'Poketabi',
      text: shopData.name,
      url: window.location.href,
    }
    try {
      await navigator.share(shareData)
    } catch (err) {

    }
  }

  useEffect(() => {
    if (data.name) {
      setContentTweet(`http://twitter.com/share?text=${showName(data.name, 20)} にチェックイン！ &url=${window.location.href} @tabingo_jp`)
    }
    if(data.album){

    }
  }, [data])
  useEffect(() => {
    const getSingleShop = async () => {
      const result = await serverApi(getDetailShop(shopId))
      const shop   = result.data.data
      const data   = transformShop(checkedShops, shop)
      dispatch(getShopSingle(data))
    }
    if (typeof shopData === 'undefined' && !needUpdate) {
      getSingleShop()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needUpdate])

  useEffect(() => {
    // redux
    if (shopData) {
      setData({
        ...shopData,
        openTime: shopData.openTime ? (shopData.openTime.replace(/(\r\n|\n|\r)/gm, '<br>')) : '',
        closeDay: shopData.closeDay ? (shopData.closeDay.replace(/(\r\n|\n|\r)/gm, '<br>')) : '',
        description: shopData.description ? (shopData.description.replace(/(\r\n|\n|\r)/gm, '<br>')) : '',
      })
      const completed = checkedShops.includes(shopData.key)
      setCompleted(completed)
    }
  }, [checkedShops, shopData])

  useEffect(() => {
    if (shopData) {
      setDistance(calculateDistance(userPosition, shopData.map))
    }
  }, [userPosition, shopData])

  useEffect(() => {
    if (shopData && shopData.color) {
      const color = (shopData.color === 'red') ? '#ff4e4f' :
                    (shopData.color === 'green') ? '#71c400' :
                    (shopData.color === 'blue') ? '#338ee0' : 'orange';
      const imageColorUrl = (shopData.color === 'red') ? '/icon/shop/ic_up_red.svg' :
                    (shopData.color === 'green') ? '/icon/shop/ic_up_green.svg' :
                    (shopData.color === 'blue') ? '/icon/shop/ic_up_blue.svg' : '/icon/shop/ic_up_orange.svg';

      setImgColor(imageColorUrl)
      setDisplayColor(color)
    }
  }, [shopData])

  const handleCheckin = async (data) => {
    const dataCheckIn = {
      latitude: userPosition.lat,
      longitude: userPosition.lng,
      bingo_id: bingoId,
      color: data.color,
    }

    let result = await serverApi(createPlayerCheckin(getUserId(), shopId, dataCheckIn))
    if (result.error) {
      setDisplay(4)
    } else {
      const cell = result.data.data.checkinCell;
      setCompleted(true)
      setDisplay(2)
      dispatch(checkInShop(shopId))
    //  dispatch(requestUpdateBingo())
      dispatch(requestUpdateUser())
      if(cell > 0){
        //dispatch(changeNewStatus())
       // dispatch(setNewCheckIn(cell))
      }

    }
  }
 
  
  const shareTwitter = async () => {
    window.open(contentTweet, '_blank')
    let result = await serverApi(apiShareTwitter(getUserId(), shopId, bingoId))

    if (result.error) {
      console.log('Share error')
    } else {
      dispatch(requestUpdateUser())
    }
  }
  const [imgIndex, setImgIndex] = useState(0);
  const slides = data.album && data.album.length > 5 ? data.album.slice(0,5) : data.album;
  const renderAlbumImage = ()=>{
    if(data.album && data.album.length > 1){
      
      return(
             <div style={{position:'relative'}}>
              <Swiper effect="fade"
              onSlideChange={(e) =>  {setImgIndex(e.activeIndex)}}>
                {slides.map((item,key)=>(
                  <SwiperSlide key={key}><img src={item} className="imgDesc" alt="imgDesc" /></SwiperSlide>
                ))}
              </Swiper>
                <div className="divDots">
                {slides.map((item,key)=>(
                  <img key={key} style={{marginRight:'1px', width:'8px'}} src={key===imgIndex?"/icon/blue_circle.svg":"/icon/opacity_circle.svg"}   />
                ))} 
                </div>
             </div>
      )
    }
    else{
      return(
        <img className="imgDesc" src={data.image} alt="imgDesc"/>
      )
    }
  }
  const renderAccess = ()=>{
   if(accessWidth > 0 && accessWidth> textWidth &&  accessWidth-walkWidth < textWidth){ //line break
     return(
      <>
      <p className="access" style={{marginLeft:'5px', maxWidth:"90%",fontSize:'12px'}}>{data.access}</p>
      <p className="access" style={{marginLeft:'18px', maxWidth:"90%",fontSize:'12px'}}>{data.walk}</p>
      </>
     )
    }
    else{
      return(
        <p className="access" style={{marginLeft:'5px', maxWidth:"90%", fontSize:'12px'}}>{data.access_walk}</p>
      )
    }
  }
  return (
    <StyleDetailShop>
      {shopData ? <div className="detail-shop">
        <div className="header_top">
          <div className="title">
            <div
              className="load"
              style={{zIndex: 500}}
              onClick={() => {
                history.goBack()
              }}
            >
              <img src = "/icon/goBackGreyArrow.svg" className="backArrow" />
            </div>
            <h2>{data.name}</h2>
            <img src = "/icon/button_share.svg" className="buttonShare" onClick={() => { btnShareClick() }} />
          </div>
        </div>
        <div className="content">  
         {renderAlbumImage()}
          <div className="desc">
            <p className="txt_gnavi" style={{position:'absolute', marginTop:'-30px', right:5, color:'#fff'}}>from gnavi.co.jp</p>
            <div className="titleDesc">
              <h2>{data.name}</h2>
            </div>
            {/* <div className="description">
             <p>{data.name}</p>
             </div> */}
            <div className="dv">
              <div className="inLeft">
                <ul>
                  <li style={{color: displayColor}}>
                    <img src={imgColor} className="iconDetails" width="12px" />
                    <span className="info"><span style={{marginLeft:'4px'}}>{data.food}</span></span>
                    
                  </li>
                  <li>
                    <img src="/icon/shop/ic_bus.svg" className="iconDetails" width="11px" />
                    <span style={{ display:'none'}} id="access">{data.access_walk}</span>
                   {renderAccess()}
                  </li>
                  <li>
                  <img src="/icon/shop/ic_location.svg" className="iconDetails" width="12px" />
                    <span style={{marginLeft:'5px'}}>現在地から{distance}m</span>
                  </li>
                </ul>
                <div className="divPoint">
                  <div className="pointIcon">
                    <span className="icon-icon_point">
                      <span className="path1"></span>
                      <span className="path2"></span>
                      <span className="path3"></span>
                    </span>
                  </div>
                  <div className="point">
                    <span className="small" style={{marginLeft: '2px'}}>
                      +
                  </span>
                    <span>{data.point}</span>
                    <span className="small" style={{marginTop: '3px'}}>
                      P
                  </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="hr"></div>
            <div className="dv">
              <h2 className="titImage">
                みんなの投稿
              </h2>
              <div className="listItem">
                {data.album.map((image, index) => (
                  <div className="item" key={index}>
                    <img src={image} alt={index}/>
                  </div>
                ))}
              </div>
            </div>
            <div className="hr"></div>
            <div className="dv">
              <h2 className="titImage">
                店舗情報{' '}
                <span className="right">
                <a href={data.url} className="unbold" rel="noopener noreferrer" target="_blank">
                 詳細をwebで見る <span className="icon-icon_detail"></span>
                </a>
              </span>
              </h2>
              <div className="tbl">
                <table className="table">
                  <tbody>
                  <tr className="brb brt">
                    <td width="30%">店名</td>
                    <td>{data.name}</td>
                  </tr>
                  {data.tel?(
                    <tr className="brb">
                    <td>電話番号</td>
                    <td>
                      <a href={'tel:' + data.tel}>{data.tel}</a>
                    </td>
                  </tr>
                  ):<></>}
                  {data.openTime ? (
                    <tr className="brb">
                      <td>営業時間</td>
                      <td dangerouslySetInnerHTML={{__html: data.openTime}}></td>
                    </tr>
                  ) : <></>}
                  {data.closeDay?(
                    <tr className="brb">
                    <td>定休日</td>
                    <td dangerouslySetInnerHTML={{__html: data.closeDay}}></td>
                  </tr>
                  ):<></>}
                  {data.description?(
                    <tr className="" >
                    <td colSpan={2} dangerouslySetInnerHTML={{__html: data.description}} style={{borderTop:"1px solid #0000000d"}}>
                    </td>
                  </tr>
                  ):<></>}
                  
                  </tbody>
                </table>
              </div>
            </div>
            <div className="twitterBot">
              {
                canCheckin(distance) &&
                <div className="checkInLeft" onClick={shareTwitter}>
                  <img src="/icon/twitter.svg" alt="twitter"/>
                  <a id="twitter" href={contentTweet} rel="noopener noreferrer" target="_blank"/>
                  <p>画像を投稿</p>
                </div>
              }

              <div className="checkInRight">
                {completed ? <button className="btnCheckIn disable" >
                  <span className="txtChkIn"><span className="item"><img src="/icon/shop/ic_caution.svg" style={{verticalAlign:'middle', width:'21px'}} /> <span className="text">チェックイン済</span></span></span>
                </button> : canCheckin(distance) ? <button className="btnCheckIn" onClick={() => setDisplay(1)} >
                              <span className="txtChkIn"><span className="item"><img src="/icon/shop/ic_checkin.svg" style={{verticalAlign:'middle', width:'21px'}} /> <span className="text">チェックインする</span></span></span>
                            </button> : <button className="btnCheckIn disable" >
                              <span className="txtChkIn"><span className="item"><img src="/icon/shop/ic_caution.svg" style={{verticalAlign:'middle', width:'21px'}} /> <span className="text">距離が離れています!</span></span></span>
                            </button>}
              </div>

            </div>
          </div>
        </div>
      </div> : <Loading/>}
      {display === 1 ? (
        <CheckInModal
          name={data.name}
          image={data.image}
          onClose={() => setDisplay(0)}
          onClick={() => {
            handleCheckin(data)
          }}
        />
      ) : display === 2 ? (
        <InfoModal point={data.point} onNext={() => setDisplay(3)}/>
      ) : display === 3 ? (
        <ConfirmModal color={data.color} onClose={() => setDisplay(0)}/>
      ) : display === 4 ? (
        <ErrorModal onClose={() => setDisplay(0)}/>
      ) : null}
    </StyleDetailShop>
  )
}

export default withRouter(DetailShop)
