import React from "react";

const IconBingo = (props) => {
  return (
    <svg
      width={props.width ? props.width : "22"}
      height={props.height ? props.height : "26"}
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.919 7.321L5.132 10.107L11.225 16.2L22 5.424L19.214 2.639L11.225 10.624L7.919 7.321Z"
        fill={props.fill?props.fill:"#96A4BC"}
      />
      <path
        d="M18.842 10.805C18.845 10.87 18.852 10.934 18.852 11C18.851 15.33 15.329 18.852 10.999 18.852C6.669 18.852 3.147 15.33 3.146 11C3.147 6.67 6.669 3.148 10.999 3.148C12.558 3.148 14.009 3.61 15.232 4.397L17.49 2.14C15.669 0.803 13.432 0 11 0C4.925 0 0 4.925 0 11C0 16.068 3.431 20.327 8.095 21.602L11 26L13.905 21.601C18.568 20.326 21.997 16.067 21.997 11H22C22 9.979 21.852 8.993 21.591 8.055L18.842 10.805Z"
        fill={props.fill?props.fill:"#96A4BC"}
      />
    </svg>
  );
};
export default IconBingo;
