import React, { useState } from 'react';
import IconMapPin from '../../../../../static/svg/IconMapPin';
import IconGenreShrine from '../../../../../static/svg/IconGenreShrine';
import IconGenreHistory from '../../../../../static/svg/IconGenreHistory';
import IconGenreScenicspots from '../../../../../static/svg/IconGenreScenicspots';
import IconSpotChecked from '../../../../../static/svg/IconSpotChecked';
import {  whiteColor } from "../../../../../helper/helper";
import './styles.scss';
import GenreIcon from '../../../../../components/genre/GenreIcon';

const MarkerIcon = (props) => {
  const spot = props.spot;
  const filled = props.filled;
  const color = props.color;
  const genre = spot.genre;
  const spotChecked = spot.isCheckin;

  const fillColor = () => {
    //props.onClick(props.shopId, props);
    props.onClick();

  };

  return props.display ? (
    <>
      <div className="google-map-marker-icon">
        {spotChecked && <div className="icon-spot-checked">
          <IconSpotChecked />
        </div>}
        <div className="box-map-pin" onClick={() => fillColor()}>
          <div className="map-pin-icon">
            <IconMapPin fill={color} backgroundColor={filled ? color : whiteColor} />
          </div>
          <div className="marker-icon">
            <GenreIcon spot = {spot} fill={filled ? whiteColor : color} sizeCategory={'size01'} />
            {/* <IconGenreShrine fill={filled ? whiteColor : color} /> */}
          </div>
        </div>
      </div>
    </>
  ) : null;
}
export default React.memo(MarkerIcon);

