import React from 'react'
import Idicator from './idicator';
import StepBody from './stepBody';
import StepBot from './stepBot';
import StepTitle from './stepTitle';

const CommonStep = (props) => {
  const innerHeight = window.innerHeight;
  return (
    <>
      <div className="tutorial-screen">
        <div className="tutorial-top" style={{ height: (innerHeight - 100) + 'px' }}>
          <StepTitle title={ props.title } title02={props.title02} />
          <StepBody step={ props.step } stepNumber={ props.stepNumber } innerHeight={ innerHeight } />
          <Idicator stepNumber={props.stepNumber} previous={ props.previous } next={ props.next } />
        </div>
        <StepBot />
      </div>
    </>
  )
}

export default CommonStep
