import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';
const MainButton = (props) => {
  const isLoading = props.isLoading;
  return (
    <>
      <button className="main-button" onClick={props.handleClick} disabled={isLoading}>
        {isLoading && <i className="fa fa-refresh fa-spin" />}
        {!isLoading && <div className="title-name">{props.name}</div>}
      </button>
    </>
  )
}
export default MainButton;