import styled from 'styled-components'

const StyleErrorPage = styled.div`
  .header {
    width: 100%;
    background: #ffffff 0% 0% no-repeat padding-box;
    height: 44px;
    line-height: 38px;
    opacity: 1;
    border-bottom: 2px solid #00000029;
    border-top: 2px solid #00000029;

    h1 {
      color: #273956;
      font-size: 20px;
      text-align: center;
    }

    .line {
      margin-left: auto;
      margin-right: auto;
      width: 36%;
      height: 4px;
      display: flex;

      .break {
        content: "";
        width: 25%;
        height: 100%;
      }

      .red {
        background-color: #ff4e4f;
      }

      .orange {
        background-color: #ff9603;
      }

      .green {
        background-color: #71c400;
      }

      .blue {
        background-color: #338ee0;
      }
    }
  }

  .err-content {
    background-color: aliceblue;
    text-align: center;
    height: calc(100vh - 50px) !important;

    .icon {
      font-size: 228px;
      margin-top: 10px;
      display: inline-block;
    }

    .title {
      margin-top: 10px;
      color: #10cad6;
      font-size: 20px;
      font-weight: bold;
    }

    p {
      margin: 10px 40px;
      font-size: 15px;
    }

    .btn {
      background-color: #10cad6;
      font-size: 18px;
      border-radius: 18px;
      padding: 10px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      a {
        color: #ffffff;
      }
    }
  }

  .footerErr {
    position: fixed;
    //left: 0;
    bottom: 86px;
    width: 100%;

    .imgFooter {
      margin-bottom: -10px;
      background-position: bottom;
      background-size: cover;
      background-repeat: no-repeat;
    }

    .copyRight {
      background-color: #dfeef2;
      color: #4a4a4a;
      text-align: center;
      font-size: 11px;
      position: absolute;
      width: 100%;
      font-weight: 500;
      padding: 5px;
    }
  }

  .error-modal {
    display: flex;
    flex-direction: column;
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

    .icon {
      font-size: 300px;
      color: #dee9f4;
      margin-bottom: 15px;
    }

    p {
      font-size: 15px;
      color: #273956;
      font-weight: 500;
      margin-left: 3%;
      margin-right: 3%;
      text-align: center;
    }

    .btn {
      color: rgb(255, 255, 255);
      background-color: #10cad6;
      font-size: 16px;
      font-weight: 600;
      border-radius: 18px;
      padding: 6px 10px;
      width: 100%;
      margin: 15px auto;
      text-align: center;
    }
  }

  @media only screen and (min-width: 1026px) {
    .footerErr {
      position: fixed;
      bottom: 86px;
      width: 700px;
    }
  }
`

export default StyleErrorPage
