import React from "react";

const IconHome = (props) => {
  return (
    <svg
      width={props.width ? props.width : "24"}
      height={props.height ? props.height : "20"}
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 0L0 11.862H3.84V20H9.38V14.509H14.62V20H20.16V11.863H24L12 0Z"
        fill={props.fill?props.fill:'#96A4BC'}
      />
    </svg>
  );
};
export default IconHome;
