import { takeLatest, put } from '@redux-saga/core/effects';
import { serverApi } from '../../services/serverApi';
import { getBingoHistorySuccess, getBingoHistoryFail, setReloadHistory } from './action';
import { getBingosHistory } from 'common/src/api/playerHistory';
import { GET_LIST_HISTORY } from './types'

function* getBingosHistorySaga(action) {
  try {
    const { playerId, playerEventId, size , page} = action.payload;
    const res = yield serverApi(getBingosHistory(playerId, playerEventId, size, page));
    if (res.status === 200) {
      const data = res.data.data;
      if (page === 1) {
        yield put(setReloadHistory(data));
      } else {
        yield put(getBingoHistorySuccess(data));
      }
    } else {
      yield put(getBingoHistoryFail());
    }
  } catch (error) {
    yield put(getBingoHistoryFail());
  }
}

export function* watchGetBingosHistory() {
  yield takeLatest(GET_LIST_HISTORY, getBingosHistorySaga);
}

