import React from 'react';
import { JUMP_TO_TOP_IMG_PATH } from '../../static/svg';
const JumpToTopButton = (props) => {
  return (
    <>
      <div className="jump-to-top-button" onClick={props.onClick}>
        <img src={JUMP_TO_TOP_IMG_PATH} alt="jump to top" />
      </div>
    </>
  )
}
export default JumpToTopButton;