import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { formatDateBingoLog } from '../../../../helper/helper';
import IconLocation from '../../../../static/svg/IconLocation';
import './styles.scss';

const BingoLogSpot = (props) => {
  const date = moment((props.data.date)).format(formatDateBingoLog)
  const design = `rgb(${props.data?.panels?.colors.red},${props.data?.panels?.colors.green},${props.data?.panels?.colors.blue})`;

  return (
    <Link to={`/bingo/${props.data.bingoId}/spot/${props.data.spotId}/color=${design}`}>
      <div className="bingo-log-spot">
        <div className="item-image">
            <img src={props.data?.thumbnailUri}  onerror=""/>
          </div>
          <div className="item-text">
            <div className="title">
              <p>{props.data?.name + "で"}<span style={{ color: design }}>{ props.data?.panels?.name }パネル</span> を 獲得しました</p>
            </div>
            <div className="address"><span><IconLocation fill="#96A4BC" /></span><span className="address-text">{props.data?.address}</span></div>
            <div className="date">{date}</div>
          </div>
      </div>
    </Link>
  )
}

export default BingoLogSpot;
