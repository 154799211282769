import {GET_SHOP_LIST, GET_SHOP_SINGLE, CHECK_IN_SHOP} from './types'

export const getShopList = (shops) => {
  return {
    type: GET_SHOP_LIST,
    payload: shops
  }
}

export const getShopSingle = (shop) => {
  return {
    type: GET_SHOP_SINGLE,
    payload: shop
  }
}

export const checkInShop = (id) => {
  return {
    type: CHECK_IN_SHOP,
    payload: id
  }
}
