import { detailPlayer } from 'common/src/api/player';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HomeSlides from '../../../components/eventSlides/HomeSlides';
import { getUserInfo, setNeedUpdateTotalTicket } from '../../../redux/user/action';
import { serverApi } from '../../../services/serverApi';
import './styles.scss';

const EventBox = (props) => {
  const { playerEvent, eventDetail } = useSelector((state) => state.event);
  const { needUpdateTotalTicket, userInfo } = useSelector((state) => state.user)
  const dispatch = useDispatch();

  useEffect(() => {
    if (needUpdateTotalTicket) {
      getInfoUser(props.playerId)
    }
  },[])
  const totalAcquisitionTicket = (ticketId, wallets) => {
    const walletData = wallets.filter(function (wallet) {
      return ticketId === wallet.ticketId;
    });
    return walletData.length > 0 ? walletData[0].totalAcquisitionTicket : 0;
  }

  const getInfoUser = async (playerId) => {
    const result = await serverApi(detailPlayer(playerId))
   
    if (result.data) {
      dispatch(getUserInfo(result.data.data))
      dispatch(setNeedUpdateTotalTicket(false))
    }
  }

  return (
    <>
      <div className="event-box">
        <HomeSlides playerEvent={playerEvent} />
        {eventDetail.ticketsData && eventDetail.ticketsData.map((ticket, index) => (
          <>
          <div className={index === 0 ? "item-ticket-success margin-top-17" : "item-ticket-success"}>
            <div className="ticket-img">
            <img src={ (ticket.thumbnails && ticket.thumbnails[0]) ? ticket.thumbnails[0] : ''} alt="" />
            </div>
            <div className="ticket-text"><span>{ticket.title}:</span></div>
            <div className="ticket-point">
              <span>{totalAcquisitionTicket(ticket.id, userInfo.activities.wallets)}</span>
            </div>
          </div>
          </>
        ))}
        {/* <div className="get-event-button">
          <Link to="/exchange-ticket" >
            <MainButton name="チケットを交換する" link="" />
          </Link>
        </div> */}
      </div>
    </>
  )
}
export default EventBox;