import { actionTypes } from './action'
const initState = {
  activeTab: 1,
  lat:35.731189,
  lng:139.738628,
  palyerId:'',
  playerEventId:''
}

const appReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.SET_BOTTOM_ACTIVE:
      return {
        ...state,
        activeTab: payload,
      }
    default:
      return state
  }
}

export default appReducer
