import React from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { formatDate } from '../../../../helper/helper'
import './styles.scss'


const NotificationItem = (props) => {
  const date = moment(props.data.registeredAt).format(formatDate)
  return (
    <Link  key={props.data.id} to={"/notifications/" + props.data.id}>
      <div className="notification-item">
        <div className="item-image">
          <img src={props.data.thumbnail} alt={props.idx} />
        </div>
        <div className="item-text">
          <div className="title" dangerouslySetInnerHTML={{ __html: props.data.title }} />
          <div className="date">{date}</div>
        </div>
      </div>
    </Link>
  );
}

export default NotificationItem
