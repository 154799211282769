import React from 'react';
import IconBingo from '../../static/svg/IconBingo';
import IconCaution from '../../static/svg/IconCaution';
import { mainColor, blackColor, whiteColor } from '../../helper/helper';

const CheckinButton = (props) => {
  const isDisable = props.isDisable ? props.isDisable : false;
  const handleClick = () => {
    if (!isDisable) {
      props.onClick();
    }
  }
  return (
    <>
      <div className="checkin-button"
        onClick={() => { handleClick() }}
        style={isDisable ? { backgroundColor: blackColor, opacity: 0.2 } : null}
      >
        <div className="icon-bingo">
          {isDisable ? <IconCaution fill={whiteColor} /> : <IconBingo fill={whiteColor} />}
        </div>
        <div className="title">{props.name}</div>
      </div>
    </>
  )
}
export default CheckinButton;