import React from 'react';

const ItemInfo = (props) => {
  const itemInfo = props.item;
  const tickets = props.item.tickets;
  return (
    <>
      <div className="box-item">
        <div className="item-title">
          <div className="item-text-title">{itemInfo.name}</div>
        </div>
        {tickets && tickets.map((ticket) => (
          <>
          <div className="item-ticket">
            <div className="item-ticket-text"><span>{ticket.title}必要数</span></div>
            <div className="item-ticket-img">
            <img src={ (ticket.thumbnails && ticket.thumbnails[0]) ? ticket.thumbnails[0] : ''} alt="" />
            </div>
            <div className="item-ticket-point">
              <span>×{ticket.amountOfTicketsConsumed}</span>
            </div>
          </div>
          </>
        ))}
        <div className="item-card">
          <div className="item-box-card-1">
            <div className="img-card-1">
              <img src={itemInfo.images[0]} alt="" />
            </div>
          </div>
          <div className="item-box-card-2">
            <div className="img-card-2">
              <img src={itemInfo.images[1]} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default ItemInfo;
