import React from 'react'

const StepBody = (props) => {
  const top = (props.innerHeight / 2) - 180 + 'px';

  return (
    <div className="tutorial-body" style={{ position: 'absolute', top: top }}>
      <div className="image-wrap">
        <div className="image">
          { props.step }
        </div>
      </div>
    </div>
  )
}

export default StepBody
