import React from 'react';

const RedeemInfo = (props) => {
  const redeemInfo = props.redeem;

  function convertTimeJapan(time) {
    let data = '';
    if (time.includes('AM') > 0) {
      data += '午前';
    } else if (time.includes('PM') > 0) {
      data += '午後';
    }
    const arr = time.split(':');
    if (arr.length > 1) {
      const hours = parseInt(arr[0]);
      const minute = parseInt(arr[1]);
      data += hours + '時';
      if (minute > 0) {
        data += minute + '分';
      }
    }
    return data;
  }
  return (
    <>
      <div className="item-info">
        <div className="title-redeem">■{redeemInfo.name}</div>
        <div className="info-header">
          <div className="title">引き換え場所</div>
          <a href={redeemInfo.detailUri} target={'_blank'}>
            <div className="link-web">詳細をwebで見る<span className="icon-icon_detail pd"></span></div>
          </a>
        </div>
        <div className="info-content">
          <div className="row margin-bottom-27">
            <div className="text-left">住所</div>
            <div className="text-right">{redeemInfo.address}</div>
          </div>
          <div className="row margin-bottom-28">
            <div className="text-left">アクセス</div>
            <div className="text-right">{redeemInfo.access}</div>
          </div>
          <div className="row">
            <div className="text-left">電話番号</div>
            <div className="text-right"><a className="tel" href={`tel:${redeemInfo.tel}`}>{redeemInfo.tel}</a></div>
          </div>
          <div className="row">
            <div className="text-left">受付時間</div>
            <div className="text-right">{convertTimeJapan(redeemInfo.acceptanceTime.startAt)}～{convertTimeJapan(redeemInfo.acceptanceTime.endAt)}</div>
          </div>
          <div className="row">
            <div className="text-left">休業日</div>
            <div className="text-right">{redeemInfo.holiday}</div>
          </div>
        </div>
      </div>
    </>
  )
}
export default RedeemInfo;
