export const calculateDistance = (source, destination) => {
  if (destination === undefined) return null
  const rad = (x) => {
    return x * Math.PI / 180
  }

  const R     = 6378137 // Earth’s mean radius in meter
  const dLat  = rad(source.lat - destination.lat)
  const dLong = rad(source.lng - destination.lng)
  const a     = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(rad(destination.lat)) * Math.cos(rad(source.lat)) *
                Math.sin(dLong / 2) * Math.sin(dLong / 2)
  const c     = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  const d     = R * c
  return Math.round(d) // returns the distance in meter
}

export const showName = (name, getLength) => {
  return name.length > getLength ? name.slice(0, getLength) + '...' : name
}

export const formatNumber = (num) => {
  if(num){
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  else{
    return '0';
  }
}
