import styled from 'styled-components'

const StyleMapInfo = styled.div`
  .data {
    overflow: scroll;

    .symbol {
      font-size: 14px;
      margin-left: 14px;
    }

    span {
      margin-left: 28px;
      display: flex;

      .property {
        font-size: 15px;
        color: coral;
      }

      span {
        margin-left: 30px;
      }
    }
  }
`

export default StyleMapInfo
