import React from 'react';

const ButtonDetail = (props) => {
  return (
    <>
      <svg
        width={props.width ? props.width : '8'}
        height={props.height ? props.height : '12'}
        viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10.826L5.434 6.001L0 1.173L1.245 0L8 6.001L1.245 12"
          fill={props.fill ? props.fill :'#96A4BC'} />
      </svg>

    </>
  )
}
export default ButtonDetail;