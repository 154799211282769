import {calculateDistance} from '../util/geo'
import {COLORS} from './map';


export const LIMIT_SHOP_IN_HEX = 1

/**
 * @param {array} checkedShops. EX: ["LXhNydwg4FdtDtMh9VZ6", "8LNv0beF5mcxEnYpPdBR", "dtOAn63ZboEhQj8fcdl1"]
 * @param {object} shop
 */
export function transformShop (checkedShops, shop) {
  let walk = '';
  if (shop.access && shop.access.walk) {
   walk = shop.access.walk.indexOf('徒歩') === -1 ? `徒歩${shop.access.walk}分` : shop.access.walk?shop.access.walk+'分':''
    //walk = shop.access.walk.indexOf('徒歩') === -1 ? `徒歩${shop.access.walk}分`:''
  }
  let address = '';
  if (shop.address && shop.address.length > 1) {
    const splitAddress = shop.address.split(' ')
    if (splitAddress.length > 1) {
      address = splitAddress[1].replace(/[0-9]/g, '').replace(/[&\/\\#,+()$~%.'":*-?<>{}]/g, '')
    }
  }

  // modify data for display
  return {
    key: shop.id,
    map: {
      lat: shop.latitude,
      lng: shop.longitude,
    },
    completed: checkedShops && !!checkedShops.includes(shop.id),
    name: shop.name_kanji,
    image: shop.main_image || (shop.images && shop.images.length >= 1) ? shop.images[0] : '/images/nophoto.png',
    food: shop.category,
    address: address,
    point: shop.point,
    tel: shop.tel,
    url: shop.url,
    shop_id: shop.id,
    album: (shop.images && shop.images.length >= 1) ? shop.images : [],
    openTime: shop.open_time,
    closeDay: shop.holiday,
    description: shop.pr_long,
    slogan: shop.pr_short ? shop.pr_short : shop.name_kanji,
    access: shop.access ? `${shop.access.line}${shop.access.station}${shop.access.station_exit}` : '',
    access_walk: shop.access ? `${shop.access.line}${shop.access.station}${shop.access.station_exit} ${walk}` : '',
    walk: walk?walk:''
  }
}
/**
 *
 * @param hexCentersLocal
 * @param shops
 */
export function getShopDisplayInHex(hexCentersLocal, shops) {
  const r  = 100 / 111111

  return shops.map(data => {
    // get collection of hexs near shop
    const b_upper_lng = data.map.lng + r
    const b_upper_lat = data.map.lat + r
    const b_lower_lng = data.map.lng - r
    const b_lower_lat = data.map.lat - r
    const closePoints = hexCentersLocal.filter(hexPoint => {
      return hexPoint.lng < b_upper_lng && hexPoint.lat < b_upper_lat
             && hexPoint.lng > b_lower_lng && hexPoint.lat > b_lower_lat;

    }).map(p => {
      // get its distance
      return {...p, dis: calculateDistance(data.map, p)}
    })
    let desHex        = {dis: 999999, color: ''}
    // get closest hex
    for (let index = 0; index < closePoints.length; index++) {
      const element = closePoints[index]
      if (element.dis < desHex.dis) {
        desHex = element
      }
    }

    // limit 1 shop within a single hex
    if (desHex && hexCentersLocal[desHex.idx] && hexCentersLocal[desHex.idx].shopsCount < LIMIT_SHOP_IN_HEX) {
      hexCentersLocal[desHex.idx].shopsCount += 1
      return {...data, color: COLORS.find(a => a.hex === desHex.color).icon, display: true, HEX_Number: desHex.shop,}
    } else {
      return {display: false}
    }
  }).filter(data => data.display)
}
