import React from "react";
import IconButtonBingoInfo from '../../static/svg/IconButtonBingoInfo';
import './styles.scss';

const QuestionHeader = (props) => {
  window.scrollTo(0, 0);
  return (
    <>
      <div className="question-header">
        <h1 className="title">{props.name}</h1>
        {/* <div className="question-icon">
          <IconButtonBingoInfo />
        </div> */}
      </div>
    </>
  );
};
export default QuestionHeader;
