import React from 'react';
import {  useLocation } from 'react-router-dom';
import './styles.scss'
const BingoTabbar = (props) => {
  const location = useLocation();
  const action = props.action;
  const bingoMapPath = `/bingo/${props.playerBingoId}/map`;
  const bingoChallengePath = `/bingo/${props.playerBingoId}/challenge`;
  return (
    <>
      <div className="bingo-tab-bar">
        <div className={(location.pathname === bingoMapPath) ? 'tab active' : 'tab'}>
          <button
            className={(location.pathname === bingoMapPath) ? 'item active' : 'item'}
            onClick={(e) => {
              if (e.cancelable) {
                e.stopPropagation()
                e.preventDefault()
              }
              // setActiveTab(1);
              action.history.push(bingoMapPath)
            }}
          >周辺地図</button>
          <div className="line-1"></div>
        </div>
        <div className={(location.pathname === bingoChallengePath) ? 'tab active' : 'tab'}>
          <button
            className={(location.pathname === bingoChallengePath) ? 'item active' : 'item'}
            onClick={(e) => {
              if (e.cancelable) {
                e.stopPropagation()
                e.preventDefault()
              }
              // setActiveTab(2);
              action.history.push(bingoChallengePath)
            }}
          >
            ビンゴシート
          </button>
          <div className="line-2"></div>
        </div>
      </div>
    </>
  )
}
export default BingoTabbar;