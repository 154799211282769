import React from 'react';
import BingoRewardBox from './BingoRewardBox';
import BingoListTicket from './BingoListTicket';


import './styles.scss';

const BingoChallengeBottom = (props) => {
  const sheetDetail = props.sheetDetail;
  const lines = sheetDetail?.clearLines;
  const lineBingo = sheetDetail && sheetDetail.lineChecked ? sheetDetail.lineChecked : 0;
  const imgbingo = sheetDetail?.images?.thumbnail;

  return (
    <>
      <div className="bingo-challenge-bottom">
        <BingoRewardBox sheetDetail = { sheetDetail } />
        <BingoListTicket lines={ lines } lineBingo = { lineBingo } imgbingo = { imgbingo } isBingoClear={ sheetDetail?.isComplete } />
      </div>
    </>
  )
}

export default BingoChallengeBottom;