import React from 'react';

const IconMapPin = (props) => {
  return (
    <>
      <svg
        width={props.width ? props.width : '48'}
        height={props.height ? props.height : '58'}
        viewBox="0 0 48 58" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" d="M34 55C34 56.657 29.523 58 24 58C18.477 58 14 56.657 14 55C14 53.343 18.477 52 24 52C29.523 52 34 53.343 34 55Z"
          fill={props.fill} />
        <path d="M24 0C10.766 0 0 10.766 0 24C0 34.758 7.237 44.269 17.6 47.13C18.124 47.275 18.557 47.629 18.804 48.099L24 55L29.219 48.064C29.469 47.612 29.89 47.271 30.4 47.13C40.763 44.269 48 34.758 48 24C48 10.766 37.234 0 24 0Z"
          fill={props.fill} />
        <path d="M20.482 47.002C19.964 46.125 19.117 45.474 18.132 45.202C8.634 42.58 2 33.861 2 24C2 11.869 11.869 2 24 2C36.131 2 46 11.869 46 24C46 33.861 39.366 42.58 29.868 45.203C28.907 45.468 28.071 46.102 27.552 46.954L24 51.674L20.482 47.002Z"
          fill={props.backgroundColor?props.backgroundColor:'#FFFFFF'} />
      </svg>
    </>
  )
}
export default IconMapPin;