import React from "react";

const IconSpotChecked = () => {
  return (
    <svg 
      width="28"
      height="24"
      viewBox="0 0 28 24" 
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M0.807007 14.254L5.12601 9.86501L9.56301 14.373L22.874 0.851013L27.193 5.23901L9.56301 23.149L0.807007 14.254Z"
        fill="#10CAD6"/>
    </svg>
  );
};
export default IconSpotChecked;