import React from 'react';
import PopupModal from './PopupModal';
import CheckinSuccessButton from '../buttons/CheckinSuccessButton';
import { ILLUST_PANEL_GREEN_IMG_PATH, ICON_BUTTON_CLOSE_IMG_PATH } from '../../static/svg';
import GenreIcon from '../genre/GenreIcon';
import _ from 'lodash';
import { useParams } from 'react-router';
const CheckinSuccessModal = (props) => {
  const params = useParams();
  const spot = props.spot;
  const handleClick = async () => {
    await props.onClick();
  }
  const design = params.color;
  let img_illust = ILLUST_PANEL_GREEN_IMG_PATH;

  return (
    <>
      <PopupModal showCloseBtn={false}>
        <div className="checkin-success-modal">
          <div className="panel-box">
            <img src={img_illust} />
            <div className="icon-image">
              <GenreIcon spot={spot} sizeCategory={'size04'} />
            </div>
          </div>
          <div className="title">
            <p><span style={{ color: design }}>{ spot?.genre }パネル</span>{" "}<span>を</span></p>
            <p>獲得しました</p>
          </div>
          <CheckinSuccessButton name="パネルを確認する" handleClick={handleClick} status={true} />
        </div>
        <div className="button-close"><img src={ICON_BUTTON_CLOSE_IMG_PATH} onClick={props.onClose} /></div>
      </PopupModal>
    </>
  )
}
export default CheckinSuccessModal;