import React from 'react'
import DetailShop from './pages/shop/DetailShop'
import CommonProblem from './pages/info/CommonProblem'
import Error500 from './pages/errors/Error500'
import Help from './pages/help/Help'
import HomeScreen from './pages/home/HomeScreen'
import HowToPlay from './pages/howToPlay/howToPlay'
import InfoTabingo from './pages/InfoTabingo/InfoTabingo'
import MapInfo from './pages/mapInfo/MapInfo'
import MemberAgreement from './pages/info/MemberAgreement'
import Menu from './pages/menu/Menu'
import MenuScreen from './pages/menu/MenuScreen'
import NotificationDetailScreen from './pages/notification/NotificationDetailScreen'
import PrivacyProtection from './pages/info/PrivacyProtection'
import PrivacyProtectionMobile from './pages/info/PrivacyProtectionMobile'
import TermOfUse from './pages/info/TermOfUse'
import TermOfUseMobile from './pages/info/TermOfUseMobile'
import Tutorial from './pages/tutorialV2/tutorial'
import NotificationScreen from './pages/notification/NotificationScreen'
import BingoLogScreen from './pages/bingoLog/BingoLogScreen'
import BingoSelectScreen from './pages/bingo/bingoSelect/BingoSelectScreen'
import BingoSpotScreen from './pages/bingoSpot/BingoSpotScreen';
import BingoSpotDetailScreen from './pages/bingoSpot/bingoSpotDetail/BingoSpotDetailScreen';
import RegisterScreen from './pages/register/Register'
import LoginMail from './pages/loginMail/LoginMail'
import BingoMapScreen from './pages/bingo/bingoMap/BingoMapScreen'
import ExchangeTicketScreen from './pages/exchangeTicket/ExchangeTicketScreen'
import RedeemCardScreen from './pages/RedeemCard/RedeemCardScreen'
const routes = [
  {
    path: "/",
    exact: true,
    main: () => <HomeScreen />,
  },
  {
    path: "/tutorial",
    exact: true,
    main: () => <Tutorial />,
  },
  {
    path: "/bingo/:playerBingoId/map",
    exact: true,
    main: () => <BingoMapScreen />,
  },
  {
    path: "/bingo/:playerBingoId/challenge",
    exact: true,
    main: () => <BingoMapScreen />,
  },
  {
    path: "/menu",
    exact: true,
    main: () => <MenuScreen />,
  },
  {
    path: "/help",
    exact: true,
    main: () => <Help />,
  },
  {
    path: "/shops/:shop_id",
    exact: true,
    main: () => <DetailShop />,
  },
  {
    path: "/error-500",
    exact: true,
    main: () => <Error500 />,
  },
  {
    path: "/term-of-use",
    exact: true,
    main: () => <TermOfUse />,
  },
  {
    path: "/term-of-use-mobile",
    exact: true,
    main: () => <TermOfUseMobile />,
  },
  {
    path: "/privacy-protection",
    exact: true,
    main: () => <PrivacyProtection />,
  },
  {
    path: "/privacy-protection-mobile",
    exact: true,
    main: () => <PrivacyProtectionMobile />,
  },
  {
    path: "/member-agreement",
    exact: true,
    main: () => <MemberAgreement />,
  },
  {
    path: "/map-info",
    exact: true,
    main: () => <MapInfo />
  },
  {
    path: "/problems",
    exact: true,
    main: () => <CommonProblem />
  },
  {
    path: "/info-tabingo",
    exact: true,
    main: () => <InfoTabingo />,
  },
  {
    path: "/how-to-play",
    exact: true,
    main: () => <HowToPlay />
  },
  {
    path: "/notification",
    exact: true,
    main: () => <NotificationScreen />
  },
  {
    path: "/notifications/:notificationId",
    exact: true,
    main: () => <NotificationDetailScreen />
  },
  {
    path: "/bingo-log",
    exact: true,
    main: () => <BingoLogScreen />
  },
  {
    path: "/select-bingo",
    exact: true,
    main: () => <BingoSelectScreen />
  },
  {
    path: "/bingo/:playerBingoId/spots",
    exact: true,
    main: () => <BingoSpotScreen />
  },
  {
    path: "/bingo/:playerBingoId/spot/:spotId/color=:color",
    exact: true,
    main: () => <BingoSpotDetailScreen showBtnCheckin={false} />
  },
  {
    path: "/bingo/:playerBingoId/spot-checkin/:spotId/color=:color",
    exact: true,
    main: () => <BingoSpotDetailScreen showBtnCheckin={true} />
  },
  {
    path: "/exchange-ticket",
    exact: true,
    main: () => <ExchangeTicketScreen showBtnCheckin={true} />
  },
  {
    path: "/redeem-card",
    exact: true,
    main: () => <RedeemCardScreen showBtnCheckin={true} />
  },
  {
    path: "/login",
    exact: true,
    main: () => <LoginMail />
  },
  {
    path: "/register",
    exact: true,
    main: () => <RegisterScreen />
  }
];

export default routes;
