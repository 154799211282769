import React from "react";
import IconButtonBack from '../../static/svg/IconButtonBack';
import './styles.scss';

const GoBackHeader = (props) => {
  // window.scrollTo(0, 0);
  let createBrowserHistory = require('history').createBrowserHistory;
  const history = createBrowserHistory();
  const right = props.right ? props.right : null;
  const rightClick = props.rightClick ? props.rightClick : null;
  return (
    <>
      <div className="go-back-header">
        <div className="back-icon" onClick={() => { history.goBack() }}>
          <IconButtonBack />
        </div>
        <h1 className="title">{props.name}</h1>
        <div className="right-icon" onClick={rightClick}>
          {right}
        </div>
      </div>
    </>
  );
};
export default GoBackHeader;
