import React from 'react';
import './styles.scss';
import { mainColor } from '../../../../helper/helper';
import { formatDateBingoLog } from '../../../../helper/helper';
import moment from 'moment';

const BingoLogClearItem = (props) => {
  const date = moment((props.data.date)).format(formatDateBingoLog)

  return (
    <div className="bingo-log-clear-item" >
      <div className="item-image">
        <img src={props.data.thumbnailUris.bingoClearUri} alt={props.idx} />
      </div>

      <div className="item-text">
        <div className="title"><span style={{color:mainColor}}>{props.data.name}</span>をクリアしました</div>
        <div>
          <div className="ticket"><img className="image-ticket" src={props.data.thumbnailUris.ticketUri} /><span className="ticket-text">×{props.data.acquiredTickets}枚 獲得</span></div>
          <div className="date">{date}</div>
        </div>
      </div>
    </div>
  )
}

export default BingoLogClearItem;
