import styled from 'styled-components'

const StyleMenu = styled.div`
  .menu {
    min-height: calc(100vh - 226px);

    .group {
      .icon-icon_detail-edit {
        position: relative;
        width: 25px;
        height: 25px;
        display: block;
        margin-right: 5px;

        &:after {
          content: "";
          background: url('/icon/edit-button.svg');
          width: 23px;
          height: 23px;
          display: block;
          position: absolute;
          background-size: 100%;
        }
      }

      .group-title {
        background-color: #edf9fc;
        height: 35px;
        line-height: 35px;

        span {
          color: #10cad6;
          font-weight: 400;
          font-size: 12px;
          margin-left: 10px;
        }
      }

      .menu-card {
        display: flex;
        align-items: center;
        height: 60px;
        border-bottom: solid 0.2px #0000001a;
        background-color: #ffffff;
        .nameTitle{
          width: calc(100% - 40px);
          margin-left: 10px;
          font-size: 15px;
          font-weight: 600;
          color: #273956;
          .id {
            color: #8592aa;
            font-size: 10px;
          }
        }
        .title {
          width: calc(100% - 40px);
          margin-left: 10px;
          font-size: 13px;
          font-weight: 500;
          color: #273956;
        }

        button {
          margin-right: 10px;
          background-color: white;
          border: none;
          font-size: 30px;
          color: #10cad6;
          outline: none;
          box-shadow: none;
          font-size: 15px;
        }
      }
      .end{
        border-bottom:none;
      }
    }

    .background-building {
      width: 100%;
      height: auto;
      display: block;
      margin-top: 40px;
    }
    .copy-right {
      text-align: center;
      background-color: #8592AA;
      color: #fff;
      width: 100%;
      height: 25px;
      font-size: 11px;
      display: block;
    }
  }

  @media only screen and (min-width: 300px) {
    .menu-building {
      height: 166px;
    }
  }

  @media only screen and (max-width: 414px) {
    .menu-building {
      height: 80px;
    }
    .menu {
      min-height: calc(100vh - 140px) !important;
    }
  }
`

export default StyleMenu
