import React from "react";

const IconGenreSouvenir = (props) => {
  var svg;
  switch (props.sizeCategory ?? 1) {
    case 'size01':
      // 周辺地図/チェックイン画面
      svg = <svg width="26" height="32" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.13 7.77701H19.174V6.31901C19.174 3.21701 16.662 0.305013 13.573 0.0250135C9.911 -0.304987 6.826 2.58101 6.826 6.17401V7.77701H1.87L0 31.087H26L24.13 7.77701ZM8.759 6.17401C8.759 3.60101 11.063 1.55601 13.716 1.99201C15.777 2.33101 17.24 4.19801 17.24 6.28701V7.77701H8.759V6.17401ZM2.095 29.154L3.654 9.71001H6.826V11.075C6.289 11.431 5.963 12.08 6.08 12.795C6.192 13.476 6.726 14.04 7.401 14.181C8.51 14.412 9.486 13.573 9.486 12.505C9.486 11.926 9.197 11.417 8.759 11.107V9.71001H17.24V11.107C16.725 11.471 16.418 12.109 16.54 12.811C16.657 13.486 17.189 14.042 17.86 14.182C18.968 14.413 19.943 13.573 19.943 12.505C19.943 11.907 19.637 11.383 19.173 11.075V9.71001H22.345L23.904 29.153H2.095V29.154Z" fill={props.fill}/>
      </svg>
      break;

    case 'size02':
      // 周辺地図チェックイン情報
      svg = <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1369 3.64531H8.84954V2.9619C8.84954 1.50789 7.69015 0.142947 6.26446 0.0117017C4.57431 -0.14298 3.15046 1.20978 3.15046 2.89394V3.64531H0.863077L0 14.5715H12L11.1369 3.64531ZM4.04262 2.89394C4.04262 1.68789 5.106 0.729331 6.33046 0.933698C7.28169 1.0926 7.95692 1.96772 7.95692 2.9469V3.64531H4.04262V2.89394ZM0.966923 13.6654L1.68646 4.55137H3.15046V5.19119C2.90262 5.35806 2.75215 5.66227 2.80615 5.99741C2.85785 6.31662 3.10431 6.58098 3.41585 6.64707C3.92769 6.75535 4.37815 6.36209 4.37815 5.86148C4.37815 5.59008 4.24477 5.3515 4.04262 5.20619V4.55137H7.95692V5.20619C7.71923 5.37681 7.57754 5.67586 7.63385 6.00491C7.68785 6.32131 7.93338 6.58192 8.24308 6.64754C8.75446 6.75582 9.20446 6.36209 9.20446 5.86148C9.20446 5.58118 9.06323 5.33556 8.84908 5.19119V4.55137H10.3131L11.0326 13.6649H0.966923V13.6654Z" fill={props.fill}/>
      </svg>
      break;

    case 'size03':
      // ビンゴシート
      svg = <svg width="32" height="39" viewBox="0 0 32 39" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.6985 9.57169H23.5988V7.77723C23.5988 3.95939 20.5071 0.375387 16.7052 0.0307717C12.1982 -0.375382 8.40123 3.17662 8.40123 7.59877V9.57169H2.30154L0 38.2609H32L29.6985 9.57169ZM10.7803 7.59877C10.7803 4.432 13.616 1.91508 16.8812 2.45169C19.4178 2.86893 21.2185 5.16677 21.2185 7.73785V9.57169H10.7803V7.59877ZM2.57846 35.8818L4.49723 11.9508H8.40123V13.6308C7.74031 14.0689 7.33908 14.8677 7.48308 15.7477C7.62092 16.5858 8.27815 17.28 9.10892 17.4535C10.4738 17.7378 11.6751 16.7052 11.6751 15.3908C11.6751 14.6782 11.3194 14.0517 10.7803 13.6702V11.9508H21.2185V13.6702C20.5846 14.1182 20.2068 14.9034 20.3569 15.7674C20.5009 16.5982 21.1557 17.2825 21.9815 17.4548C23.3452 17.7391 24.5452 16.7052 24.5452 15.3908C24.5452 14.6548 24.1686 14.0098 23.5975 13.6308V11.9508H27.5015L29.4203 35.8806H2.57846V35.8818Z" fill={props.fill}/>
      </svg>
      break;

    case 'size04':
      // パネル獲得時
      svg = <svg width="70" height="85" viewBox="0 0 70 85" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M64.9654 21.9381H51.6223V18.0127C51.6223 9.66115 44.8592 1.82115 36.5427 1.0673C26.6835 0.17884 18.3777 7.94884 18.3777 17.6223V21.9381H5.03462L0 84.6958H70L64.9654 21.9381ZM23.5819 17.6223C23.5819 10.695 29.785 5.18922 36.9277 6.36307C42.4765 7.27576 46.4154 12.3023 46.4154 17.9265V21.9381H23.5819V17.6223ZM5.64038 79.4915L9.83769 27.1423H18.3777V30.8173C16.9319 31.7758 16.0542 33.5231 16.3692 35.4481C16.6708 37.2815 18.1085 38.8 19.9258 39.1796C22.9115 39.8015 25.5392 37.5427 25.5392 34.6673C25.5392 33.1085 24.7612 31.7381 23.5819 30.9035V27.1423H46.4154V30.9035C45.0288 31.8835 44.2023 33.6011 44.5308 35.4911C44.8458 37.3085 46.2781 38.8054 48.0846 39.1823C51.0677 39.8042 53.6927 37.5427 53.6927 34.6673C53.6927 33.0573 52.8688 31.6465 51.6196 30.8173V27.1423H60.1596L64.3569 79.4888H5.64038V79.4915Z" fill={props.fill}/>
      </svg>
      break;
  }

  return (
    <>{ svg }</>
  );
};
export default IconGenreSouvenir;
