import React, {useState} from 'react';
import PopupModal from './PopupModal';
import IconBingo from '../../static/svg/IconBingo';
import { mainColor } from '../../helper/helper';
import MainButton from '../buttons/MainButton';



const CheckinModal = (props) => {
  // handle spot name to long
  const spotName = props.name.length > 8 ? `${props.name.substring(0, 8)}...` : props.name
  const isCheckinSpot = props.isCheckinSpot;
  const handleClick = async () => {
    await props.onClick()
  }
  return (
    <>
      <PopupModal onClose={props.onClose}>
        <div className="checkin-modal">
          <div className="bingo-icon"><IconBingo fill={mainColor} width="48" height="56.73" /></div>
          <div className="title">
            <p><span style={{color:mainColor}}>{spotName}</span>{" "}<span>に</span></p>
            <p>チェックインしますか？</p>
          </div>
          <div className="image">
            <img src={props.image} alt={props.name} />
          </div>
          <div className="checkin-button">
            <MainButton name ="チェックイン!" handleClick={handleClick} isLoading ={isCheckinSpot} />
          </div>
        </div>
      </PopupModal>
    </>
  )
}
export default CheckinModal;