import React, {useState} from 'react'
import PopupModal from '../../../components/modals/PopupModal'
import StyleCheckinModal from './CheckInModal.style'

const CheckInModal = (props) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleClick = async () => {
    setIsLoading(true)
    await props.onClick()
  }

  return (
    <StyleCheckinModal>
      <PopupModal onClose={props.onClose}>
        <span className="icon-icon_checkin icon"/>
        <p><span className="name">{props.name}</span>に <br/>チェックインしますか？</p>
        <img src={props.image} alt="" />
        <button
          onClick={handleClick}
          className={isLoading ? 'check-in-button disable' : 'check-in-button'}
        >
          {isLoading ? <i className="fa fa-refresh fa-spin"/> : <span>チェックイン</span>}
        </button>
      </PopupModal>
    </StyleCheckinModal>
  )
}

export default CheckInModal
