/**
 *
 * @param {number|string} userId
 * @returns {{url: string}}
 */
export function getPlayerBingoStarted (userId) {
  return { url: `/players/${userId}/bingos/get-started` };
}

/**
 *
 * @param {string} userId
 * @returns {{method: string, url: string}}
 */
export function createPlayerBingo(userId) {
  return {
    url: `/players/${userId}/bingos`,
    method: "post",
  };
}

export function getBingoSelect (userId, eventId) {
  return {
    url: `/players/${userId}/events/${eventId}/bingos/bingo-select`
  };
}
