import styled from 'styled-components'

const StyleInfoTabingo = styled.div`
  .play-body {
    min-height: 950px;
  }
  .line {
    width: 96%;
    margin:5% auto;
    display:block;
    min-height:5px;
  }

  @media only screen and (min-width: 768px) {
    width: 700px;
    margin: 0 auto;
  }
`

export default StyleInfoTabingo
