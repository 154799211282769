import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { formatDateEventJA, formatDateNoYearEventJA } from '../../helper/helper';
import './styles.scss';
const HomeSlides = (props) => {
  const playerEvent = props.playerEvent;

  let startAt = playerEvent.startAt * 1000;
  let endAt = playerEvent.endAt * 1000;
  const startDate = moment(startAt).format(formatDateEventJA);
  const endDate = moment(endAt).format(formatDateNoYearEventJA);
  const history = useHistory();

  const handleEvent1Click = () => {
      history.push('/select-bingo')
  };

  const handleEvent2Click = () => {
    // 【初級】 熊本市内
    window.location.href = 'https://kumamoto1.tabingo.com/';
  };

  const handleEvent3Click = () => {
    // 【中級】 宇城・天草
    window.location.href = 'https://kumamoto2.tabingo.com/';
  };

  const handleEvent4Click = () => {
    // 【中級】 上益城・八代
    window.location.href = 'https://kumamoto3.tabingo.com/';
  };

  const handleEvent5Click = () => {
    // 【中級】 玉名・鹿本
    window.location.href = 'https://kumamoto4.tabingo.com/';
  };

  const handleEvent6Click = () => {
    // 【中級】 芦北・球磨
    window.location.href = 'https://kumamoto6.tabingo.com/';
  };

  const handleEvent7Click = () => {
    // 【上級】 熊本周遊
    window.location.href = 'https://kumamoto7.tabingo.com/';
  };

  return (
    <>
      <div className="home-slides">
        <Swiper effect="fade" >
          <SwiperSlide>
            <div id ="event1" onClick={handleEvent1Click}>
              <div className="div-image">
                <img src={ playerEvent?.banners?.images?.topBanner } alt="" />
              </div>
              <div className="box-title"><h1>開催期間:{startDate}〜{endDate}</h1></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id ="event2" onClick={handleEvent2Click}>
              <div className="div-image">
                <img src="https://storage.googleapis.com/poke-kumamoto1-prod.appspot.com/banners/home_banner.png" alt=""/>
              </div>
              <div className="box-title"><h1>開催期間:{startDate}〜{endDate}</h1></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id ="event3" onClick={handleEvent3Click}>
              <div className="div-image">
                <img src="https://storage.googleapis.com/poke-kumamoto2-prod.appspot.com/banners/home_banner.png" alt=""/>
              </div>
              <div className="box-title"><h1>開催期間:{startDate}〜{endDate}</h1></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id ="event4" onClick={handleEvent4Click}>
              <div className="div-image">
                <img src="https://storage.googleapis.com/poke-kumamoto3-prod.appspot.com/banners/home_banner.png" alt=""/>
              </div>
              <div className="box-title"><h1>開催期間:{startDate}〜{endDate}</h1></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id ="event5" onClick={handleEvent5Click}>
              <div className="div-image">
                <img src="https://storage.googleapis.com/poke-kumamoto4-prod.appspot.com/banners/home_banner.png" alt=""/>
              </div>
              <div className="box-title"><h1>開催期間:{startDate}〜{endDate}</h1></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id ="event6" onClick={handleEvent6Click}>
              <div className="div-image">
                <img src="https://storage.googleapis.com/poke-kumamoto6-prod.appspot.com/banners/home_banner.png" alt=""/>
              </div>
              <div className="box-title"><h1>開催期間:{startDate}〜{endDate}</h1></div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div id ="event7" onClick={handleEvent7Click}>
              <div className="div-image">
                <img src="https://storage.googleapis.com/poke-kumamoto7-prod.appspot.com/banners/home_banner.png" alt=""/>
              </div>
              <div className="box-title"><h1>開催期間:{startDate}〜{endDate}</h1></div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div class="bingo-list">
        <p><b>ビンゴは全部で７つ</b></p>
        <a href="https://kumamoto1.tabingo.com/" target="_self">①熊本市内</a>
        <a href="https://kumamoto2.tabingo.com/" target="_self">②宇城・天草</a>
        <a href="https://kumamoto3.tabingo.com/" target="_self">③上益城・八代</a>
        <a href="https://kumamoto4.tabingo.com/" target="_self">④玉名・鹿本</a>
        <span id="event1" onClick={handleEvent1Click}><a href="#" target="_self">⑤菊池・阿蘇</a></span>
        <a href="https://kumamoto6.tabingo.com/" target="_self">⑥芦北・球磨</a>
        <a href="https://kumamoto7.tabingo.com/" target="_self">⑦熊本周遊</a>
      </div>
    </>
  )
}
export default HomeSlides;