import React from 'react';

const IconCurrentLocation = (props) => {
  return (
    <>
      <svg 
      width={props.width ? props.width : '24'}
      height={props.height ? props.height : '24'}
      viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.146 12C16.146 14.29 14.29 16.146 12 16.146C9.71 16.146 7.854 14.29 7.854 12C7.854 9.71 9.71 7.854 12 7.854C14.29 7.854 16.146 9.71 16.146 12ZM24 13H21.373C20.907 17.401 17.401 20.907 13 21.373V24H11V21.373C6.599 20.907 3.093 17.401 2.627 13H0V11H2.627C3.093 6.599 6.599 3.093 11 2.627V0H13V2.627C17.401 3.093 20.907 6.599 21.373 11H24V13ZM19.428 12C19.428 7.904 16.096 4.572 12 4.572C7.904 4.572 4.572 7.904 4.572 12C4.572 16.096 7.904 19.428 12 19.428C16.096 19.428 19.428 16.096 19.428 12Z"
         fill={props.fill?props.fill:'#96A4BC'} />
      </svg>

    </>
  )
}
export default IconCurrentLocation;