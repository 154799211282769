import React from "react";

const IconGenreTemple = (props) => {
  var svg;
  switch (props.sizeCategory ?? 1) {
    case 'size01':
      // 周辺地図/チェックイン画面
      svg = <svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.537 23.74H7.958V23.659V20.615V20.534C7.958 19.839 8.535 19.276 9.248 19.276C9.96 19.276 10.538 19.839 10.538 20.534V20.615V23.659V23.74H10.537ZM23.967 20.615C23.967 19.92 23.39 19.357 22.677 19.357C21.965 19.357 21.387 19.92 21.387 20.615V23.74H23.966V20.615H23.967ZM26.183 20.235H27.576V18.971H28.811V20.235H30.175V18.971H31.41V26.286H0.589V18.971H1.824V20.235H3.268V18.971H4.503V20.235H5.817V11.509L5.712 10.414C2.955 10.238 1.053 9.122 0 7.941C3.883 6.83 6.009 4.71 6.901 2.49H5.169C5.173 2.473 5.176 2.456 5.179 2.44H5.115L4.506 0H27.494L26.885 2.439H26.821C26.824 2.456 26.828 2.473 26.831 2.489H25.098C25.991 4.71 28.117 6.83 32 7.941C30.947 9.123 29.045 10.238 26.288 10.414L26.183 11.509V20.235ZM3.268 21.439H1.824V24.478H3.268V21.439ZM5.817 21.439H4.503V24.478H5.817V21.439ZM12.66 17.414H15.171V13.614H12.66V17.414ZM11.425 18.619H7.22V24.479H11.424V18.619H11.425ZM11.425 13.614H7.22V17.414H11.424V13.614H11.425ZM11.425 10.414H7.22V12.245H11.424V10.414H11.425ZM19.34 18.619H12.66V24.49H19.341V18.619H19.34ZM19.34 13.614H16.574V17.414H19.34V13.614ZM19.34 10.414H12.66V12.245H15.171H16.575H19.341V10.414H19.34ZM24.78 18.619H20.576V24.479H24.78V18.619ZM24.78 13.614H20.576V17.414H24.78V13.614ZM24.78 10.414H20.576V12.245H24.78V10.414ZM28.812 24.479H30.176V21.44H28.812V24.479ZM26.183 21.439V24.478H27.576V21.439H26.183Z" fill={props.fill}/>
      </svg>
      break;

    case 'size02':
      // 周辺地図チェックイン情報
      svg = <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.2685 11.87H3.979V11.8295V10.3075V10.267C3.979 9.9195 4.2675 9.638 4.624 9.638C4.98 9.638 5.269 9.9195 5.269 10.267V10.3075V11.8295V11.87H5.2685ZM11.9835 10.3075C11.9835 9.96 11.695 9.6785 11.3385 9.6785C10.9825 9.6785 10.6935 9.96 10.6935 10.3075V11.87H11.983V10.3075H11.9835ZM13.0915 10.1175H13.788V9.4855H14.4055V10.1175H15.0875V9.4855H15.705V13.143H0.2945V9.4855H0.912V10.1175H1.634V9.4855H2.2515V10.1175H2.9085V5.7545L2.856 5.207C1.4775 5.119 0.5265 4.561 0 3.9705C1.9415 3.415 3.0045 2.355 3.4505 1.245H2.5845C2.5865 1.2365 2.588 1.228 2.5895 1.22H2.5575L2.253 0H13.747L13.4425 1.2195H13.4105C13.412 1.228 13.414 1.2365 13.4155 1.2445H12.549C12.9955 2.355 14.0585 3.415 16 3.9705C15.4735 4.5615 14.5225 5.119 13.144 5.207L13.0915 5.7545V10.1175ZM1.634 10.7195H0.912V12.239H1.634V10.7195ZM2.9085 10.7195H2.2515V12.239H2.9085V10.7195ZM6.33 8.707H7.5855V6.807H6.33V8.707ZM5.7125 9.3095H3.61V12.2395H5.712V9.3095H5.7125ZM5.7125 6.807H3.61V8.707H5.712V6.807H5.7125ZM5.7125 5.207H3.61V6.1225H5.712V5.207H5.7125ZM9.67 9.3095H6.33V12.245H9.6705V9.3095H9.67ZM9.67 6.807H8.287V8.707H9.67V6.807ZM9.67 5.207H6.33V6.1225H7.5855H8.2875H9.6705V5.207H9.67ZM12.39 9.3095H10.288V12.2395H12.39V9.3095ZM12.39 6.807H10.288V8.707H12.39V6.807ZM12.39 5.207H10.288V6.1225H12.39V5.207ZM14.406 12.2395H15.088V10.72H14.406V12.2395ZM13.0915 10.7195V12.239H13.788V10.7195H13.0915Z" fill={props.fill}/>
      </svg>
      break;

    case 'size03':
      // ビンゴシート
      svg = <svg width="38" height="32" viewBox="0 0 38 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.5127 28.1912H9.45012V28.0951V24.4803V24.3841C9.45012 23.5588 10.1353 22.8902 10.982 22.8902C11.8275 22.8902 12.5139 23.5588 12.5139 24.3841V24.4803V28.0951V28.1912H12.5127ZM28.4608 24.4803C28.4608 23.655 27.7756 22.9864 26.9289 22.9864C26.0834 22.9864 25.3971 23.655 25.3971 24.4803V28.1912H28.4596V24.4803H28.4608ZM31.0923 24.0291H32.7465V22.5281H34.2131V24.0291H35.8328V22.5281H37.2994V31.2146H0.699437V22.5281H2.166V24.0291H3.88075V22.5281H5.34731V24.0291H6.90769V13.6669L6.783 12.3666C3.50906 12.1576 1.25044 10.8324 0 9.42994C4.61106 8.11063 7.13569 5.59312 8.19494 2.95688H6.13819C6.14294 2.93669 6.1465 2.9165 6.15006 2.8975H6.07406L5.35087 0H32.6491L31.9259 2.89631H31.8499C31.8535 2.9165 31.8582 2.93669 31.8618 2.95569H29.8039C30.8643 5.59312 33.3889 8.11063 38 9.42994C36.7496 10.8336 34.4909 12.1576 31.217 12.3666L31.0923 13.6669V24.0291ZM3.88075 25.4588H2.166V29.0676H3.88075V25.4588ZM6.90769 25.4588H5.34731V29.0676H6.90769V25.4588ZM15.0337 20.6791H18.0156V16.1666H15.0337V20.6791ZM13.5672 22.1101H8.57375V29.0688H13.566V22.1101H13.5672ZM13.5672 16.1666H8.57375V20.6791H13.566V16.1666H13.5672ZM13.5672 12.3666H8.57375V14.5409H13.566V12.3666H13.5672ZM22.9662 22.1101H15.0337V29.0819H22.9674V22.1101H22.9662ZM22.9662 16.1666H19.6816V20.6791H22.9662V16.1666ZM22.9662 12.3666H15.0337V14.5409H18.0156H19.6828H22.9674V12.3666H22.9662ZM29.4263 22.1101H24.434V29.0688H29.4263V22.1101ZM29.4263 16.1666H24.434V20.6791H29.4263V16.1666ZM29.4263 12.3666H24.434V14.5409H29.4263V12.3666ZM34.2142 29.0688H35.834V25.46H34.2142V29.0688ZM31.0923 25.4588V29.0676H32.7465V25.4588H31.0923Z" fill={props.fill}/>
      </svg>
      break;
    case 'size04':
      // パネル獲得時
      svg = <svg width="82" height="68" viewBox="0 0 82 68" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.0011 60.8337H20.3924V60.6262V52.8259V52.6184C20.3924 50.8374 21.8709 49.3947 23.698 49.3947C25.5225 49.3947 27.0036 50.8374 27.0036 52.6184V52.8259V60.6262V60.8337H27.0011ZM61.4154 52.8259C61.4154 51.045 59.9369 49.6023 58.1098 49.6023C56.2853 49.6023 54.8042 51.045 54.8042 52.8259V60.8337H61.4129V52.8259H61.4154ZM67.0939 51.8522H70.6635V48.6132H73.8282V51.8522H77.3234V48.6132H80.4881V67.3579H1.50931V48.6132H4.674V51.8522H8.37425V48.6132H11.5389V51.8522H14.9061V29.4918L14.637 26.6859C7.57219 26.2349 2.69831 23.3751 0 20.3488C9.95019 17.5019 15.3981 12.0694 17.6838 6.38063H13.2456C13.2558 6.33706 13.2635 6.2935 13.2712 6.2525H13.1072L11.5466 0H70.4534L68.8928 6.24994H68.7288C68.7365 6.2935 68.7467 6.33706 68.7544 6.37806H64.3136C66.6019 12.0694 72.0498 17.5019 82 20.3488C79.3017 23.3777 74.4278 26.2349 67.363 26.6859L67.0939 29.4918V51.8522ZM8.37425 54.9374H4.674V62.7249H8.37425V54.9374ZM14.9061 54.9374H11.5389V62.7249H14.9061V54.9374ZM32.4412 44.6234H38.8757V34.8859H32.4412V44.6234ZM29.2766 47.7112H18.5012V62.7274H29.274V47.7112H29.2766ZM29.2766 34.8859H18.5012V44.6234H29.274V34.8859H29.2766ZM29.2766 26.6859H18.5012V31.3778H29.274V26.6859H29.2766ZM49.5588 47.7112H32.4412V62.7556H49.5613V47.7112H49.5588ZM49.5588 34.8859H42.4709V44.6234H49.5588V34.8859ZM49.5588 26.6859H32.4412V31.3778H38.8757H42.4734H49.5613V26.6859H49.5588ZM63.4988 47.7112H52.726V62.7274H63.4988V47.7112ZM63.4988 34.8859H52.726V44.6234H63.4988V34.8859ZM63.4988 26.6859H52.726V31.3778H63.4988V26.6859ZM73.8307 62.7274H77.326V54.94H73.8307V62.7274ZM67.0939 54.9374V62.7249H70.6635V54.9374H67.0939Z" fill={props.fill}/>
      </svg>
      break;
  }

  return (
    <>{ svg }</>
  );
};
export default IconGenreTemple;
