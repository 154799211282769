import React from 'react';
import IconButtonBack from '../../static/svg/IconButtonBack';

const MainGoBackHeader = (props)=>{
  let createBrowserHistory = require('history').createBrowserHistory;
  const history = createBrowserHistory();

  return(
    <>
      <div className="main-header">
        <div className="back-icon" onClick={() => {history.goBack()}}>
          <IconButtonBack fill="#FFFFFF" />
        </div>
        <h1 className="title">{props.name}</h1>
      </div>
    </>
  )
}
export default MainGoBackHeader;