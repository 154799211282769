import React from 'react'

const StepBody2 = (props) => {
    const top = (props.innerHeight / 2) - 180 + 'px';

    return (
        <div className="kumahaku-body">
        <div className="kumahaku-body-box" style={{ marginTop: '32px' }}>
            <p className="kumahaku-body-box-txt1">くまモンスクエア</p>
            <div className="kumahaku-body-box-txt2"><p className="kumahaku-body-box-txt2-child">交換時間</p>10:00～17:00 (火曜休館)</div>
        </div>

        <p className="kumahaku-body-txt7">イベント会場</p>
        <p className="kumahaku-body-txt8">*イベント開催日のみ。イベントにより営業時間が変わります。</p>

        <div className="kumahaku-body-box" style={{ marginTop: '16px' }}>
            <p className="kumahaku-body-box-txt1">出張！くまモンスクエア アミュひろばブース</p>
            <div className="kumahaku-body-box-txt2"><p className="kumahaku-body-box-txt2-child">交換時間</p>11:00～17:00</div>
        </div>

        <div className="kumahaku-body-box" style={{ marginTop: '8px' }}>
            <p className="kumahaku-body-box-txt1">出張！くまモンスクエア 花畑広場ブース</p>
            <div className="kumahaku-body-box-txt2"><p className="kumahaku-body-box-txt2-child">交換時間</p>11:00～17:00</div>
        </div>
        </div>
    )
}

export default StepBody2