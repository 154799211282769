import React from 'react';
import BingoTicketItem from './BingoTicketItem';
import './styles.scss';


const BingoListTicket = (props) => {
  const lines = props.lines;
  return (
    <>
      <div className="bingo-list-ticket">
      <BingoTicketItem isFirst={ true } imgbingo={ props.imgbingo } isBingoClear={ props.isBingoClear } />
        {lines && lines.map((line) => (
          <BingoTicketItem line = { line } lineitem={ true } isBingoClear={ props.isBingoClear }/>
        ))}
        {/* <BingoTicketItem isFirst={true} />
        <BingoTicketItem isClear={true} /> */}
      </div>

    </>
  )
}
export default BingoListTicket;