import React, {useState} from 'react'
import HomeHeader from '../../components/headers/HomeHeader';
import SendmailSuccess from '../../components/modals/SendmailSuccess'
import { SENDMAILFORGOT } from '../../helper/helper';


const SendMailSuccess = () => {
  const handleClick = () => {
    window.location.href = ('/login');
  }
  return(
    <>
      <HomeHeader />
      <SendmailSuccess handleClick={handleClick} name='ログインへ' title={SENDMAILFORGOT} />
    </>
  )
}

export default SendMailSuccess