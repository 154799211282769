import React, { useState } from 'react';
import { mainColor } from '../../helper/helper';
import { BG_ILLUST_PANEL_TICKET_IMG_PATH } from '../../static/svg';
import PopupModal from './PopupModal';

const GetTicketSuccessModal = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const handleClick = async () => {
    setIsLoading(true)
    await props.onClick();
  }
  return (
    <>
      <PopupModal onClose={props.onClose}>
      <div className="get-ticket-success-modal" >
          <div className="panel-box">
            <div className="panel-box-img">
              <img className='img-box' src={props.ticketUri}/>
            </div>
            <img src={BG_ILLUST_PANEL_TICKET_IMG_PATH} />
          </div>
          <div className="title">
            <p><span className='number-line'>{props.number}</span>ラインのビンゴを達成し</p>
            <p><span className='title-ticket' style={{ color: mainColor }}>{props.title}</span>{" "}<span>を</span></p>
            <p><span className='number-ticket'>{props.rewards}</span>枚獲得しました</p>
          </div>
          <div className='modal-id'>{props.id}</div>
        </div>
      </PopupModal>
    </>
  )
}
export default GetTicketSuccessModal;