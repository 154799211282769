import React from 'react'
import '../styles.scss'
import IconCurrentLocation from '../../../../static/svg/IconCurrentLocation';

const CurrentLocationButton = (props) => {
  let right = '15px';
  const width = window.innerWidth;
  if (window.innerWidth > 768) {
    right = Math.ceil((window.innerWidth - 375) / 2) + 15;
    right = right + "px";
  }
  return (
    <div className="current-location"
      style={{ bottom: props.modalVisible ? (width <= 280 ? '170px' : '172px') : '64px', right: right }}
      onClick={props.onClick}
    >
      <div className="box-icon">
        <IconCurrentLocation />
      </div>
      {/* <img className="image-icon" src={BUTTON_CURRENT_LOCATION_IMG_PATH} /> */}
    </div>

  )
}

export default CurrentLocationButton
