import styled from 'styled-components'

const StyleContentInfoTabingo = styled.div`
margin: 0;
height: 100%;
overflow: hidden;
.loginTitle {
  border-top: none;
  position: fixed;
  top: 2%;
  z-index: 2;
  width: 100%;
  line-height: 38px;
  opacity: 1;

  .btn {
    margin-left: auto;
    margin-right: auto;
    display: block;
    font-size: 13px;
    color: white;
    padding: 5px;
    border-radius: 20px;
    border: none;
    background-color: #1da1f2;
    width: 42%;
    font-family: "Noto Sans JP";
    font-weight: bold;
    margin-right: 4%;

    .whileTwitter {
      max-width: 18px;
      float: left;
      margin-left: 2%;
      margin-top: 2px;
    }
  }

  .disable {
    opacity: 0.5;
    pointer-events: none;
    color: #ffffff;
    background-color: #1da1f2;
  }
}

.bgrLogin {
  background-image: url("/images/loginBgr.png");
  min-height: 700px;
  //background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  display:block;

  
}
.title{
  width:90%;
  margin-left: 5%;
  margin-right: 5%;
  
  top:10px;
}
.imgTitle{
  position:absolute;
  top:64px;
}
.divImgBgr{
  display:flex;
  justify-content: center;
  align-items: center;
}
.imgBgr{
  width:198%;
  margin-bottom:2.5px;
}

  .tabingo {
    width: 100%;
    margin: 3% 0;
    display: flex;
    position: inherit;
    justify-content: center;
    
    .imgLeft {
      width: 10%;
      max-width: 30px;
      margin-left: 10px;
      float: left;
    }
    h2 {
      // width: 50%;
      text-align: center;
      color: #10cad6;
      font-size: 20px;
      font-family: "Noto Sans JP";
      float: left;
      margin: 0 10px 0 10px;

    }
    .imgRight {
      width: 10%;
      max-width: 30px;
      margin-right: 10px;
    }
  }

  .content {
    text-align: center;
    color: #273956;
    font-size: 12px;
    font-weight: bold;
    div {
      padding: 2.5px;
    }
  }

  @media only screen and (min-width: 1026px) {
    .tabingo{
      display: flex;
      align-items: center;
      justify-content: center;
      h2{
        font-size: 30px;
      }
      img{
        min-width: 30px;
      }
    }
  }

  @media only screen and (min-width: 769px) {
    .bgrInfo {
      .logo {
        max-width: 160px;
        margin-top: 35px;
      }
      .title {
        margin-top: 9% !important;
      }
    }
  }
  @media only screen and (max-width: 769px) {
    .bgrInfo {
      .logo {
        max-width: 150px;
        margin-top: 30px;
      }
    }
    .title {
      margin-top: 9.5% !important;
    }
  }
  @media only screen and (max-width: 541px) {
    .bgrInfo {
      min-height: 200px;
      .logo {
        max-width: 150px;
      }
    }
    .tabingo {
      .imgLeft {
        max-width: 25px;
      }
      .imgRight {
        max-width: 25px;
      }
    }
  }
  @media only screen and (max-width: 461px) {
    .bgrInfo {
      min-height: 170px;
      .logo {
        max-width: 150px;
        margin-top: 10px;
      }
    }
    .tabingo {
      .imgLeft {
        max-width: 24px;
      }
      .imgRight {
        max-width: 24px;
      }
    }
  }
  @media only screen and (max-width: 414px) and (min-height: 751px) {
    .bgrInfo {
      .logo {
        max-width: 155px;
      }
    }
    .tabingo {
      .imgLeft {
        max-width: 22px;
      }
      .imgRight {
        max-width: 22px;
      }
    }
  }
  @media only screen and (max-width: 376px) and (min-height: 701px) {
    .bgrInfo {
      .logo {
        max-width: 155px;
      }
    }
    .tabingo {
      .imgLeft {
        max-width: 20px;
      }
      .imgRight {
        max-width: 20px;
      }
    }
  }
  @media only screen and (max-width: 376px) and (max-height: 700px) {
    .bgrInfo {
      .logo {
        max-width: 146px;
        margin-top: 6px;
      }
    }
  }
  @media only screen and (max-width: 361px) {
    .bgrInfo {
      min-height: 160px;
      .logo {
        max-width: 125px;
        margin-top: 4px;
      }
    }
    .tabingo {
      .imgLeft {
        max-width: 20px;
      }
      .imgRight {
        max-width: 20px;
      }
    }
  }
  @media only screen and (max-width: 321px) {
    .bgrInfo {
      .logo {
        max-width: 125px;
      }
    }
    .tabingo {
      .imgLeft {
        max-width: 18px;
      }
      .imgRight {
        max-width: 18px;
      }
    }
    .divLogin .btn {
      font-size: 15px;
      margin-top: 20px;
      .whileTwitter {
        max-width: 24px;
      }
    }
  }
`

export default StyleContentInfoTabingo
