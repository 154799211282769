import {DISTANCE_AVAILABLE_CHECK_DEV, DISTANCE_AVAILABLE_CHECK} from 'common/src/googleMap/map'

export function getUserId () {
  return window.localStorage.getItem("user_id");
}

/**
 *
 * @param {int} distance
 * @returns {boolean}
 */
export function canCheckin(distance) {
  return (distance <= DISTANCE_AVAILABLE_CHECK_DEV && process.env.REACT_APP_NODE_ENV !== 'production') ||
          (distance <= DISTANCE_AVAILABLE_CHECK && process.env.REACT_APP_NODE_ENV === 'production')
}

export function disableScroll(display) {
  if (display > 0) {
    document.body.style.overflow = "hidden"
  } else {
    document.body.style.overflow = "unset"
  }
}
