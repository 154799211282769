/**
 * @param {number} size
 * @param {any} fieldValue
 * @returns {{url: string}}
 */
export function listNotification(size = 6, page = 1) {
  let url = `/notifications?size=${size}&sort=registeredAt&sort_direction=desc&page=${page}`
  return { url }
}

/**
 * @param {number| string} notificationId
 * @returns {{url: string}}
 */
export function getDetailNotification(notificationId) {
  return { url: '/notifications/' + notificationId };
}

