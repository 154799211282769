import React from "react";

const IconCaution = (props) => {
  return (
    <svg
      width={props.width ? props.width : "22"}
      height={props.height ? props.height : "26"}
      viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 0C4.925 0 0 4.925 0 11C0 16.068 3.431 20.327 8.095 21.602L11 26L13.905 21.602C18.569 20.327 22 16.068 22 11C22 4.925 17.075 0 11 0ZM11 18.854C9.903 18.854 9.014 17.965 9.014 16.868C9.014 15.771 9.903 14.882 11 14.882C12.097 14.882 12.986 15.771 12.986 16.868C12.986 17.965 12.097 18.854 11 18.854ZM12.986 11.326C12.986 12.423 12.097 13.312 11 13.312C9.903 13.312 9.014 12.423 9.014 11.326V5.134C9.014 4.037 9.903 3.148 11 3.148C12.097 3.148 12.986 4.037 12.986 5.134V11.326Z"
        fill={props.fill ? props.fill : "#FFFFFF"}
      />
    </svg>

  );
};
export default IconCaution;
