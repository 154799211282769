import React from "react";

const IconGenreFood = (props) => {
  var svg;
  switch (props.sizeCategory ?? 1) {
    case 'size01':
      // 周辺地図/チェックイン画面
      svg = <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.3719 1.37225L11.3713 9.7185C11.3713 10.0567 11.3713 10.7518 10.7133 11.4998L8.95263 13.6201C8.08156 14.5207 7.22769 15.7066 7.22769 16.9592V27.8774C7.22769 28.468 6.75813 28.9472 6.17856 28.9472H5.68906H5.6815H5.192C4.61244 28.9472 4.14288 28.468 4.14288 27.8774V16.9592C4.14288 15.7066 3.28969 14.5207 2.41862 13.6208L0.657938 11.5005C0 10.7518 0 10.0574 0 9.7185V1.37225C0 0.615313 0.615313 0 1.37225 0C2.08656 0 2.67369 0.536938 2.73763 1.24919C2.74106 1.28769 2.73419 7.37687 2.73419 7.37687C2.76375 7.77975 3.12606 8.10769 3.52962 8.10769C3.96481 8.10769 4.32781 7.755 4.3285 7.31844L4.33262 1.37225C4.33262 0.702625 4.81181 0.13475 5.47181 0.0213125C5.54125 0.0089375 5.61069 0.00275 5.68081 0.00275V0.0020625C5.68219 0.0020625 5.68425 0.0020625 5.68563 0.0020625C5.687 0.0020625 5.68906 0.0020625 5.69044 0.0020625V0.00275C5.76125 0.00275 5.83069 0.0089375 5.90013 0.020625C6.56012 0.134062 7.03931 0.702625 7.03931 1.37156L7.04344 7.31775C7.04344 7.75362 7.40712 8.10631 7.84163 8.10631C8.24519 8.10631 8.6075 7.77837 8.63706 7.3755C8.63706 7.3755 8.63087 1.28631 8.63431 1.24781C8.69825 0.536938 9.28537 0 9.99969 0C10.7566 0 11.3719 0.615313 11.3719 1.37225ZM22 1.89956C22 0.850438 21.1496 0 20.1004 0C19.2548 0 18.6374 0.55825 18.293 1.31588C18.293 1.31588 14.9827 8.45212 14.9827 12.4259C14.9827 15.1724 17.2624 16.698 18.8657 17.4721H18.8664L18.8726 28.2844C18.8726 28.6488 19.1702 28.9472 19.5353 28.9472H21.3173C21.6817 28.9472 21.9876 28.6488 21.9876 28.2844L22 1.89956Z" fill={props.fill}/>
      </svg>
      break;

    case 'size02':
      // 周辺地図チェックイン情報
      svg = <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.16906 0.619915L5.16875 4.39034C5.16875 4.54315 5.16875 4.85714 4.86969 5.19505L4.06938 6.15288C3.67344 6.55974 3.28531 7.09548 3.28531 7.66136V12.5937C3.28531 12.8604 3.07188 13.0769 2.80844 13.0769H2.58594H2.5825H2.36C2.09656 13.0769 1.88313 12.8604 1.88313 12.5937V7.66136C1.88313 7.09548 1.49531 6.55974 1.09938 6.15319L0.299062 5.19536C0 4.85714 0 4.54346 0 4.39034V0.619915C0 0.277968 0.279688 0 0.62375 0C0.948438 0 1.21531 0.242562 1.24437 0.564322C1.24594 0.581714 1.24281 3.33251 1.24281 3.33251C1.25625 3.51451 1.42094 3.66266 1.60437 3.66266C1.80219 3.66266 1.96719 3.50333 1.9675 3.30611L1.96938 0.619915C1.96938 0.317412 2.18719 0.0608735 2.48719 0.00962794C2.51875 0.00403753 2.55031 0.00124232 2.58219 0.00124232V0.000931737C2.58281 0.000931737 2.58375 0.000931737 2.58438 0.000931737C2.585 0.000931737 2.58594 0.000931737 2.58656 0.000931737V0.00124232C2.61875 0.00124232 2.65031 0.00403752 2.68187 0.00931737C2.98187 0.0605629 3.19969 0.317412 3.19969 0.619605L3.20156 3.3058C3.20156 3.50271 3.36687 3.66204 3.56437 3.66204C3.74781 3.66204 3.9125 3.51389 3.92594 3.33189C3.92594 3.33189 3.92313 0.581093 3.92469 0.563701C3.95375 0.242562 4.22062 0 4.54531 0C4.88938 0 5.16906 0.277968 5.16906 0.619915ZM10 0.858129C10 0.384186 9.61344 0 9.13656 0C8.75219 0 8.47156 0.25219 8.315 0.594448C8.315 0.594448 6.81031 3.81826 6.81031 5.6134C6.81031 6.85416 7.84656 7.54334 8.57531 7.89305H8.57562L8.57844 12.7775C8.57844 12.9421 8.71375 13.0769 8.87969 13.0769H9.68969C9.85531 13.0769 9.99438 12.9421 9.99438 12.7775L10 0.858129Z" fill={props.fill}/>
      </svg>
      break;

    case 'size03':
      // ビンゴシート
      svg = <svg width="26" height="35" viewBox="0 0 26 35" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4396 1.62175L13.4388 11.4855C13.4388 11.8852 13.4388 12.7067 12.6612 13.5907L10.5804 16.0964C9.55094 17.1608 8.54181 18.5624 8.54181 20.0427V32.9461C8.54181 33.644 7.98688 34.2103 7.30194 34.2103H6.72344H6.7145H6.136C5.45106 34.2103 4.89612 33.644 4.89612 32.9461V20.0427C4.89612 18.5624 3.88781 17.1608 2.85837 16.0972L0.777562 13.5915C0 12.7067 0 11.8861 0 11.4855V1.62175C0 0.727187 0.727188 0 1.62175 0C2.46594 0 3.15981 0.634562 3.23538 1.47631C3.23944 1.52181 3.23131 8.71812 3.23131 8.71812C3.26625 9.19425 3.69444 9.58181 4.17137 9.58181C4.68569 9.58181 5.11469 9.165 5.1155 8.64906L5.12038 1.62175C5.12038 0.830375 5.68669 0.15925 6.46669 0.0251875C6.54875 0.0105625 6.63081 0.00325 6.71369 0.00325V0.0024375C6.71531 0.0024375 6.71775 0.0024375 6.71938 0.0024375C6.721 0.0024375 6.72344 0.0024375 6.72506 0.0024375V0.00325C6.80875 0.00325 6.89081 0.0105625 6.97287 0.024375C7.75287 0.158437 8.31919 0.830375 8.31919 1.62094L8.32406 8.64825C8.32406 9.16337 8.75387 9.58019 9.26737 9.58019C9.74431 9.58019 10.1725 9.19262 10.2074 8.7165C10.2074 8.7165 10.2001 1.52019 10.2042 1.47469C10.2797 0.634562 10.9736 0 11.8178 0C12.7124 0 13.4396 0.727187 13.4396 1.62175ZM26 2.24494C26 1.00506 24.9949 0 23.7551 0C22.7557 0 22.0261 0.65975 21.619 1.55512C21.619 1.55512 17.7068 9.98887 17.7068 14.6851C17.7068 17.9311 20.4011 19.734 22.2958 20.6489H22.2966L22.3039 33.4271C22.3039 33.8577 22.6558 34.2103 23.0872 34.2103H25.1932C25.6238 34.2103 25.9854 33.8577 25.9854 33.4271L26 2.24494Z" fill={props.fill}/>
      </svg>
      break;

    case 'size04':
      // パネル獲得時
      svg = <svg width="58" height="77" viewBox="0 0 58 77" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.9806 3.61775L29.9788 25.6215C29.9788 26.5132 29.9788 28.3457 28.2442 30.3177L23.6024 35.9074C21.3059 38.2818 19.0548 41.4084 19.0548 44.7107V73.4951C19.0548 75.052 17.8169 76.3153 16.2889 76.3153H14.9984H14.9785H13.688C12.1601 76.3153 10.9221 75.052 10.9221 73.4951V44.7107C10.9221 41.4084 8.67281 38.2818 6.37637 35.9092L1.73456 30.3195C0 28.3457 0 26.5151 0 25.6215V3.61775C0 1.62219 1.62219 0 3.61775 0C5.50094 0 7.04881 1.41556 7.21738 3.29331C7.22644 3.39481 7.20831 19.4481 7.20831 19.4481C7.28625 20.5102 8.24144 21.3748 9.30538 21.3748C10.4527 21.3748 11.4097 20.445 11.4115 19.2941L11.4224 3.61775C11.4224 1.85237 12.6857 0.35525 14.4257 0.0561875C14.6088 0.0235625 14.7918 0.00725 14.9767 0.00725V0.0054375C14.9803 0.0054375 14.9858 0.0054375 14.9894 0.0054375C14.993 0.0054375 14.9984 0.0054375 15.0021 0.0054375V0.00725C15.1888 0.00725 15.3718 0.0235625 15.5549 0.054375C17.2949 0.353437 18.5582 1.85237 18.5582 3.61594L18.5691 19.2922C18.5691 20.4414 19.5279 21.3712 20.6734 21.3712C21.7373 21.3712 22.6925 20.5066 22.7704 19.4445C22.7704 19.4445 22.7541 3.39119 22.7632 3.28969C22.9317 1.41556 24.4796 0 26.3628 0C28.3584 0 29.9806 1.62219 29.9806 3.61775ZM58 5.00794C58 2.24206 55.7579 0 52.9921 0C50.7627 0 49.1351 1.47175 48.227 3.46912C48.227 3.46912 39.4998 22.2829 39.4998 32.7591C39.4998 40.0001 45.5101 44.022 49.7368 46.0629H49.7386L49.7549 74.5681C49.7549 75.5287 50.5398 76.3153 51.5022 76.3153H56.2002C57.1608 76.3153 57.9674 75.5287 57.9674 74.5681L58 5.00794Z" fill={props.fill}/>
      </svg>
      break;
  }

  return (
    <>{ svg }</>
  );
};
export default IconGenreFood;
