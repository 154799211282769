import styled from 'styled-components'

const StyleInfoModel = styled.div`
  .icon {
    width: 60%;
    margin: 20px 0;
  }

  p {
    font-size: 15px;
    color: #273956;
    font-weight: bold;
    margin-bottom: 20px;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    font-family:'Noto Sans JP';

    span {
      color: #10cad6;

      .number {
        font-size: 21px;
        font-family:'Osawald';
        font-weight:600;
      }
    }
  }
`

export default StyleInfoModel
