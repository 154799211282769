import React from 'react'
import Hexagon from 'react-hexagon'
import HeaderGoBack from '../../components/HeaderGoBack'
import StyleHelp from './Help.style'

let height = window.innerHeight < 880 ? '880px' : window.innerHeight + 60 + 'px'

const Help = () => {
  return (
    <StyleHelp>
      <div className="help" style={{minHeight: height}}>
        <HeaderGoBack text="パネルの説明"/>
        <div className="group">
          <div className="help-card" key="1">
            <div className="left">
              <div className="helpHex">
                <Hexagon
                  style={{
                    stroke: '#deefef',
                    strokeWidth: '20',
                    fill: '#ffffff',
                  }}
                />
              </div>
              <span className="icon-Free helpFreeIcon"></span>
            </div>
            <div className="right">
              <div className="title">フリーパネル</div>
              <p>開始時すでに獲得しているパネルです。</p>
            </div>
          </div>
          <div className="help-card" key="2">
            <div className="left">
              <div className="helpHex">
                <Hexagon
                  style={{
                    stroke: '#deefef',
                    strokeWidth: '20',
                    fill: '#ffffff',
                  }}
                />
              </div>
              <span className="icon-green_rice helpIcon" style={{color: '#71C400'}} />
            </div>
            <div className="right">
              <div className="title" style={{color: '#71C400'}}>
                緑のパネル
              </div>
              <p>
                地図上の緑色マスにある飲食店にチェックインすると獲得可能なパネルです。
              </p>
            </div>
          </div>
          <div className="help-card" key="3">
            <div className="left">
              <div className="helpHex">
                <Hexagon
                  style={{
                    stroke: '#deefef',
                    strokeWidth: '20',
                    fill: '#ffffff',
                  }}
                />
              </div>
              <span
                className="icon-blue_rice helpIcon"
                style={{color: '#71C400'}}
              ></span>
            </div>
            <div className="right">
              <div className="title" style={{color: '#338ee0'}}>
                青のパネル
              </div>
              <p>
                地図上の青色マスにある飲食店にチェックインすると獲得可能なパネルです。
              </p>
            </div>
          </div>
          <div className="help-card" key="4">
            <div className="left">
              <div className="helpHex">
                <Hexagon
                  style={{
                    stroke: '#deefef',
                    strokeWidth: '20',
                    fill: '#ffffff',
                  }}
                />
              </div>
              <span
                className="icon-orange_rice helpIcon"
                style={{color: '#71C400'}}
              ></span>
            </div>
            <div className="right">
              <div className="title" style={{color: '#ff9603'}}>
                黄のパネル
              </div>
              <p>
                地図上の黄色マスにある飲食店にチェックインすると獲得可能なパネルです。
              </p>
            </div>
          </div>
          <div className="help-card" key="5">
            <div className="left">
              <div className="helpHex">
                <Hexagon
                  style={{
                    stroke: '#deefef',
                    strokeWidth: '20',
                    fill: '#ffffff',
                  }}
                />
              </div>
              <span
                className="icon-red_rice helpIcon"
                style={{color: '#71C400'}}
              ></span>
            </div>
            <div className="right">
              <div className="title" style={{color: '#ff4e4f'}}>
                赤のパネル
              </div>
              <p>
                地図上の赤色マスにある飲食店にチェックインすると獲得可能なパネルです。
              </p>
            </div>
          </div>
          {/* <div className="help-card" key="3">
           <div className="left">
           <div className="helpHex">
           <Hexagon
           style={{
           stroke: "#deefef",
           strokeWidth: "20",
           fill: "#ffffff",
           }}
           />
           </div>
           <span
           className="icon-icon_genre_tyuuka helpIcon"
           style={{ color: "#FF4E4F" }}
           ></span>
           </div>
           <div className="right">
           <div className="title" style={{ color: "#FF4E4F" }}>
           中華のパネル
           </div>
           <p>
           地図上のレッドマスにある中華系の飲食店にチェックインすると獲得可能なパネルです。
           </p>
           </div>
           </div>
           <div className="help-card" key="4">
           <div className="left">
           <div className="helpHex">
           <Hexagon
           style={{
           stroke: "#deefef",
           strokeWidth: "20",
           fill: "#ffffff",
           }}
           />
           </div>
           <span
           className="icon-icon_genre_italian helpIcon"
           style={{ color: "#FF9603" }}
           ></span>
           </div>
           <div className="right">
           <div className="title" style={{ color: "#FF9603" }}>
           イタリアンのパネル
           </div>
           <p>
           地図上のオレンジマスにあるイタリアン系の飲食店にチェックインすると獲得可能なパネルです。
           </p>
           </div>
           </div>
           <div className="help-card" key="5">
           <div className="left">
           <div className="helpHex">
           <Hexagon
           style={{
           stroke: "#deefef",
           strokeWidth: "20",
           fill: "#ffffff",
           }}
           />
           </div>
           <span
           className="icon-icon_genre_izakaya helpIcon"
           style={{ color: "#338EE0" }}
           ></span>
           </div>
           <div className="right">
           <div className="title" style={{ color: "#338EE0" }}>
           居酒屋のパネル
           </div>
           <p>
           地図上のブルーマスにあるイタリアン系の飲食店にチェックインすると獲得可能なパネルです。
           </p>
           </div>
           </div>
           <div className="help-card" key="6">
           <div className="left">
           <div className="helpHex">
           <Hexagon
           style={{
           stroke: "#deefef",
           strokeWidth: "20",
           fill: "#ffffff",
           }}
           />
           </div>
           <span className="icon-treasure_blow helpIcon">
           <span className="path1"></span>
           <span className="path2"></span>
           <span className="path3"></span>
           <span className="path4"></span>
           <span className="path5"></span>
           <span className="path6"></span>
           <span className="path7"></span>
           <span className="path8"></span>
           <span className="path9"></span>
           <span className="path10"></span>
           </span>
           </div>
           <div className="right">
           <div className="title" style={{ color: "#273956" }}>
           宝箱[木]のパネル
           </div>
           <p>
           地図上にある木の宝箱付近で[木の宝箱の鍵]を使用すると何か素敵な物が手に入ります。
           </p>
           </div>
           </div> */}
          {/* <div className="help-card" key="7">
           <div className="left">
           <div className="helpHex">
           <Hexagon
           style={{
           stroke: "#deefef",
           strokeWidth: "20",
           fill: "#ffffff",
           }}
           />
           </div>
           <span className="icon-icon_twitter helpIcon">
           <span className="path1"></span>
           <span className="path2"></span>
           <span className="path3"></span>
           <span className="path4"></span>
           <span className="path5"></span>
           <span className="path6"></span>
           <span className="path7"></span>
           <span className="path8"></span>
           <span className="path9"></span>
           <span className="path10"></span>
           </span>
           </div>
           <div className="right">
           <div className="title" style={{ color: "#273956" }}>
           Twitter投稿のパネル
           </div>
           <p>
           チェックイン時に店舗のハッシュタグをつけ写真をTwitter投稿すると獲得可能なパネルです。
           </p>
           </div>
           </div> */}
        </div>
      </div>
    </StyleHelp>
  )
}

export default Help
