import React from "react";

const IconBingoLineget = (props) => {
  return (

    <svg
      width={props.width ? props.width : '20'}
      height={props.height ? props.height : '23'}
      viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0L0 5.668V17.332L10 23L20 17.332V5.673L10 0ZM17 14.461L16.065 16.081L10.936 13.119V19.499H9.065V13.119L3.936 16.081L3 14.461L8.129 11.5L3 8.539L3.935 6.919L9.065 9.88V3.5H10.936V9.88L16.065 6.918L17 8.539L11.871 11.5L17 14.461Z"
        fill={props.fill} />
    </svg>


  );
};
export default IconBingoLineget;
