import React from 'react';

const GoOtherBingo = (props) => {

  return (
    <>
      <div className="go-other-bingo-wrap">
        <div className="go-other-bingo">
          <a href="/select-bingo" className="text">
            他のビンゴにチャレンジ
            <div className="icon">
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 10.826L5.434 6.001L0 1.173L1.245 0L8 6.001L1.245 12" fill="#10CAD6"/>
              </svg>
            </div>
          </a>
        </div>
      </div>
    </>
  )
}

export default GoOtherBingo;