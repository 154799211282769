import React from 'react';

const BingoToLP = (props) => {
  const getTotalAcquisitionTicket = (ticketId, wallets) => {
    const walletData = wallets.filter(function (wallet) {
      return ticketId === wallet.ticketId;
    });
    return walletData.length > 0 ? walletData[0].totalAcquisitionTicket : 0;
  }

  return (
    <>
      {
        <div className="bingo-to-lp-swap">
          {props.eventDetail.ticketsData && props.eventDetail.ticketsData.map((ticket) => (
            <>
            <div className="item-ticket-success">
              <div className="ticket-img">
              <img src={ (ticket.thumbnails && ticket.thumbnails[0]) ? ticket.thumbnails[0] : ''} alt="" />
              </div>
              <div className="ticket-text"><span>{ticket.title}:</span></div>
              <div className="ticket-point">
                <span>{getTotalAcquisitionTicket(ticket.id, props.userInfo.activities.wallets)}</span>
              </div>
            </div>
            </>
          ))}
          {/* <div class="btn-box">
            <div className="box-button">
              <a href="/exchange-ticket" className="button-text">
                <div className="button-text-inner">
                  チケット交換<span className="icon-icon_detail"></span>
                </div>
              </a>
            </div>
          </div> */}
        </div>
      }
    </>
  )
}
export default BingoToLP;
