/**
 *
 * @param {number| string} userId
 * @returns {{url: string}}
 */
export function detailPlayer(userId) {
  return {
    url: `/players/${userId}`,
  };
}

/**
 * @param {number| string} userId
 * @param {string} name
 * @returns {{data: {name: string}, method: string, url: string}}
 */
export function updatePlayerName(userId, name) {
  return {
    url: `/players/${userId}/change-nickname`,
    data: { nickName: name },
    method: "patch",
  };
}

// /**
//  * @param {number| string} userId
//  * @param {boolean} showTutorial
//  * @returns {{data: {name: string}, method: string, url: string}}
//  */
// export function updatePlayerTutorial(userId, showTutorial) {
//   return {
//     url: `/players/${userId}`,
//     data: { show_tutorial: showTutorial },
//     method: "patch",
//   };
// }
