import React from 'react';

const BingoHexagonBlue = (props) => {
  return (
    <>
      <svg
        width={props.width ? props.width : '70'}
        height={props.height ? props.height : '80'}
        viewBox="0 0 70 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35 0L0 20V60L35 80L70 60V20L35 0Z" fill="#4EDFE6" />
        <path d="M1.5 59.129V20.871L35 1.728L68.5 20.871V59.129L35 78.272L1.5 59.129Z" fill="#10CAD6" />
        <path d="M4 57.731V22.269L35 4.5L66 22.269V57.731L35 75.5L4 57.731Z" fill="white" />
      </svg>

    </>
  )
}
export default BingoHexagonBlue;