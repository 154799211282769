export const actionTypes = {
  UPDATE_NOTIFICATION_LIST: 'UPDATE_NOTIFICATION_LIST',
  UPDATE_LASTED_NOTIFICATION: 'UPDATE_LASTED_NOTIFICATION',

  GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
  GET_NOTIFICATIONS_SUCCESS: 'GET_NOTIFICATIONS_SUCCESS',
  GET_NOTIFICATIONS_FAIL: 'GET_NOTIFICATIONS_FAIL',
  GET_NOTIFICATION_DETAIL: 'GET_NOTIFICATION_DETAIL',
  GET_NOTIFICATION_DETAIL_SUCCESS: 'GET_NOTIFICATION_DETAIL_SUCCESS',
  GET_NOTIFICATION_DETAIL_FAIL: 'GET_NOTIFICATION_DETAIL_FAIL',
  CLEAR_NOTIFICATION_DETAIL: 'CLEAR_NOTIFICATION_DETAIL',
  UPDATE_PAGE_NOTIFICATION: 'UPDATE_PAGE_NOTIFICATION'
}



export const updateNotificationList = (data) => {
  return {
    type: actionTypes.UPDATE_NOTIFICATION_LIST,
    payload: data,
  }
}
export const updateLastedNotifications = (lasted) => {
  return {
    type: actionTypes.UPDATE_LASTED_NOTIFICATION,
    payload: lasted,
  }
}
export const getNotifications = (params) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS,
    payload: params,
  };
};

export const getNotificationsSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const getNotificationsFail = () => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_FAIL,
  };
};

export const getNotificationDetail = (params) => {
  return {
    type: actionTypes.GET_NOTIFICATION_DETAIL,
    payload: params,
  };
};

export const getNotificationDetailSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATION_DETAIL_SUCCESS,
    payload: data,
  };
};

export const getNotificationDetailFail = () => {
  return {
    type: actionTypes.GET_NOTIFICATION_DETAIL_FAIL,
  };
};

export const clearNotificationDetail = () => {
  return {
    type: actionTypes.CLEAR_NOTIFICATION_DETAIL,
  };
};

export const updatePageNotification = (data) => {
  return {
    type: actionTypes.UPDATE_PAGE_NOTIFICATION,
    payload: data
  };
};