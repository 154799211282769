import styled from 'styled-components'

const StyleInfoMarkerModal = styled.div`
  display: none;
  position: absolute;
  z-index: 99;
  right: auto;
  left: 3%;
  top: auto;
  bottom: 10px;
  width: 94%;
  overflow: visible;
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0px 4px 8px #00000033;
  .distanceText{
    position:absolute;
    top:-50px;
    left:1px;
    background-color:#fff;
    border:1px solid #000;
    padding:7px;
    font-size:15px;
    font-weight:600;
    color:#273956;
  }
  .left {
    width: 83px;
    height:83px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .left::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  .image {
    // width: 100%;
    // height: 100px;
    // border-radius: 10%;
    // position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    max-height: 115px;
    object-fit: cover;
    object-position: center;
    border-radius: 10px;
  }

  .right {
    width: calc(100% - 83px);
    padding-left:7px;
    h1 {
      margin-bottom: 3px;
      margin-top: 2px;
      color: #273956;
      font-size: 14px;
      font-weight:'bold';
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 5px;
    }

    ul {
      margin-left: 5px;

      li {
        color: #8592aa;
        font-size: 10px;
        display: flex;
        justify-content: flex-start;
        display: flex;
        line-height: 19px;

        span {
          align-self: center;
          font-size: 14px;
          margin-right: 5px;
          
        }
        .icon-icon_walktime:before {
          font-size:14px;
          position: relative;
          margin-right: 1px;
          left: 1px;
        }
        .icon-icon_distance:before{
          font-size:14px;
          position:relative;
          left:0.5px;
        }
        .info {
          margin-top: auto;
          margin-bottom: auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size:10px;
          padding-left:3px;
        }
      }
    }

    .divPoint {
      position: absolute;
      right: 10px;
      bottom: 5px;
      display: flex;
      align-items: center;

      .pointIcon {
        font-size: 19px;
        margin-top: 1px;
      }

      .point {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: "Oswald";
        font-weight: 500;
        font-size: 18px;
        color: #273956;
      }

      .small {
        font-size: 15px;
        text-align: center;
      }
    }
  }
  // @media only screen and (max-width: 350px) {
  //   .left {
  //     width: 100px;
  //   }
  //   .right {
  //     width: calc(100% - 100px);
  //   }
  // }

  @media only screen and (max-width: 280px) {
    display: none;
    position: absolute;
    z-index: 99;
    right: auto;
    left: 3%;
    top: auto;
    bottom: 10px;
    width: 94%;
    overflow: visible;
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    padding: 5px;
    height:95px;
    box-shadow: 0px 4px 8px #00000033;

    .left {
      width: 80px;
    }
    .right {
      width: calc(100% - 80px);
      h1 {
        margin-bottom: 5px;
        color: #273956;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 4px;
      }
  
      ul {
        margin-left: 5px;
  
        li {
          color: #8592aa;
          font-size: 11px;
          display: flex;
          justify-content: flex-start;
          margin-bottom: 5px;
          display: flex;
          line-height: 13px;
  
          span {
            align-self: center;
            font-size: 13px;
            margin-right: 6px;
          }
  
          .info {
            margin-left: 5px;
            margin-top: auto;
            margin-bottom: auto;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
  
      .divPoint {
        position: absolute;
        right: 3%;
        bottom: 5px;
        display: flex;
        align-items: center;
  
        .pointIcon {
          font-size: 21px;
          margin-top: 1px;
        }
  
        .point {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: "Oswald";
          font-weight: 500;
          font-size: 15px;
          color: #273956;
        }
  
        .small {
          font-size: 15px;
          text-align: center;
        }
      }
      
    }
  }
`

export default StyleInfoMarkerModal
