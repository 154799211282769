import React from 'react'

const StepTitle = (props) => {
    return (
    <>
    <div className="kumahaku-title">
        <div className="kumahaku-text">
        <p>{props.title}</p>
        {props.title02 !== null && props.title02 !== "" && <p>{props.title02}</p>}
        </div>
    </div>
    </>
    )
}

export default StepTitle