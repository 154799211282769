import styled from 'styled-components'

const StyleHowToPlay = styled.div`
  .play-body {
    min-height: 950px;
  }
  .line {
    width: 96%;
    margin:4% auto;
    display:block;
    min-height:5px;
  }
  .play-content{
    padding-bottom:60px;
  }
  @media only screen and (min-width: 768px) {
    width: 50%;
    margin: 0 25%;
  }
`

export default StyleHowToPlay
