import styled from 'styled-components'

const StyleCheckinModal = styled.div`
  button {
    border: none;
    outline: none;
    box-shadow: none;
  }

  .icon {
    color: #10cad6;
    font-size: 42px;
    margin-bottom: 10px;
    margin-top: 5px;
    min-height: 0;
  }

  p {
    font-size: 15px;
    color: #273956;
    font-weight: bold;
    margin-bottom: 5px;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    min-height: 0;

    span {
      color: #10cad6;
    }
  }

  img {
    width: 90%;
    height:auto;
    min-height:100px;
    margin: 10px 0 15px;
    max-height: 210px;
    border-radius: 5px;
  }

  .check-in-button {
    width: 80%;
    height: fit-content;
    padding: 9px;
    border-radius: 40px;
    background-color: #10cad6;
    color: white;
    font-size: 16px;
    font-weight: bold;
    min-height: 0;
    font-family:'Noto Sans JP';
    //margin-bottom:15px;
  }

  .disable {
    opacity: 0.5;
    pointer-events: none;
    color: #ffffff;
    background-color: #10cad6;
  }
`

export default StyleCheckinModal
