import React from "react";

const IconButtonChange = (props) => {
  return (
    <svg
      width={props.width ? props.width : '20'}
      height={props.height ? props.height : '22'}
      viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.003 5H4.022L7.854 1.471L6.498 0L0 5.985L6.498 11.97L7.854 10.5L4.054 7H12.003C15.31 7 18.001 9.692 18.001 13V14C18.001 17.308 15.31 20 12.003 20H4.006C4.004 20 4.002 20 4 20V22C4.002 22 4.004 22 4.006 22H12.003C16.42 22 20 18.418 20 14V13C20 8.582 16.42 5 12.003 5Z"
        fill={props.fill} />
    </svg>


  );
};
export default IconButtonChange;
