import React from "react";

const IconPanelGet = (props) => {
  return (
    <svg
      width={props.width ? props.width : '20'}
      height={props.height ? props.height : '23'}
      viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0L0 5.668V17.332L10 23L20 17.332V5.673L10 0ZM16.467 15.299L10 18.966L3.533 15.299V7.701L10 4.034L16.467 7.702V15.299Z"
        fill={props.fill} />
      <path d="M10 6.81201C10.863 9.93701 11.562 10.637 14.687 11.5C11.562 12.363 10.863 13.062 10 16.188C9.137 13.063 8.437 12.363 5.313 11.5C8.437 10.638 9.137 9.93701 10 6.81201Z"
        fill={props.fill} />
    </svg>

  );
};
export default IconPanelGet;
