import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../../components/loading/Loading';
import { HEADER_TABBAR_COPYRIGHT_HEIGHT } from '../../../helper/helper';
import { getBingosSelect, setReloadBingos } from '../../../redux/bingo/action';
import './styles.scss';
const BingoListBottom = () => {
  const { playerEvent } = useSelector((state) => state.event);
  const { bingos, isGettingBingos, needReloadBingos } = useSelector((state) => state.bingo);
  const TOP_HEIGHT = 192;
  const playerId = window.localStorage.getItem('user_id');
  const dispatch = useDispatch();
  useEffect(() => {
    if (bingos.length === 0 || needReloadBingos) {
      if (!playerId || !playerEvent?.id) {
        return;
      }
      dispatch(setReloadBingos(false));
      dispatch(getBingosSelect({ playerId: playerId, playerEventId: playerEvent.id }))
    }
  }, [])

  return (
    <>

      <div className="bingo-list-bottom" style={{ minHeight: window.innerHeight - TOP_HEIGHT - HEADER_TABBAR_COPYRIGHT_HEIGHT + "px" }}>
        {isGettingBingos ? <Loading /> :
          <>
            {/* {bingos && bingos.map((item, key) => (
              <BingoItem bingo={item} key={key} playerEventId={ playerEvent?.id } position={key} bingos={bingos} />
            ))} */}
          </>}

      </div>


    </>
  )
}
export default BingoListBottom;