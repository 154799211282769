import styled from 'styled-components'

const StyleDetailShop = styled.div`
  .header_top {
    justify-content: space-around;
    width: 100%;
    left: 0px;
    top: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    z-index: 9999;
    text-align: center;

    .title {
      background-color: #ffffff;
      height: 47px;

      .load {
        position: absolute;
        top: 11px;
        left:5px;
      }

      .share {
        position: absolute;
        top: 3px;
        right: 14px;
        font-size: 28px;
        color: #8592aa;
      }

      h2 {
        color: #273956;
        margin: 0;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 80%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: 500;
      }
    }

    .tab_bar {
      width: 100%;
      height: 30px;
      display: flex;
      background-color: #ffffff;
      justify-content: space-around;

      .tab {
        width: 38%;

        &.active {
          border-bottom: 2px solid #10cad6;
        }

        button {
          border: 0px;
          background-color: white;
          width: 100%;
          // height: fit-content;
          margin-top: 5px;
          margin-bottom: 5px;
          outline: none;
          box-shadow: none;
        }

        .item {
          // height: 56px;
          padding: 3px 10px;
          color: #8592aa;
          font-family: "Oswald";
          line-height: 1;
          font-size: 11px;

          line-height: 14px;
          letter-spacing: 0.875px;

          &.active,
          &:hover {
            font-weight: 700;
            color: #10cad6;
          }
        }

        .cssarrow {
          position: relative;
          background: #10cad6;
        }
      }
    }
  }

  .content {
    // padding-bottom: 60px;
    .imgDesc {
      width: 100%;
      max-height: 850px;
      margin-bottom: 20px;
    }

    .desc {
      border-top-left-radius: 5%;
      border-top-right-radius: 5%;
      margin-top: -35px;
      min-height: 700px;
      background-image: url("/images/BG.jpg");
      z-index: 100;
      position: relative;
      .titImage {
        padding:5px 0;
        font-size:16px;
        color:#273956;
        font-weight:700;
      }
      .titleDesc {
        background-color: #ffffff;
        bottom: 0;
        border-radius: 15px;
        padding: 15px 10px 0 10px;
        text-align: center;

        h2 {
          font-size: 17px;
          font-weight: bold;
          color: #273956;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .description {
        font-size: 16px;
        font-weight: bold;
        color: #273956;
        text-align: center;
        padding: 5px;
      }

      .dv {
        margin: 0 10px;
        margin-bottom: 20px;
        overflow: hidden;

        .inLeft {
          position: relative;
          margin-top:10px;
          .divPoint {
            position: absolute;
            right: 0;
            bottom: -5px;
            display: flex;
            align-items: center;

            .pointIcon {
              font-size: 24px;
              margin-top: 1px;
            }

            .point {
              display: flex;
              justify-content: center;
              align-items: center;
              font-family: "Oswald";
              font-weight: 500;
              font-size: 30px;
              color: #273956;
            }

            .small {
              font-size: 24px;
              text-align: center;
            }
          }

          ul {
            li {
              color: #8592aa;
              font-size: 10px;
              width:90%;
              // display: flex;
              // justify-content: flex-start;
              // display: flex;
              line-height: 19px;
              .iconDetails{
                vertical-align: top;
                position: relative;
                top: 3px;
                display:inline-block;
              }
              .access{
                // white-space: nowrap; 
                // overflow: hidden;
                // text-overflow: ellipsis; 
                //width:70%;
                display:inline-block;
                //width:calc(100% - 30px);
              }
              span {
                align-self: center;
                font-size: 12px;
                // margin-right: 6px;
                
              }
              .icon-icon_walktime:before {
                font-size:14px;
                position: relative;
                margin-right: 1px;
                left: 1px;
              }
              .icon-icon_distance:before{
                font-size:14px;
                position:relative;
                left:0.5px;
              }
              .info {
                margin-top: auto;
                margin-bottom: auto;
                font-size:10px;
                width:calc(100% - 20px);
                display:inline-block;
                
              }
            }
          }
        }
      }

      .hr {
        border-bottom: 12px solid #f0f1f4;
      }

      .right {
        float: right;
        margin-right: 0;

        a {
          color: #10cad6;
          font-size:12px;
        }
      }

      .listItem {
        margin-bottom: 10px;
        min-height: 100px;
        margin: 0 -5px;

        .item {
          position: relative;
          width: 25%;
          float: left;
          padding: 5px;

          img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            padding: 5px;
          }
        }

        .item::after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }

      .tbl {
        background-color: #fff;
        margin-bottom: 132px;

        a {
          color: #10cad6;
        }

        .table {
          width: 100%;
          margin: 0 auto;
          overflow: hidden;
          border-collapse: collapse;

          tr {
            td {
              padding: 10px 0;
              font-size: 13px;
              color: #273956;
            }

            td:first-child {
              color: #8592aa;
            }
          }
        }
      }
    }

    .twitterBot {
      width: 100%;
      padding: 10px 10px 10px 5px;
      position: fixed;
      bottom: 60px;
      background-color: #ffffff;
      border-top: 1px solid #f0f1f4;
      display: flex;
      justify-content: center;
      
      #twitter {
        display: none;
      }

      .checkInLeft {
        float: left;
        width: 24%;
        display: block;

        img {
          margin-left: auto;
          margin-right: auto;
          display: block;
          max-width: 25.845px;
          margin-top: 8px;

        }

        p {
          color: #8592aa;
          text-align: center;
          font-weight:600;
          font-size:11px
        }
      }

      .checkInRight {
        float: left;
        width: 76%;
        position: relative;
        .txtChkIn {
          top: 0;
          font-size:16px;
          font-weight:600;
          .item{
            span{
              vertical-align: middle;
            }
            .icon{
              font-size: 21px;
              color:#fff;
              &::before {
                color: #ffffff;
              }
            }
            .text{
              position: relative;
            }
          }
          
        }
        
        .background-gray {
          background: #000000 0% 0% no-repeat padding-box !important;
          opacity: 0.2;
        }
        .background-blue {
          background-color: #10cad6;  
        }
      }
    }

    .btnCheckIn {
      background-color: #10cad6;
      border-radius: 45px;
      color: #fff;
      font-size: 17px;
      width: 100%;
      padding: 10px 5px;
      border: none;
      font-weight: 600;
    }

    &::before {
      color: #ffffff;
    }

    .unbold {
      font-weight: 400;
    }

    .disable {
      background-color: darkgray;
      pointer-events: none;
    }

    .twitter {
      text-align: center;

      span {
        width: 100%;
        font-size: 29px;
      }
    }

    .textCenter {
      text-align: center;
    }

    .txtTwitter {
      font-weight: bold;
      font-size: 18px;
      color: #8592aa;
    }

    .brb {
      border-bottom: 1px solid #0000001a;
    }
    .brt{
      border-bottom: 1px solid #0000001a;
    }
    .mgr5 {
      margin-right: 5px;
    }

    .list .btn {
      line-height: 25px;
    }
    .txt_gnavi{
      color:#ffffff;
      possition:'absolute';
      margin-top:10px;
      font-family:'Oswald'
    }
    .default-nav{
      display:none;
    }
    .divDots{
      position: absolute; 
      left: 0; 
      right: 0; 
      bottom:42px;
      margin-left: auto; 
      margin-right: auto;
      width: 200px; 
      text-align:center;
      z-index:9;
    }
  }
  .backArrow{
    width:13px;
    position:absolute;
    left:16px;
  }
  .buttonShare{
    width:17px;
    height:25px;
    position:absolute;
    top:9px;
    right:21px;
  }
  @media only screen and (min-width: 1026px) {
    .twitterBot {
      width: 700px !important;
    }
  }

  @media only screen and (max-width: 415px) {
    .header_top .title h2 {
      width: 80%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .content {
      .imgDesc {
        width: 100%;
        height:auto;
        max-height: 250px;
      }
      // .imgDesc{
      //   height:100%;
      //   width:auto;
      //   max-width:100%;
      //   max-height: 250px;
      //   margin-left:auto;
      //   margin-right:auto;
      //   display:block;
      // }
    }
    .content .desc .dv .inLeft .divPoint {
      .pointIcon {
        font-size: 24px;
        margin-top: 1px;
      }

      .point {
        font-size: 20px;
      }

      .small {
        font-size: 16px;
      }
    }
    
  }
`

export default StyleDetailShop
