import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../../components/loading/Loading';
import { serverApi } from '../../services/serverApi';
import { detailPlayer } from 'common/src/api/player';
import { getUserInfo } from '../../redux/user/action';
import './styles.scss';
import { HEADER_TABBAR_COPYRIGHT_HEIGHT } from '../../helper/helper';
import RedeemInfo from './RedeemInfo';
import ItemInfo from './ItemInfo';
import Step from './Step';
import Ticket from './Ticket';

const ExchangeTicketBottom = () => {
  const TOP_HEIGHT = 192;
  const playerId = window.localStorage.getItem('user_id')
  const { userInfo } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const { eventDetail, isGettingEventDetail } = useSelector((state) => state.event);

  const getInfoUser = async (playerId) => {
    const result = await serverApi(detailPlayer(playerId))
    if (result.data) {
      const player = result.data.data
      dispatch(getUserInfo(player))
    }
  }

  useEffect(() => {
    if (!playerId) {
        return;
    }
    if (!userInfo.activities) {
      getInfoUser(playerId);
    }
  }, [])

  return (
    <>
      {
        (!isGettingEventDetail || !userInfo.activities) ?
        <Loading /> : 
        <div className="bingo-list-bottom" style={{ minHeight: window.innerHeight - TOP_HEIGHT - HEADER_TABBAR_COPYRIGHT_HEIGHT + "px" }}>
          <div className="title">チケットを交換して限定カードをGET!</div>
          <div className="description">各ビンゴで列を揃えるたびにチケットを獲得できます。獲得したチケットを使用し、指定の場所で限定カードとの引き換えが出来ます。</div>
          <div className="top">
            <img src="icon/icon_reward_left.svg" className="img-left" alt="icon_reward_left"/>
            <span className="title-content">チケット所持数</span>
            <img src="icon/icon_reward_right.svg" className="img-right" alt="icon_reward_right"/>
          </div>
          <Ticket tickets={eventDetail.ticketsData} activities={userInfo.activities} />
          <div className="top-redeem">
            <img src="icon/icon_reward_left.svg" className="img-left" alt="icon_reward_left"/>
            <span className="title-content">カード引き換え情報</span>
            <img src="icon/icon_reward_right.svg" className="img-right" alt="icon_reward_right"/>
          </div>
          {/* display box items */}
          <div className="description-redeem">カードの引き換えが可能な場所は<span className="redeem-index">{eventDetail?.redeems?.length}</span>箇所あります。</div>
          {eventDetail.redeems && eventDetail.redeems.map((redeem) => (
            <>
            <RedeemInfo redeem={ redeem } />
              {redeem.items && redeem.items.map((item) => (
              <>
              <ItemInfo item={ item } />
              </>
              ))}
            </>
          ))}
          <Step />
        </div>
      }
    </>
  )
}
export default ExchangeTicketBottom;