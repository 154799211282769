import React from "react";

const IconDifficulty = (props) => {
  return (
    <svg
      width={props.width ? props.width : '16'}
      height={props.height ? props.height : '16'}
      viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.40199 0.281391L10.2907 5.08444C10.3531 5.24283 10.4955 5.35163 10.6587 5.36443L15.5986 5.76361C15.9849 5.79481 16.1417 6.3012 15.8465 6.56439L12.0739 9.93228C11.9491 10.0435 11.8947 10.2187 11.9331 10.3859L13.097 15.4353C13.1882 15.8305 12.7778 16.1433 12.4475 15.9305L8.22679 13.209C8.0876 13.1194 7.9116 13.1194 7.77161 13.209L3.55254 15.9305C3.22215 16.1433 2.81177 15.8305 2.90297 15.4353L4.06693 10.3859C4.10533 10.2187 4.05093 10.0435 3.92613 9.93228L0.153454 6.56439C-0.141737 6.3012 0.0150582 5.79481 0.401446 5.76361L5.34129 5.36443C5.50448 5.35083 5.64688 5.24283 5.70927 5.08444L7.59801 0.281391C7.74681 -0.093797 8.25399 -0.093797 8.40199 0.281391Z"
        fill={props.fill?props.fill:'#E8E8EE'} />
    </svg>


  );
};
export default IconDifficulty;
