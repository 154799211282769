import styled from 'styled-components'

const StyleEditUserModal = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  right:0;
  bottom:0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  max-width:700px;
  margin: auto;

  .content {
    margin: 0 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
    .head {
      background-color: #10CAD6;
      text-align: center;
      padding: 7px;
      color: #fff;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      position: relative;
      font-size: 13px;

      &:after {
        content: "";
        display: block;
        width: 10px;
        height: 10px;
        background-color: #10CAD6;
        transform: translateX(-50%) rotate(45deg);
        bottom: -5px;
        left: 50%;
        position: absolute;
        z-index: 1;
      }
    }
    button {
      border: none;
      outline: none;
      box-shadow: none;
    }

    .card {
      // margin-top: 40%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      padding: 10px 0;
      border-bottom-left-radius:5px;
      border-bottom-right-radius:5px;
      .icon {
        color: #10cad6;
        font-size: 45px;
        margin-bottom: 10px;
        min-height: 0;
      }
      .txtUserName{
        background-color: #EDF9FC;
        color: #273956;
        font-weight: 500;
        font-size: 15px;
        height: 40px;
        padding: 5px;
        margin: 20px 0 5px 0;
        display: table-cell;
        width: 90%;
        text-align: center;
        border: none;
        border-radius: 4px;
      }
      p {
        font-size: 10px;
        color: #8592AA;
        margin-bottom: 5px;
        margin-left: 5%;
        margin-right: 5%;
        text-align: center;
        min-height: 0;
        font-family:"Noto Sans JP";
        span {
          color: #10cad6;
        }
      }

      img {
        width: 90%;
        height: 70%;
        margin-bottom: 15px;
        min-height: 0;
      }

      .edit-user-button {
        width: 90%;
        height: fit-content;
        margin: 10px 0;
        padding: 9px;
        border-radius: 30px;
        background-color: #ffffff;
        color: #10CAD6;
        border:2px solid #10CAD6;
        font-size: 14px;
        font-weight: bold;
        min-height: 0;
        cursor: pointer;
      }
      .disabled {
        background-color: darkgray;
        pointer-events: none;
        color: #fff;
        border: none;
        opacity: 0.4;
        padding: 9px;
      }
    }

    .close-button {
      margin-top: 5%;
      border-radius: 100%;
      width: fit-content;
      height: fit-content;
      padding: 10px;
      background-color: black;
      margin-left: auto;
      margin-right: auto;
      color: white;
      font-size: 30px;
      padding: 10px;
      margin-bottom: 30px;
    }
  }
`

export default StyleEditUserModal
