import {createStore, applyMiddleware} from 'redux'
import reducers from './reducers'
import rootSaga from './sagas'
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  reducers,
  {},
  composeWithDevTools(
    applyMiddleware(sagaMiddleware, logger),
  )
)

store.subscribe(()=>{
  localStorage.setItem('jwt_id', store.getState().user.jwt_token)
})

sagaMiddleware.run(rootSaga);