import React, {useEffect} from 'react'
import HeaderGoBack from '../../components/HeaderGoBack'
import ContentInfoTabingo from '../InfoTabingo/contentInfoTabingo'
import ContentHowToPlay from './contentHowToPlay'
import StyleHowToPlay from './howToPlay.style'

const HowToPlay = () => {
  useEffect(() => {
    document.getElementsByClassName('end-content-info')[0].scrollIntoView()
  }, [])

  return (
    <React.Fragment>
      <div className="menu">
        <HeaderGoBack text="TABINGOの遊び方" style={{ borderBottom: 'none'}}/>

        <StyleHowToPlay>
          <ContentInfoTabingo/>
          <img className="line" src="icon/rainbow_LINE.svg" alt="rainbow_LINE"/>
          <ContentHowToPlay/>
        </StyleHowToPlay>
      </div>
    </React.Fragment>
  )
}

export default HowToPlay
