import React from "react";

const ChallengeToSpots = (props) => {
  return (
    <>
      <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
          <circle cx="25" cy="25" r="20" fill="white"/>
        </g>
        <path d="M35.9999 23.4998C36.0002 21.5436 35.4541 19.6261 34.423 17.9636C33.3919 16.3011 31.9168 14.9597 30.1641 14.0907C28.4114 13.2217 26.4508 12.8597 24.5034 13.0453C22.5559 13.231 20.6991 13.9571 19.1422 15.1416C17.5853 16.3261 16.3903 17.9221 15.6919 19.7495C14.9935 21.5768 14.8195 23.563 15.1896 25.484C15.5596 27.4049 16.4589 29.1843 17.7861 30.6215C19.1134 32.0587 20.8157 33.0965 22.7012 33.618L25.5028 36.9961L28.3053 33.618C30.5177 33.0046 32.468 31.683 33.8577 29.8556C35.2474 28.0282 35.9999 25.7956 35.9999 23.4998ZM25.4999 30.9968C23.5119 30.9968 21.6052 30.207 20.1995 28.8013C18.7937 27.3955 18.0039 25.4889 18.0039 23.5008C18.0039 21.5127 18.7937 19.6061 20.1995 18.2003C21.6052 16.7945 23.5119 16.0048 25.4999 16.0048C27.488 16.0048 29.3946 16.7945 30.8004 18.2003C32.2062 19.6061 32.9959 21.5127 32.9959 23.5008C32.9959 25.4889 32.2062 27.3955 30.8004 28.8013C29.3946 30.207 27.488 30.9968 25.4999 30.9968ZM25.4999 27.1452C25.0164 27.1531 24.5362 27.0646 24.0873 26.885C23.6383 26.7054 23.2296 26.4382 22.8849 26.099C22.5402 25.7599 22.2665 25.3555 22.0796 24.9095C21.8928 24.4635 21.7965 23.9848 21.7965 23.5013C21.7965 23.0177 21.8928 22.539 22.0796 22.093C22.2665 21.647 22.5402 21.2426 22.8849 20.9035C23.2296 20.5643 23.6383 20.2972 24.0873 20.1176C24.5362 19.938 25.0164 19.8495 25.4999 19.8573C26.4562 19.8728 27.368 20.2635 28.0387 20.9452C28.7095 21.6269 29.0854 22.5449 29.0854 23.5013C29.0854 24.4576 28.7095 25.3756 28.0387 26.0573C27.368 26.739 26.4562 27.1297 25.4999 27.1452Z" fill="#96A4BC"/>
        <defs>
          <filter id="filter0_d" x="0" y="0" width="50" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
          <feFlood flood-opacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset/>
          <feGaussianBlur stdDeviation="2.5"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
          </filter>
        </defs>
      </svg>
    </>
  );
};
export default ChallengeToSpots;
