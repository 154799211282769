import React from 'react'
import styled from 'styled-components'

const StyleCurrentLocation = styled.div`
  // margin: 50px;
  position: absolute;
  // top: -64px;
  // left: -64px;

  .dot {
    width: 100%;
    position: relative;

    .point {
      background: #2ba7d9;
      border: 1px solid #0f7baf;
      display: block;
      height: 14px;
      left: 50%;
      margin-left: -7px;
      position: relative;
      text-align: center;
      top: -7px;
      width: 14px;
      -moz-border-radius: 8px;
      -webkit-border-radius: 8px;
      border-radius: 8px;
      box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.3), inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -1px 1px rgba(44, 77, 143, 0.7), 0 1px 1px rgba(0, 0, 0, 0.2);

      .pulse {
        background: transparent;
        border: 1px solid #60c9e9;
        display: block;
        height: 64px;
        left: -26px;
        position: absolute;
        top: -26px;
        width: 64px;
        z-index: -1;
        border-radius: 32px;
        box-shadow: #60c9e9 2px 2px 40px 0px;
      }
    }
  }
`

const CurrentLocation = () => {
  return (
    <StyleCurrentLocation>
      <div className="dot med">
        <span className="point">
          <span className="pulse"/>
        </span>
      </div>
    </StyleCurrentLocation>
  )
}

export default CurrentLocation
