import React from 'react';
import { IC_HEXAGON_GREY_IMG_PATH, IC_HEXAGON_YELLOW_IMG_PATH } from '../../../../static/svg'
const HexagonLineItem = (props) => {
  const type = props.data ? props.data : "grey";
  let src_img = IC_HEXAGON_GREY_IMG_PATH;
  switch (type) {
    case "yellow":
      src_img = IC_HEXAGON_YELLOW_IMG_PATH;
      break;
    default:
      src_img = IC_HEXAGON_GREY_IMG_PATH;
      break;
  }
  return (
    <>
      <div className="hexagon-item">
        <img src={src_img} />
      </div>
    </>
  )
}
export default HexagonLineItem;