import React from 'react';

const Ticket = (props) => {
  const tickets = props.tickets;
  const activities = props.activities;
  for (let i = 0; i < tickets.length; i++) {
    tickets[i]['totalAcquisitionTicket'] = 0;
    if (Array.isArray(activities.wallets) && activities.wallets) {
      const walletData = activities.wallets.filter(function (wallet) {
        return tickets[i].id === wallet.ticketId;
      });
      if (walletData.length > 0) {
        tickets[i]['totalAcquisitionTicket'] = walletData[0].totalAcquisitionTicket;
      }
    }
  }
  return (
    <>
      <div className={(tickets && tickets.length === 1) ? "bingo-to-lp-swap-one" : "bingo-to-lp-swap"}>
        {tickets && tickets.map((ticket) => (
          <>
          <div className="box-ticket">
            <div className="ticket-img">
              <img src={ ticket.thumbnails[0] ? ticket.thumbnails[0] : ''} alt="" />
            </div>
            <div className="ticket-text"><span>{ticket.title}</span></div>
            <div className="ticket-point">
              <span>×{ticket.totalAcquisitionTicket}</span>
            </div>
          </div>
          </>
        ))}
      </div>
    </>
  )
}
export default Ticket;
