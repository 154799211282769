import React from 'react';
import { useSelector } from 'react-redux';
import HomeSlides from '../../components/eventSlides/HomeSlides';
import { Link } from 'react-router-dom';

const ExchangeTicketTop = () => {
    const { eventDetail } = useSelector((state) => state.event);
    return (
      <>
        <div className="bingo-select-top">
          <Link to="/select-bingo">
            <HomeSlides playerEvent={eventDetail} />
          </Link>
        </div>
      </>
    )
}
export default ExchangeTicketTop;