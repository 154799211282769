import styled from 'styled-components'

const StyleLogin = styled.div`
  position: relative;
  background-color:#FFF;
  margin: 0;
  height: 100%;
  overflow: hidden;

  .loginTitle {
    border-top: none;
    position: absolute;
    margin-top: 16px;
    z-index: 2;
    width: 100%;
    line-height: 38px;
    opacity: 1;

    .btn {
      margin-left: auto;
      margin-right: auto;
      display: block;
      font-size: 13px;
      color: white;
      padding: 5px;
      border-radius: 20px;
      border: none;
      background-color: #1da1f2;
      font-family: "Noto Sans JP";
      font-weight: bold;
      margin-right: 4%;
      height: 32px;
      letter-spacing: 0.05em;

      .whileTwitter {
        max-width: 20px;
        float: left;
        margin-left: 14px;
        margin-top: 2px;
        margin-right: 3px;
      }

      .title-twitter-first {
        margin-left: 3px;
        margin-right: 11px;
        letter-spacing: 0.05em;
        font-style: normal;
        font-weight: bold;
      }
    }

    .disable {
      opacity: 0.5;
      pointer-events: none;
      color: #ffffff;
      background-color: #1da1f2;
    }
  }

  .bgrLogin {
    background-image: url("/images/loginBgr.png");
    min-height: 700px;
    //background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    display:block;
    background-color:#FFF;
    
  }
  .title{
    width:90%;
    margin-left: 5%;
    margin-right: 5%;
    
    top:10px;
  }
  .imgTitle{
    position:absolute;
    top: 72px;
    display: flex;

    .image-title {
      width:90%;
      margin: auto;
      top:10px;
    }
  }
  .divImgBgr{
    display:flex;
    justify-content: center;
    align-items: center;
    .img-logo {
      position: absolute;
    }
    .kumahaku-logo{
      position: absolute;
      .kumahaku-logo-sub{
        position: absolute;
        left: 20%;
        top: 175px;
        display: grid;
        text-align: center;
        .kumahaku-logo-sub1{
          margin: 12px auto;
        }
      }
    }
  }
  .imgBgr{
    width: 100%;
    height: 100%; 
    object-fit: contain;
  }

  .tabingo {
    width: 100%;
    display: flex;
    position: inherit;
    justify-content: center;
    
    &.top {
      margin: 20px 0 15px 0;
    }

    &.body {
      margin: 32px 0 27px 0;
    }

    .imgLeft {
      width: 10%;
      max-width: 30px;
      margin-left: 10px;
      float: left;
    }

    h2 {
      // width: 50%;
      text-align: center;
      color: #10cad6;
      font-size: 22px;
      font-family: "Noto Sans JP";
      float: left;
      line-height: 32px;
      margin: 0 9px 0 9px;
    }

    .imgRight {
      width: 10%;
      max-width: 30px;
      margin-right: 10px;
    }
  }

  .content {
    text-align: center;
    color: #45556D;
    font-size: 12px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.05em;
    margin-top: 15px;

    div {
      padding-left: 7px;
    }
  }

  .line {
    width: 96%;
    margin:auto;
    //margin: 25px 0 10px 0;
    min-height:36px;
    display:block;
  }

  .divLoginEmail {
    margin-top: 32px;
    margin-bottom: 47px; 
    margin-left: 47px;
    margin-right: 48px;
    
    .btn {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: white;
      padding: 10px;
      border-radius: 50px;
      border: none;
      background-color: #1da1f2;
      width: 100%;
      font-family: "Noto Sans JP";
      font-weight: bold;
      margin: 4px auto;
      letter-spacing: 0.05em;
      background-color: #10CAD6 !important;
      height: 48px;
      
      &:focus-visible {
        outline: none;
      }
    }
  }

  .divLogin {
    margin-top: 27px;
    margin-left: 47px;
    margin-right: 48px;

    .btn {
      position:relative;
      display: block;
      font-size: 16px;
      color: white;
      padding: 10px;
      border-radius: 50px;
      border: none;
      background-color: #1da1f2;
      width: 100%;
      font-family: "Noto Sans JP";
      font-weight: bold;
      margin: 4px auto;
      letter-spacing: 0.05em;
      height: 48px;

      .whileTwitter {
        max-width: 30px;
        position: absolute;
        left: 20px;
      }
    }

    .disable {
      opacity: 0.5;
      pointer-events: none;
      color: #ffffff;
      background-color: #1da1f2;
    }

    img {
      margin-top: 3px;
    }
  }

  .loginGoolge {
    margin-top: 32px;
    margin-bottom: 47px;
    .btn {
      position:relative;
      display: block;
      font-size: 16px;
      color: white;
      padding: 10px;
      border-radius: 50px;
      border: none;
      background-color: #1da1f2;
      width: 80%;
      font-family: "Noto Sans JP";
      font-weight: bold;
      margin: 4px auto;
      letter-spacing: 0.05em;

      .whileTwitter {
        max-width: 30px;
        position: absolute;
        left: 20px;
      }
    }

    .disable {
      opacity: 0.5;
      pointer-events: none;
      color: #ffffff;
      background-color: #1da1f2;
    }

    img {
      margin-top: 3px;
    }
  }

  .rules {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 70px;
    display: block;
    padding: 3% 5px;
    color:#45556D;
    font-weight:500;
    .text {
      width: 100%;
      text-align: center;
      span {
        color: #45556d;
        font-family: "Noto Sans JP";
        font-size: 12px;
        font-weight: 500;
  
        a {
          color: #10cad6;
          text-decoration: underline;
        }
      }
    }
  }

  .footer {
    position: absolute;
    left: 0;
    bottom: 26px;
    width: 100%;

    .imgFooter {
      background-image: url("/icon/login/footer.svg");
      background-position: bottom;
      background-repeat: repeat;
      height: 24px;
    }

    .copyRight {
      background-color: #96A4BC;
      color: #ffffff;
      text-align: center;
      font-size: 11px;
      // bottom: 0;
      position: absolute;
      width: 100%;
      padding: 5px;
    }
  }

  .content-how-to-play {
    .play-body {
      width: 92%;
    }
  }

  // @media only screen and (min-width: 1026px) {
  //   .loginTitle {
  //     max-width: 375px;
  //   }

  //   .bgrLogin {
  //     height: 80vh !important;

  //     .logo {
  //       max-width: 27%;
  //       margin-top: 4.5%;
  //     }
  //   }

  //   .tabingo {
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;

  //     h2 {
  //       font-size: 30px;
  //     }

  //     img {
  //       min-width: 30px;
  //     }
  //   }

  //   .content-how-to-play .play-title {
  //     h2 {
  //       font-size: 30px;
  //     }
  //   }

  //   .rules {
  //     text-align: center;
  //   }

  //   .footer {
  //     position: relative;
  //     bottom: 26px;

  //     .imgFooter {
  //       height: 24px;
  //     }

  //     .copyRight {
  //       //font-size: 18px;
  //      // bottom: -20px;
  //     }
  //   }

  //   .title {
  //     //margin-top: 8.5% !important;
  //   }
  // }

  @media only screen and (max-width: 1025px) and (min-width: 769px) {
    .bgrLogin {
      min-height:750px;
      .logo {
        max-width: 300px;
        margin-top: 35px;
      }

      .title {
        //margin-top: 9% !important;
      }
    }

    .imgFooter {
      height: 75px;
    }
  }
  @media only screen and (max-width: 769px) {
    .bgrLogin {
      .logo {
        max-width: 150px;
        margin-top: 30px;
      }
    }

    .title {
      //margin-top: 11% !important;
    }
  }
  @media only screen and (max-width: 541px) {
    .bgrLogin {
      min-height: 200px;

      .logo {
        max-width: 150px;
      }
    }

    .tabingo {
      .imgLeft {
        max-width: 25px;
      }

      .imgRight {
        max-width: 25px;
      }
    }
  }
  @media only screen and (max-width: 461px) {
    .bgrLogin {
      min-height: 170px;

      .logo {
        max-width: 150px;
        margin-top: 10px;
      }
    }

    .tabingo {
      .imgLeft {
        max-width: 24px;
      }

      .imgRight {
        max-width: 24px;
      }
    }
  }
  @media only screen and (max-width: 414px) and (min-height: 751px) {
    .bgrLogin {
      .logo {
        max-width: 155px;
      }
    }

    .tabingo {
      .imgLeft {
        max-width: 22px;
      }

      .imgRight {
        max-width: 22px;
      }
    }

    .divLogin .btn {
     

      .whileTwitter {
        max-width: 24px;
      }
    }

    .title {
      //margin-top: 15% !important;
    }
  }
  @media only screen and (max-width: 376px) and (min-height: 701px) {
    .bgrLogin {
      .logo {
        max-width: 155px;
      }
    }

    .tabingo {
      .imgLeft {
        max-width: 20px;
      }

      .imgRight {
        max-width: 20px;
      }
    }

    .title {
      //margin-top: 18% !important;
    }
  }
  @media only screen and (max-width: 376px) and (max-height: 700px) {
    .bgrLogin {
      .logo {
        max-width: 146px;
        margin-top: 6px;
      }
    }
  }
  @media only screen and (max-width: 361px) {
    .bgrLogin {
      min-height: 160px;

      .logo {
        max-width: 125px;
        margin-top: 4px;
      }
    }

    .tabingo {
      .imgLeft {
        max-width: 20px;
      }

      .imgRight {
        max-width: 20px;
      }
    }

    .title {
      //margin-top: 15% !important;
    }
  }
  @media only screen and (max-width: 321px) {
    .bgrLogin {
      .logo {
        max-width: 125px;
      }
    }

    .tabingo {
      .imgLeft {
        max-width: 18px;
      }

      .imgRight {
        max-width: 18px;
      }
    }

    .divLogin .btn {
      font-size: 15px;
      margin-top: 20px;

      .whileTwitter {
        max-width: 24px;
      }
    }

    .loginGoogle .btn {
      font-size: 15px;
      margin-top: 20px;

      .whileTwitter {
        max-width: 24px;
      }
    }

    .rules span {
      font-size: 11px;
    }

    .footer {
      img {
        width: 100%;
      }
    }

    .loginTitle .btn {
      width: 48%;
    }

    .title {
      //margin-top: 16% !important;
    }
  }
`

export default StyleLogin
