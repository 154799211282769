import { actionTypes } from './action'

const initState = {
  jwt_token: window.localStorage.getItem('jwt_id') ?? null,
  userInfo: {
    nick_name: '',
    need_update: true,
    point: 0,
    iconChecked: 0,
    totalPlayerBingoSuccess: 0,
    isShowTutorial: true
  },
  playerId: '',
  playerBingoId: '',
  userPosition: { lat: 0, lng: 0 },
  isCheckUser: false,
  totalAcquisitionTicket: "",
  login: false,
  needUpdateTotalTicket: false
}

const userReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case actionTypes.SET_JWT:
      return {
        ...state,
        jwt_token: payload,
      }
    case actionTypes.SET_LOGIN:
      return {
        ...state,
        login: payload,
      }
    case actionTypes.LOGOUT:
      return {
        ...state,
        jwt_token: null,
        userInfo: {},
      }
    case actionTypes.GET_USER_INFO:
      return {
        ...state,
        userInfo: {
          ...payload,
          need_update: false,
        },
        isCheckUser: true,
        // totalAcquisitionTicket: payload.wallets.totalAcquisitionTicket,
        // numberOfRedemptions: payload.wallets.numberOfRedemptions,
      }
    case actionTypes.NEED_UPDATE_TOTAL_TICKET:
      return {
        ...state,
        needUpdateTotalTicket: payload
      }
    case actionTypes.REQUEST_UPDATE_USER:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          need_update: true,
        },
      }
    case actionTypes.GET_USER_POSITION:
      return {
        ...state,
        userPosition: payload,
        isCheckUser: true,
      }
    case actionTypes.UPDATE_TUTORIAL:
      return {
        ...state,
        isShowTutorial: true
      }
    case actionTypes.SET_PLAYER_BINGO_ID:
      return {
        ...state,
        playerBingoId: payload
      }
    default:
      return state
  }
}

export default userReducer
