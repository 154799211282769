
export const actionTypes = {
  SET_JWT: 'SET_JWT',
  LOGOUT: 'LOGOUT',
  GET_USER_INFO: 'GET_USER_INFO',
  REQUEST_UPDATE_USER: 'REQUEST_UPDATE_USER',
  GET_USER_POSITION: 'GET_USER_POSITION',
  UPDATE_TUTORIAL: 'UPDATE_TUTORIAL',
  SET_PLAYER_BINGO_ID: 'SET_PLAYER_BINGO_ID',
  SET_LOGIN: 'SET_LOGIN',
  NEED_UPDATE_TOTAL_TICKET: 'NEED_UPDATE_TOTAL_TICKET'
}

export const setLogin = (params) => {
  return {
    type: actionTypes.SET_LOGIN,
    payload: params
  }
}

export const setPlayerBingoId = (params) => {
  return {
    type: actionTypes.SET_PLAYER_BINGO_ID,
    payload: params
  }
}

export const get_jwt = (token) => {
  return {
    type: actionTypes.SET_JWT,
    payload: token,
  }
}

export const actionLogout = () => {
  return {
    type: actionTypes.LOGOUT,
  }
}

export const getUserInfo = (user) => {
  return {
    type: actionTypes.GET_USER_INFO,
    payload: user,
  }
}
export const setNeedUpdateTotalTicket = (status) => {
  return {
    type: actionTypes.NEED_UPDATE_TOTAL_TICKET,
    payload: status,
  }
}

export const requestUpdateUser = () => {
  return {
    type: actionTypes.REQUEST_UPDATE_USER,
  }
}

export const updateUserPosition = (position) => {
  return {
    type: actionTypes.GET_USER_POSITION,
    payload: position,
  }
}
export const updateTutorial = () => {
  return {
    type: actionTypes.UPDATE_TUTORIAL,
  }
}