import React from "react";

const IconMenu = (props) => {
  return (
    <svg
    width={props.width ? props.width : "22"}
    height={props.height ? props.height : "16"}
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 14H0V16H22V14Z" fill={props.fill?props.fill:'#96A4BC'} />
      <path d="M22 7H0V9H22V7Z" fill={props.fill?props.fill:'#96A4BC'} />
      <path d="M22 0H0V2H22V0Z" fill={props.fill?props.fill:'#96A4BC'} />
    </svg>
  );
};
export default IconMenu;
