import { takeLatest, put } from '@redux-saga/core/effects';
import { serverApi } from '../../services/serverApi';
import { actionTypes, getBingosSelectFail, getBingosSelectSuccess, getSheetDetailFail, getSheetDetailSuccess } from './action';
import { getBingosSelect, getSheetDetail } from 'common/src/api/bingo';

function* getBingosSelectSaga(action) {
  try {
    const { playerId, playerEventId } = action.payload;
    const res = yield serverApi(getBingosSelect(playerId, playerEventId));
    if (res.status === 200) {
      const data = res.data.data;
      yield put(getBingosSelectSuccess(data));
    } else {
      yield put(getBingosSelectFail());
    }
  } catch (error) {
    yield put(getBingosSelectFail());
  }
}

function* getSheetDetailSaga(action) {
  try {
    const { playerId, playerEventId, playerBingoId } = action.payload;
    const res = yield serverApi(getSheetDetail(playerId, playerEventId, playerBingoId));
    if (res.status === 200) {
      const data = res.data.data;
      yield put(getSheetDetailSuccess(data));
    } else {
      yield put(getSheetDetailFail());
    }
  } catch (error) {
    yield put(getSheetDetailFail());
  }
}

export function* watchGetBingosSelect() {
  yield takeLatest(actionTypes.GET_BINGOS_SELECT, getBingosSelectSaga);
}
export function* watchGetSheetDetail() {
  yield takeLatest(actionTypes.GET_SHEET_DETAIL, getSheetDetailSaga);
}

