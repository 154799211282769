import React from "react";
import HeaderGoBack from "../../components/HeaderGoBack";
import styled from "styled-components";
import FooterBuilding from '../../components/footerBuilding';

const Style = styled.div`
  .txt {
    color: #323232;
    font-weight: 200;
  }
  .head {
    font-weight: bold;
    font-size:16px;
  }
  .mgt20 {
    margin-top: 20px;
  }
  .mgb20 {
    margin-bottom: 20px;
  }
  .mgb10 {
    margin-bottom: 10px;
  }
  .mgt40 {
    margin-top: 40px;
  }
  .right {
    float: right;
  }
  p{
    font-size:12px;
  }
`;

const PrivacyProtection = () => {
  window.scrollTo(0, 0);
  return (
    <Style>
      <div className="menu">
        <HeaderGoBack text="プライバシーポリシー" />
        <div style={{ padding: "10px"}}>
          <div className="head"><b style={{ color: "#10cad6" }}>TABINGO</b> <b>プライバシーポリシー</b></div>
          <div>
            <p className="txt mgt20 mgb10">株式会社ポケットカルチャー(以下、｢当社」といいます)は、当社サービス「TABINGO」において個人情報を取扱う際に、個人情報保護に関する諸法令、および主務大臣のガイドラインに定められた義務、並びに本ポリシーを遵守します。そのため、利用者に関する情報についての取得、利用、開示、保存するのかについて規定するプライバシーポリシーを定めます。当社の社員一同が以下に定める個人情報保護方針を遵守し、お客様の個人情報を適切かつ安全に取り扱うとともに、個人情報保護の徹底に努めてまいります。</p>
          </div>

          <div className="mgt20 mgb20 head">第1条（個人情報）</div>
          <div>
            <p className="txt mgb10">「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。</p>
          </div>

          <div className="mgt20 mgb20 head">第2条（個人情報の収集方法）</div>
          <div>
            <p className="txt mgb10">当社は、当社のサービスを利用する際に、以下の情報を取得する場合があります。</p>
            <p className="txt mgb10">１．	利用者から取得する情報</p>
            <p className="txt mgb10">（１)年齢または生年月日</p>
            <p className="txt mgb10">（２）氏名、ユーザー名またはニックネーム</p>
            <p className="txt mgb10">（３）メールアドレス</p>
            <p className="txt mgb10">（４）本人確認に必要なパスワード等の情報</p>
            <p className="txt mgb10">（５）ユーザープロフィール※プロフィール画像、ユーザー名、ニックネーム、性別、経歴、SNS上のプロフィールリンク及びプロフィール情報、ID等</p>
            <p className="txt mgb10">（６）その他当社が指定する利用者に関する情報</p>
            <p className="txt mgb10">1-2端末情報</p>
            <p className="txt mgb10">（１）端末識別子</p>
            <p className="txt mgb10">（２）携帯端末識別子およびIPアドレス</p>
            <p className="txt mgb10">1-3．位置情報</p>
            <p className="txt mgb10">当社のサービスを利用し、そこで位置情報を提供することを認めた場合、利用者の現在地情報を取得することがあります。</p>
            <p className="txt mgb10">1-4．利用者のアクションに関する情報</p>
            <p className="txt mgb10">当社のアプリおよびウェブサイト上で行われた利用者のアクションについては、記録するサーバー上のファイルであるログファイルを使用して取得、保管します。</p>
            <p className="txt mgb10">1-5.コミュニケーション機能</p>
            <p className="txt mgb10">当社のサービス上において、他の利用者とコミュニケーションを取る一定の活動に参加した場合、これらの情報のアーカイブを記録、保管することがあります。</p>
            <p className="txt mgb10">1-6. クッキー等を利用した情報取得</p>
            <p className="txt mgb10">当社は利用者が当社サービスを利用する際に、下記の特定の技術情報を取得します。</p>
            <p className="txt mgb10">クッキー、IPアドレス、端末の種類、携帯端末識別子、ブラウザの種類、ブラウザの言語、参照ページおよび出口ページ、プラットフォームの種類、クリック数、ドメイン名、ランディングページ、ページ閲覧数およびページの閲覧順序等。これらの情報は、当社内部での使用を目的とのみ利用します。</p>
            <p className="txt mgb10">※クッキーポリシー(クッキーとは？)</p>
            <p className="txt mgb10">クッキーとは、ウェブページを利用したときに、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、お客様のコンピュータにファイルとして保存しておく仕組みです。次回、同じページにアクセスすると、クッキーの情報を使って、ページの運営者はお客様ごとに表示を変えたりすることができます。お客様がブラウザの設定でクッキーの送受信を許可している場合、ウェブサイトは、ユーザーのブラウザからクッキーを取得できます。なお、お客様のブラウザは、プライバシー保護のため、そのウェブサイトのサーバーが送受信したクッキーのみを送信します。</p>
            <p className="txt mgb10">※クッキーポリシー(クッキーの設定について)</p>
            <p className="txt mgb10">お客様は、クッキーの送受信に関する設定を「すべてのクッキーを許可する」、「すべてのクッキーを拒否する」、「クッキーを受信したらユーザーに通知する」などから選択できます。設定方法は、ブラウザにより異なります。クッキーに関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。すべてのクッキーを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。</p>
            <p className="txt mgb10">1-7．外部サービスと連携により取得する情報</p>
            <p className="txt mgb10">外部サービスで利用するIDおよびその他外部サービスプライバシー設定により利用者が連携先に開示を認めた情報を取得することがあります。</p>

          </div>

          <div className="mgt20 mgb20 head">第3条（取得した情報の利用）</div>
          <div>
            <p className="txt mgb10">当社は、取得した利用者の情報を以下の目的のために利用します。</p>
            <p className="txt mgb10">(１)当社の商品・サービスの提供のため</p>
            <p className="txt mgb10">(２)料金請求、課金計算のため</p>
            <p className="txt mgb10">(３)本人確認、認証サービスのため</p>
            <p className="txt mgb10">(４)アンケート、懸賞、キャンペーンの実施のため</p>
            <p className="txt mgb10">(５)マーケティング調査、統計、分析並びにサービス改善のため</p>
            <p className="txt mgb10">(６)システムメンテナンス、不具合対応のため</p>
            <p className="txt mgb10">(７) 規約、条件およびポリシーの変更などの重要な通知をお送りするため</p>
            <p className="txt mgb10">(８) 広告の配信、その他第三者の商品、ターゲットを絞った商品サービス広告提供等</p>
          </div>

          <div className="mgt20 mgb20 head">第4条（情報の共有・第三者提供）</div>
          <div>
            <p className="txt mgb10">当社は、以下の場合、利用者の情報を第三者と共有することがあります。</p>
            <p className="txt mgb10">(１）利用者の同意を得た場合</p>
            <p className="txt mgb10">利用者の同意を得た場合、当社は利用者の情報を第三者である会社、組織、個人に提供することがあります。</p>
            <p className="txt mgb10">(２)第三者サービス提供者との共有</p>
            <p className="txt mgb10">支払処理、データ分析、メール送信、ホスティングサービス、カスタマーサービスなどを当社の代理で行うサービスを提供する第三者、または、当社のマーケティングのサポートを行う第三者に対して利用者の情報を提供します。すべての第三者サービス提供者に対して、開示情報の機密を保持し、当社の代理でサービスを提供する以外の目的に利用者の情報を使用しないように指示するものとします。</p>
            <p className="txt mgb10">(３)外部サービスとの連携のための共有</p>
            <p className="txt mgb10">Twitterアカウントその他の外部サービスとの連携または外部サービスを利用した認証にあたり、当該外部サービス運営会社に利用者の情報を提供することがあります。</p>
          </div>

          <div className="mgt20 mgb20 head">第5条（委託先の監督）</div>
          <div>
            <p className="txt mgb10">当社は、お預かりした個人情報の処理を利用目的の範囲内で第三者に委託する場合があります。これらの第三者は、十分な個人情報のセキュリティ水準にあることを確認の上選定し、契約等を通じて、必要かつ適切な監督を行います。</p>
          </div>

          <div className="mgt20 mgb20 head">第6条（開示等の求め）</div>
          <div>
            <p className="txt mgb10">当社がお客様から、保有個人情報の開示を求められたときは、請求者がご本人であることを確認させていただいた上で、特別な理由のない限り、当社が定める方法により、当該保有個人情報を開示いたします。</p>
          </div>

          <div className="mgt20 mgb20 head">第7条（プライバシーポリシーの変更）</div>
          <div>
            <p className="txt mgb10">本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。</p>
            <p className="txt mgb10">当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。</p>
          </div>
          <div className="mgt20">
            <p className="txt mgb10">[個人情報保護方針に関する問合せ先]</p>
            <p className="txt mgb10">株式会社ポケットカルチャー</p>
            <p className="txt mgb10">電話番号: 03-5652-7028</p>
            <p className="txt mgb10">メールアドレス：tabingo_privacy@poke.co.jp</p>
          </div>
        </div>
        <FooterBuilding />
      </div>
    </Style>
  );
};

export default PrivacyProtection;
