import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { canCheckin } from '../../../../../services/helper'
import StyleInfoMarkerModal from './InfoMarkerModal.style'
import IconGenreShrine from '../../../../../static/svg/IconGenreShrine'
import IconLocation from '../../../../../static/svg/IconLocation'
import { calculateDistance } from 'common/src/util/geo';
import './styles.scss';
import { DEFAULT_IMAGE_IMG_PATH } from "../../../../../static/png";
import { useSelector } from "react-redux";
import GenreIcon from "../../../../../components/genre/GenreIcon";

const InfoMarkerModal = (props) => {
  const { userPosition } = useSelector((state) => state.user);
  const spot = props.data ? props.data : null;
  const spotPossition = { lat: spot?.location?.latitude, lng: spot?.location?.longitude }
  const image = spot.image ? spot.image : DEFAULT_IMAGE_IMG_PATH;
  const [visible, setVisible] = useState();
  const color = spot && `rgb(${spot?.design?.red},${spot?.design?.green},${spot?.design?.blue})`;
  //let color;
  useEffect(() => {
    setVisible(props.visible ? "flex" : "none");
    // if (spot) {
    //   color = `rgb(${spot?.design?.red},${spot?.design?.green},${spot?.design?.blue})`;
    // }
  }, [props.visible]);
  const distance = calculateDistance(userPosition, spotPossition);
  return (
    <div>
      <Link to={`/bingo/${props.playerBingoId}/spot-checkin/${spot.id}/color=${color}`} key={spot.id}>
        <div className="info-marker-modal" style={{ display: visible }}>
          <div className="div-image">
            <img src={image} alt={spot.name} />
          </div>
          <div className="div-content">
            <div className="title">{spot.name}</div>
            <div className="text-middle">
              <div className="icon">
                <GenreIcon spot={spot} sizeCategory={'size02'} />
              </div>
              <span className="text-icon" style={{ color: color }}>{spot.genre}</span>
            </div>
            <div className="address">
              <IconLocation fill="#96A4BC" /><span className="address-text">現在地から{distance}m</span>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default InfoMarkerModal;
