import React, { useEffect } from 'react';
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getEventDetail } from '../../redux/event/action';
import CopyRight from '../../components/copyRights/CopyRight';
import Loading from '../../components/loading/Loading';
import { backgroundGlay } from '../../helper/helper';
import ExchangeTicketBottom from './ExchangeTicketBottom';
import ExchangeTicketTop from './ExchangeTicketTop';
import GoBackHeader from '../../components/headers/GoBackHeader';

const ExchangeTicketScreen = () => {
  const playerId = window.localStorage.getItem('user_id');
  const eventId = window.localStorage.getItem('eventId');
  const { isGettingEventDetail } = useSelector((state) => state.event);
  const dispatch = useDispatch();
  useEffect(() => {
      if (!playerId) {
          return;
      }
      if (!isGettingEventDetail) {
        dispatch(getEventDetail({ eventId: eventId }));
      }
  }, [])

  return (
    <>
      <GoBackHeader name="チケット交換について" />
      {!isGettingEventDetail ? <Loading /> :
        <div className="exchange-ticket-screen">
          <ExchangeTicketTop />
          <ExchangeTicketBottom />
          <CopyRight backgroundColor={ backgroundGlay } />
        </div>
      }
    </>
  )
}
export default ExchangeTicketScreen;