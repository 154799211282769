import { getLocationFromAddress } from 'common/src/util/helper'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NotificationItem from './NotificationItem';
import NotificationItemNoImage from './NotificationItemNoImage';
import NextButton from '../../../../components/buttons/NextButton';
import { HEADER_COPYRIGHT_HOME } from '../../../../helper/helper';

const HomeNotification = () => {
  const dispatch = useDispatch();

  const { isLastedNotification, notifications, countItem } = useSelector((state) => state.notification);

  return (
    <div
    style={{ minHeight: window.innerHeight - HEADER_COPYRIGHT_HOME+ "px" }} 
    className={((notifications && notifications.length === 2) || isLastedNotification) ? "notification-list class-list" : "notification-list"}>
      {notifications && notifications.slice(0,6).map((item, idx) =>
        <>
          {item.thumbnail && <NotificationItem data={item} key={idx} />}
          {!item.thumbnail && <NotificationItemNoImage data={item} key={idx} />}
        </>)}
        <div className="box-button-next">
          {
            countItem > 6 &&
            <NextButton name="お知らせ一覧" link="/notification" />
          }
        </div>
      {/* {notifications && notifications.length >= 3 && !isLastedNotification && (
        <button
          className="btn"
          onClick={loadMoreNotification}
          disabled={isLoading}
        >
          {isLoading && <i className="fa fa-refresh fa-spin" />}
          {!isLoading && <span>更に表示</span>}
        </button>
      )} */}
    </div>
  )
}

export default HomeNotification
