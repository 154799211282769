import React from 'react';
import HeaderGoBack from '../../components/HeaderGoBack'

const MemberAgreement = () => {
  return (
    <React.Fragment>
      <div className="menu">
        <HeaderGoBack text="会員規約"/>
        <div style={{ marginTop: "10px" }}>会員規約</div>
      </div>
    </React.Fragment>
  );
}

export default MemberAgreement;
