import { updatePlayerName } from 'common/src/api/player';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { serverApi } from '../../services/serverApi';
import { ICON_BUTTON_CLOSE_IMG_PATH } from '../../static/svg';
import IconEdit from '../../static/svg/IconEdit';
import CheckinSuccessButton from '../buttons/CheckinSuccessButton';

const EditUserNameModal = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [name, setName]           = useState(props.name)
  const [status, setStatus]       = useState(name.length > 1 && name.length <= 10)
  const { userInfo } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const handleChange = (e) => {
    let nickName = e.target.value;
    (nickName.trim().length < 1 || nickName.length > 10 || nickName.length < 1) ?
    setStatus(false) :
    setStatus(true)
    setName(nickName);
  }

  const updateUserName = async () => {
    setIsLoading(true)
    const result = await serverApi(updatePlayerName(props.userId, name))
    if (result?.data?.statusCode === 200) {
      userInfo.nickName = result.data.data.nickName;
      props.onClose()
      window.scrollTo(0, 0)
    }
  }

  const handleTouchStart = (e) => {
    if (e.target.tagName !== 'INPUT') {
      const input = document.getElementsByName('txtNickName');
      if (input.length > 0) {
        input[0].blur();
      }
    }
  }
  document.removeEventListener('touchstart', handleTouchStart, false);
  document.addEventListener('touchstart', handleTouchStart, false);
  return (
    <>
      <div className="popup-edit-user-modal">
        <div className="content">
          <div className="card">
            <div className="edit-user-name-modal">
              <div className="edit-icon">
                <IconEdit width='24' height='24' />
              </div>
              <div className="title">ニックネームの変更</div>
              <input
                className="txt-input"
                type="text"
                defaultValue={props.name}
                onChange={(e) => handleChange(e)}
              />
              <div className="desc">ニックネームは10文字以内で変更できます。</div>
              <CheckinSuccessButton
                name={isLoading ? <i className="fa fa-refresh fa-spin" /> : "変更する"}
                handleClick={() => updateUserName()}
                status={status}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
        <div className="button-close"><img src={ICON_BUTTON_CLOSE_IMG_PATH} onClick={props.onClose} /></div>
      </div>
    </>
  )
}
export default EditUserNameModal;